.news {
  padding: 80px 0;
  background-color: #242424;
}

.news h3 {
  text-transform: uppercase;
  font-family: "Oswald", sans-serif;
  font-weight: 600;
  font-size: 40px;
  letter-spacing: -1.4px;
  padding-bottom: 18px;
}
.news-preview-collection {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.news-preview:first-of-type {
  margin: 1.5% 1.5% 1.5% 0;
}
@media (max-width: 1200px) {
  .news {
    padding: 20px 100px 60px 100px;
  }
}
@media (max-width: 768px) {
  .news {
    padding: 60px;
  }
}
@media (max-width: 428px) {
  .news {
    padding: 30px;
  }
  .news-preview {
    width: 100%;
    width: 85%;
    margin: 0 auto 20px auto;
  }
  .news h3 {
    font-size: 24px;
  }
  .news-preview:first-of-type {
    margin: 0 auto 20px auto;
  }
}

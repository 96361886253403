.movie-genre {
  width: 100%;
  font-size: 12px;
  /* opacity: 0; */
  padding: 0 10px;
  position: absolute;
  left: 0;
  bottom: 28px;
  transition: opacity 0.3s, -webkit-transform 0.3s ease-in;
  transition: opacity 0.3s, transform 0.3s ease-in;
  transition: opacity 0.3s, transform 0.3s ease-in,
    -webkit-transform 0.3s ease-in;
}

.navbar-nav .dropdown-menu {
 
  float: none;
  position: absolute !important;
}

.movie-genre p {
  font-size: 14px !important;
  color: #fff !important;
  margin-left: -7px;
  font-weight: 700;
}
.book {
  display: flex;
  justify-content: space-between;
}
.movie-genre img {
  margin-right: 20px;
}
.movie-genre a {
  text-decoration: none !important;
  color: #000;
  font-weight: 600;
}
.movie-genre img {
  margin-top: 3px;
}

/* .movie-preview:hover .movie-genre {
  opacity: 1;
  transform: translate(0, -15px);
} */
.trailer-btn {
  display: none;
}
.release.movie-preview .book {
  display: none;
}
.release.movie-preview .trailer-btn {
  display: unset;
  font-size: 100%;
}

.book a:first-of-type {
  display: flex;
  justify-content: center;
  align-items: center;
  transition: background 0.3s, color 0.3s;
}
.book a:hover:first-of-type {
  background-color: #fff;
  color: #c5292a !important;
}
img.icon {
  transform: unset !important;
  margin-right: unset !important;
  cursor: pointer;
}
.book-now a {
  transition: color 200ms ease-in;
}
.book-now:hover a {
  color: #c5292a !important;
}
.book-now {
  background-color: #c5292a;
}
.book-now {
  color: #fff !important;
}

@media (max-width: 428px) {
  .movie-genre img {
    margin: 0 0 0 15px;
  }
  .movie-preview:hover .movie-genre {
    transform: translateY(-15px);
  }
  .book a:first-of-type {
    margin-top: 0;
  }

  .dropdown .btn {
    
    font-size: 14px;
    align-items: center;
    justify-content: center;
    display: flex;
    margin: 0px auto;
    
}

}

.booking {
  /* padding: 100px; */
}
.booking-upper {
  width: 100%;
  /* height: 70vh; */
  padding: 100px 100px 0px 100px;
  float: left;
  position: relative;
  z-index: 1;
  background-repeat: no-repeat;
  background-size: cover;
  padding-bottom: 15px;
}
.booking-mobile{
  display: none !important;
}
.booking-lower {
  /* height: 30vh; */
  width: 100%;
  float: left;
  padding: 30px 100px 30px 100px;
  background-color: #1b1b1b;
}
.current-week {
  width: 100%;
  background-color: #242424;
  float: left;
  height: 120px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: right;
  align-content: center;
  position: absolute;
  z-index: -1;
  left: 0;
  bottom: 40px;
}
.movie-info {
  width: 23%;
  float: left;
  padding: 20px;
  background-color: #242424;
  margin-top: 10px;
}
.payment-movie-info .movie-info h4 {
  font-weight: 500 !important;
}
.movie-info h4 {
  text-transform: uppercase;
  font-weight: 600;
  padding-bottom: 10px;
  border-bottom: 0 solid #484848;
  font-family: "oswald";
}
.showtimes {
  width: 77%;
  float: left;
  padding: 0 100px;
}
._info__ ul {
  list-style-type: none;
}
.nav-tabs {
  border-bottom: 0 !important;
}
.booking-time {
  background-color: transparent !important;
  color: #fff !important;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 24px;
  font-family: "oswald";
}
.nav-tabs .nav-link:focus,
.nav-tabs .nav-link:hover {
  border-color: transparent transparent !important;
}
.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  border-color: transparent transparent #c5292aff !important;
}
.type-time-slots {
  list-style-type: none;
  display: inline;
}
.type-time-slots li {
  /* padding: 5px 15px; */
  float: left;
  /* background-color: #333; */
  /* margin-right: 15px; */
  /* border-radius: 3px; */
  /* width: 105px; */
  margin-bottom: 15px;
  text-align: left;
}
.type-time-slots .timing-box {
  padding: 5px 15px;
  float: left;
  background-color: #333;
  margin-right: 15px;
  border-radius: 3px;
  width: 105px;
  margin-bottom: 15px;
  text-align: center;
}
.type-time-slots li a {
  text-decoration: none;
  font-family: "oswald";
}
.tab-content {
  padding: 30px 0;
  padding-bottom: 0px;
}
.booking-time {
  padding: 0 !important;
  margin-right: 10px;
}
.text-default {
  color: rgb(150, 149, 149);
}
.text-default:hover {
  color: rgb(150, 149, 149);
}

.display-week {
  width: 100%;
  padding-left: 30%;
  margin-left: 0;
  display: flex;
  flex-wrap: wrap;
}
.btn-week {
  color: rgba(194, 194, 194, 0.904) !important;
  font-weight: 600 !important;
  margin-top: 2px;
}
.btn-week:focus,
.btn-week:focus-visible {
  background-color: #c5292a !important;
  color: #fff !important;
  border: none !important;
  box-shadow: none !important;
  border: none !important;
}
.btn-week.active {
  background-color: #c5292a !important;
  color: #fff !important;
  border: none !important;
  box-shadow: none !important;
  border: none !important;
}
.filling-data {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  width: 65%;
  padding: 10px;
}
.filling-data p {
  /* width: 50%; */
  font-weight: 500;
}
.filling-details {
  width: 35%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;
  padding: 10px 20px;
}
.filling-details p {
  width: 50%;
  margin-bottom: 0;
}
.filling-details p:first-of-type {
  color: #2cb10a;
}
.filling-details p:nth-of-type(2) {
  color: #ff972a;
}
.filling-details p:nth-of-type(3) {
  color: #ff4242;
}
.filling-details p:nth-of-type(4) {
  color: rgb(150, 149, 149);
}
.btn-week:active {
  background: Yellow;
}
.lang-time {
  color: #c1c1c1;
  font-weight: 600;
}
.cin-type {
  font-weight: 600;
}
.seats-bar {
  height: 3px;
}
.seats-bar .progress-bar {
  background-color: #28a745 !important;
}
.cinema-title .title {
  font-size: 20px;
}
@media (max-width: 428px) {
  .booking-mobile{
    display: inline-block !important;
  }
  .movie-info h4 {
    font-weight: 500;
  }
  .genre {
    margin-top: 0 !important;
    text-align: left;
    padding-left: 5px;
  }
  .booking-upper {
    width: 100%;
    padding: 100px 30px 0 30px;
    background-image: none !important;
    display: flex;
    background-size: cover;
    flex-direction: column;
  }
  .movie-details {
    width: 100%;
    display: flex;
    flex-direction: column;
  }
  .booking-poster {
    width: 70%;
    margin: auto;
  }
  .booking-movie-details {
    padding: 30px 0 0;
    width: 100%;
  }
  .booking-movie-details h3 {
    font-size: 150%;
  }
  .booking-movie-details p {
    font-weight: 700;
    font-size: 80%;
  }
  .booking-movie-details .btn-normal {
    padding: 7px 10px !important;
    font-size: 14px;
  }
  .book-now {
    background-color: #c5292a;
    color: #fff;
  }
  .book-now a {
    transition: color 200ms ease-in;
  }
  .book-now:hover a {
    color: #c5292a !important;
  }
  .share {
    font-size: 20px !important;
  }
  .filling-data {
    width: 100%;
  }
  .display-week {
    width: 100%;
    display: flex;
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;
    margin: 10px 0;
  }
  .filling-details {
    width: 100%;
    padding: 0 10px;
  }

  .current-week {
    width: 100%;
    height: auto;
    margin-top: 10px;
  }
  .btn-week.active,
  .btn-week:focus,
  .btn-week:focus-visible {
    font-size: 14px !important;
    margin-right: 0.5% !important;
  }
  .booking-lower {
    padding: 20px 30px;
  }
  .movie-info {
    width: 100%;
    margin-top: 0;
  }
  .movie-info h4 {
    font-size: 120%;
  }
  .showtimes {
    width: 100%;
    padding: 10px 0;
  }
  .showtimes .nav {
    width: 100% !important;
  }
  .booking-time {
    font-size: 22px !important;
  }
  .showtimes .tab-content {
    padding: 20px 0;
  }
  .btn-week {
    margin-top: 2px;
    margin-right: 2px;
    padding: 5px 10px !important;
    font-size: 12px;
  }
  .current-week {
    position: unset;
  }
  #uncontrolled-tab-example-tabpane-synopsis h4 {
    font-size: 16px;
  }
  .filling-details {
    font-size: 12px;
  }
  .showtimes .nav-tabs {
    border-bottom: 0 !important;
    width: 100% !important;
  }
  .food-type .tab-content {
    width: 100%;
  }
  .type-time-slots li {
    margin-right: 10px;
  }
  .display-week {
    padding-left: 0%;
  }
}
.cinema-holder
{
  display: block; 
  float:left;
  clear:both;
}

.foodcontainer {
  background-color: #242424;
}
.food-section .movie-info {
  width: 20%;
  border-radius: 5px;
  position: relative;
}
.food-section .showtimes {
  padding: 0 20px !important;
  width: 75%;
  border-radius: 5px;
}
.food-section .row {
  justify-content: space-between;
}
.foodcontainer ._summary_txt,
._seat_title {
  text-transform: uppercase;
  color: #fff;
  font-size: 22px;
  line-height: 30px;
  font-weight: 500;
  margin-bottom: 10px;
}
.food-section ._summary_txt {
  padding: 33px 0 0;
  font-family: "oswald";
}
.food-heading {
  padding: 0px 10px 30px 10px;
}
.heading-content {
  display: flex;
  justify-content: space-between;
  padding-bottom: 10px;
  border-bottom: solid 0.5px #484848;
  width: 100%;
  align-items: center;
}
.heading-content .btn {
  padding: 5px 15px !important;
}
.food-section ._summary_details {
  height: auto !important;
}
.food-section ._35px {
  padding: 0 10px;
}
.food-section ul {
  display: flex;
  flex-wrap: wrap;
}
.food-heading h3 {
  color: #fff;
  font-size: 20px;
  text-transform: uppercase;
  font-family: "Oswald", sans-serif;
  letter-spacing: 1px;
  margin-bottom: 0px;
}
.food-type {
  padding: 0 10px 30px 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}
.food-section {
  padding: 110px 0px 30px 0px;
}
.food-section .movie-info {
  margin-top: 0;
  padding: 0;
}

.food-section .movie-info h4 {
  text-transform: uppercase;
  font-weight: 600;
  padding-bottom: 10px;
  border-bottom: none;
  font-family: unset;
}
ul.react-tabs__tab-list {
  float: left;
  display: flex;
  list-style-type: none;
}
.btn-preview-collection {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}
.food-btn-group {
  width: unset;
}
.food-btn-group .btn {
  margin-right: 5px;
  padding: 5px 15px !important;
  font-size: 14px;
  text-transform: capitalize;
  font-weight: 600;
}
.food-section ._item_list {
  margin-bottom: 0;
}
.food-section ._print_tickets {
  min-height: auto;
}
.food-section .food_list_selected_ li {
  display: flex;
  font-size: 12px;
  justify-content: space-between;
  margin-bottom: 10px;
  width: 100%;
  color: #9f9797;
}
.food-section .food_list_selected_ {
  padding: 0 10px;
  border-bottom: 1px solid #484848;
}
.food-section .total-amount {
  padding: 0 10px;
}
.food-section .total-amount ._name__ {
  font-size: 12px;
}
.food-section .amount-value {
  font-size: 1rem;
  font-weight: 600;
}
.food-section .booking-button {
  padding: 0 10px;
  margin: 40px 0 20px 0;
  width: 100%;
}
.food-section .food_list_selected_ {
  padding: 0 10px;
  border-bottom: 1px solid #484848;
  height: 115px;
  overflow-y: scroll;
}
.btn {
  border-radius: 5px;
  color: #fff;
  font-size: 15px;
  padding: 8px 20px;
}
.btn-ghost {
  background-color: transparent;
  border: 2px solid #fff;
}
.foodcontainer .btn-ghost:hover {
  color: #fff;
}
.btn-gray {
  background-color: #5a5959;
  border: 2px solid #5a5959;
  padding: 8px 15px;
  font-weight: 600;
}
.btn-default {
  background-color: #c5292a;
  border: 2px solid #c5292a;
  padding: 8px 15px;
  font-weight: 600;
}

.switch {
  position: relative;
  display: inline-block;
  width: 40px;
  height: 22px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0px;
  background-color: #5a5959;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 18px;
  width: 18px;
  left: 3px;
  bottom: 2px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #c5292a;
}

input:focus + .slider {
  box-shadow: 0 0 1px #c5292a;
}

input:checked + .slider:before {
  -webkit-transform: translateX(16px);
  -ms-transform: translateX(16px);
  transform: translateX(16px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}
.food-switch {
  color: #fff;
  font-family: sans-serif;
}
.food-preview-collection {
  width: 100%;
  color: #fff;
  display: flex;
  height: 410px;
  flex-wrap: wrap;
  overflow-y: auto;
}
.food-preview {
  width: 31%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-right: 15px;
}
.food-img {
  width: 100%;
  position: relative;
  background-color: black;
}
.food-img img {
  width: 100%;
  opacity: 0.6;
  display: block;
}
.food-img p {
  position: absolute;
  bottom: 5%;
  left: 5%;
  font-weight: 600;
  font-family: sans-serif;
  letter-spacing: 1px;
  height: 30px;
}
p.order-info {
  margin-top: 30px;
  font-family: sans-serif;
  font-size: 14px;
}
.food-preview .row {
  margin-top: 30px;
  display: flex;
  justify-content: space-between;
  padding: 0 20px;
  margin-bottom: 20px;
}
.food-counter-btn {
  display: flex;
  align-items: baseline;
}
.food-counter-btn img {
  line-height: 120%;
}
.food-count {
  margin: 0 13px;
}
.food-section .movie_name__ {
  color: #fff;
  font-size: 13px;
  line-height: 24px;
  font-weight: normal;
  font-weight: 700;
  text-transform: capitalize;
}
/* width */
::-webkit-scrollbar {
  width: 2px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: red;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #b30000;
}
.food-counter-btn .fa {
  cursor: pointer;
}
._food_item_name:first-of-type {
  width: 65%;
}

@media (max-width: 768px) {
  .food-preview {
    height: 320px;
    margin-bottom: 30px;
  }
}
@media (max-width: 428px) {
  .foodcontainer {
    padding: 0;
  }
  .food-section {
    padding: 80px 20px 30px 20px;
  }
  .btn {
    margin-bottom: 10px;
    font-size: 14px;
  }
  .switch {
    margin-top: 10px;
  }
  .food-section .movie-info {
    width: 80%;
    margin: auto;
    margin-top: 20px;
  }

  .food-section .foodcontainer {
    width: 80%;
    margin: 20px auto;
  }
  .food-preview {
    height: 180px;
    margin-bottom: 30px;
    width: 100%;
  }
  .heading-content {
    align-items: center;
  }
  .food-section .row {
    justify-content: space-between;
    flex-direction: column-reverse;
  }
  .food-preview {
    height: 200px;
    margin-bottom: 30px;
  }
  .food-preview {
    height: 200px;
  }
  .food-section .row {
    justify-content: space-between;
    flex-direction: column-reverse;
  }
}

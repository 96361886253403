a#uncontrolled-tab-example-tab-Movie-info {
  display: none;
}

.movie-details {
  width: 100%;
  /* display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content:space-between;
    align-items: center; */
}
.booking-poster {
  width: 23%;
  height: 400px;
  float: left;
}
.booking-movie-details {
  /* padding: 60px 80px 0 80px; */
  padding: 30px 80px 0 80px;
  width: 77%;
  float: left;
}
.booking-poster img {
  width: 100%;
  height: inherit;
  display: block;
}
.booking-movie-details h3 {
  font-size: 240%;
  text-transform: uppercase;
  font-weight: 700;
  font-family: "oswald";
}
.booking-movie-details p {
  font-weight: 700;
}
.booking-movie-details svg {
  line-height: 120%;
  margin-top: -3px;
  font-size: 20px;
  margin-right: 5px;
}
.share {
  font-size: 40px !important;
  line-height: 120% !important;
  margin-top: 5px !important;
}
.booking-mobile{
  display: none;
}

@media (max-width: 428px) {
  .movie-details.container.mobile-container {
    padding: 0px;
}

  .hidden-mobile{
    display: none;
  }

.bookin-lower-contain.container.booking-mobile {
  padding: 0px;
}
.booking-mobile{
  display: inline-block;
}

  .booking-upper {
    width: 100%;
    padding: 100px 30px 0 30px;
    background-image: none !important;
    display: flex;
    background-size: cover;
    flex-direction: column;
    padding-bottom: 15px;
  }
  .movie-details {
    width: 100%;
    display: flex;
    flex-direction: column;
    background-image: none !important;
  }
  .booking-poster {
    width: 100%;
    margin: auto;
  }
  .booking-movie-details {
    padding: 30px 0 0;
    width: 100%;
  }
  .booking-movie-details h3 {
    font-size: 150%;
  }
  .booking-movie-details p {
    font-weight: 700;
    font-size: 80%;
  }
  .booking-movie-details .btn-normal {
    padding: 7px 10px !important;
    font-size: 14px;
    margin-right: 0px;
    margin-bottom: 0px;
  }
  .share {
    font-size: 20px !important;
  }
  .filling-data {
    width: 100%;
  }
  .display-week {
    width: 100%;
    display: flex;
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;
    margin: 10px 0;
  }
  .filling-details {
    width: 100%;
    padding: 0 10px;
  }
  .current-week {
    width: 100%;
    height: auto;
    margin-top: 10px;
  }
  .btn-week.active,
  .btn-week:focus,
  .btn-week:focus-visible {
    font-size: 14px !important;
    margin-right: 0.5% !important;
  }
  .booking-lower {
    padding: 20px 30px;
  }
  .movie-info {
    width: 100%;
    margin-top: 0;
  }
  .movie-info h4 {
    font-size: 120%;
  }
  .showtimes {
    width: 100%;
    padding: 10px 0;
  }
  .booking-time {
    font-size: 18px !important;
  }
  .showtimes .tab-content {
    padding: 20px 0;
  }
  .btn-week {
    margin-top: 2px;
    margin-right: 2px;
    padding: 5px 10px !important;
  }
  .current-week {
    position: unset;
  }
  #uncontrolled-tab-example-tabpane-synopsis h4 {
    font-size: 16px;
  }
  a#uncontrolled-tab-example-tab-Movie-info {
    display: inline-block;
  }
}

@media (max-width: 395px){

  .booking-time {
    font-size: 14px !important;
}
}

.know_more img {
  width: 100%;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
}

.about_wow {
  padding: 85px 80px;
  display: flex;
}
.about_img img {
  width: 401px;
  height: 500px;
  padding: 0px 15px;
}
.about_text {
  width: 100%;
  padding: 0px 15px;
}
.know_more {
  width: 100%;
  padding: 0px 15px;
}
.about_header_img {
  width: 100%;
}
.about_header_img img {
  height: 550px;
  width: 100%;
}
.carousel_img img {
  width: 100%;
}

.movie_rating .accordion__button {
  background: #6c6d6e3b;
  color: #fff;
}
.movie_rating .accordion__button:hover {
  background: #6c6d6e3b;
}
.movie_info .accordion__panel {
  background: #6c6d6e3b;
  background-color: #6c6d6e3b;
}
.carousel-container-about {
  height: 85vh;
}
.carousel-container-about img.d-block.w-100 {
  height: 100%;
}
@media only screen and (max-width: 768px) {
  .about_wow {
    flex-wrap: wrap;
    padding: 15px;
  }
  .about_header_img img {
    height: auto;
    max-width: 100%;
  }
  .about_img img {
    max-width: 100%;
    height: auto;
  }
}
li::before {
  color:white !important;
}

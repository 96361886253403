.factors-preview {
  width: 33.33%;
  text-align: center;
  margin-bottom: 50px;
}
.factors-preview p {
  width: 70%;
  margin: auto;
  font-family: "Gotham", sans-serif;
  color: #c6c6c6;
  font-size: 14px;
  padding-top: 10px;
}
.factors-img {
  margin-bottom: 15px;
}
.factors-preview a {
  text-decoration: none !important;
}

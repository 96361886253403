.wow_club {
  padding: 60px 0;
  width: 100%;
}
.wow_points {
  display: flex;
  justify-content: space-around;
}
.wow_hello {
  background: #242424;
}
/* .voucher-info-container-lower {
  display: none;
} */
.wow_text_info h1 {
  font-size: 29.5px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.45;
  letter-spacing: -0.16px;
  text-align: left;
  color: #fff;
  margin-bottom: 0px;
}
.silver {
  border-left: 1px solid #707070;
  padding-left: 25px;
}
.silver {
  display: flex;
  align-items: center;
}
.point_img.point-silver P {
  color: #878787;
}
.point_text h1 {
  color: #fff;
  font-weight: bold;
}
.gold {
  display: flex;
  align-items: center;
}

.point_img p {
  color: #c5292a;
  font-size: 13px;

}
.silver {
  display: flex;
  align-items: center;
  padding: 4px 15px 10px 25px;
}
.point_text {
  margin-right: 15px;
}
.point_img {
  margin-top: 7px;
}
.wow_text_info p {
  color: #9b9b9b;
  margin-bottom: 0px;
}
.wow_hello {
  padding: 0px 15px;
}

.wow_points {
  border-left: 1px solid #707070;
  padding: 0px 15px;
}

.user_points_win {
  background: #242424;
}

.border-line {
  border-bottom: 1px solid #707070;
}

.user_points_win {
  display: flex;
}

.point_win h2 {
  color: #9b9b9b;
  font-weight: 500;
  margin-left: 12px;
}

.points_text p {
  color: #9b9b9b;
  font-size: 12px;
  margin-top: 6px;
  margin-left: 6px;
}
.user_points_win {
  padding: 20px 120px;
}
.img_voucher img {
  width: 33px;
  -webkit-filter: sepia(40%);
  position: relative;
  top: -4px;
}
.input.active img {
  -webkit-filter: sepia(0%);
}

.flex-parent {
  display: flex;
  background: #242424;

  width: 100%;
  height: 100%;
}

.input-flex-container {
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 100%;

  max-width: 1000px;
  position: relative;
  z-index: 0;
  padding-bottom: 28px;
  padding-top: 5px;
}

.input {
  position: relative;
}
.input:hover {
  cursor: pointer;
}
.input::before,
.input::after {
  content: "";
  display: block;
  position: absolute;
  z-index: -1;
  top: 50%;
  transform: translateY(-50%);
  background-color: #b9cadb;
  width: 4vw;
  height: 5px;
  max-width: 50px;
}
.input::before {
  left: calc(-4vw + 12.5px);
}
.input::after {
  right: calc(-4vw + 12.5px);
}
.input.active {
}
.input.active::before {
  background-color: #b12728;
}
.input.active::after {
  background-color: #b12728;
}
.input.active span {
  font-weight: 700;
}
.input.active span::before {
  font-size: 13px;
}
.input.active span::after {
  font-size: 15px;
}
.input.active ~ .input,
.input.active ~ .input::before,
.input.active ~ .input::after {
}
.input span {
  width: 1px;
  height: 1px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  visibility: hidden;
}
.input span::before,
.input span::after {
  visibility: visible;
  position: absolute;
  left: 50%;
}
.input span::after {
  content: attr(data-year);
  top: 25px;
  transform: translateX(-50%);
  font-size: 14px;
}
.input span::before {
  content: attr(data-info);
  top: -65px;
  width: 70px;
  transform: translateX(-5px) rotateZ(-45deg);
  font-size: 12px;
  text-indent: -10px;
}

.description-flex-container {
  width: 80vw;
  font-weight: 400;
  font-size: 22px;
  margin-top: 100px;
  max-width: 1000px;
}
.description-flex-container p {
  margin-top: 0;
  display: none;
}
.description-flex-container p.active {
  display: block;
}

@media (min-width: 1250px) {
  .input::before {
    position: relative;
    top: 15px;
    width: 28px;
    left: -32px;
    height: 4px;
  }
  .input-first::before {
    width: 0;
  }

  .input::after {
    right: -49.5px;
    top: 15px;
    height: 4px;
  }

  .input.input_no_active::after {
    right: -49.5px;
    top: 15px;
  }
}
@media (max-width: 850px) {
  .input {
    width: 17px;
    height: 17px;
  }
  .input::before,
  .input::after {
    height: 3px;
  }
  .input::before {
    left: calc(-4vw + 8.5px);
  }
  .input::after {
    right: calc(-4vw + 8.5px);
  }
}
@media only screen and (max-width: 320px) and (min-width: 600px) {



  .flex-parent {
    justify-content: initial;
  }

  .input-flex-container {
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;
    height: auto;
    margin-top: 15vh;
  }

  .input {
    width: 60px;
    height: 60px;
    margin: 0 10px 50px;
    background-color: #aeb6bf;
  }
  .input::before,
  .input::after {
    content: none;
  }
  .input span {
    width: 100%;
    height: 100%;
    display: block;
  }
  .input span::before {
    top: calc(100% + 5px);
    transform: translateX(-50%);
    text-indent: 0;
    text-align: center;
  }
  .input span::after {
    top: 50%;
    transform: translate(-50%, -50%);
    color: #ecf0f1;
  }

  .description-flex-container {
    margin-top: 30px;
    text-align: center;
  }
}
.voucher-progress {
  display: flex;
  align-items: center;
  position: relative;
  height: 70px;
}
.voucher-prog-bar {
  width: 50px;
  height: 4px !important;
}
.voucher-img {
  margin-left: 5px;
}
.voucher-img img {
  width: 40px;
  background-color: #242424;
  opacity: 0.5;
}
.voucher-progress.active .voucher-img img {
  opacity: 1;
  /* position: absolute;
  left: 3%;
  z-index: 99;
  top: 10px; */
}
.voucher-progress .user_img {
  position: absolute;
  top: -30px;
  display: none;
}
/* .voucher-progress.active:last-of-type .user_img {
  display: block !important;
} */
.voucher-progress.active:nth-last-of-type(2) .user_img {
  display: block;
}
.voucher-prog-bar {
  background-color: #505050 !important;
}
.progress-bar {
  background-color: #c5292a !important;
}
.flex-parent {
  /* padding: 20px; */
  overflow-x: auto;
  width: 98%;
  margin: auto;
  padding: 40px 0 0 0;
}
.wow_club .container {
  background-color: #242424;
  padding-bottom: 10px;
}
/* width */
.flex-parent::-webkit-scrollbar {
  width: 2px;
  height: 4px;
}

/* Track */
.flex-parent::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 2px;
}

/* Handle */
.flex-parent::-webkit-scrollbar-thumb {
  background: #fff;
  border-radius: 2px;
}

/* Handle on hover */
.flex-parent::-webkit-scrollbar-thumb:hover {
  background: #fff;
}
.quick-book-container {
  background-color: #fff;
  width: 300px;
  padding: 20px 30px;
  border-radius: 15px;
}
.quick-book-head h3 {
  color: #c5292a;
  font-weight: 600;
  margin-bottom: 20px;
  font-size: 24px;
}
.select-quick {
  width: 100%;
  color: #505050;
}
.select-quick::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
  position: absolute;
  top: 35%;
  right: 5%;
}
.select-quick select {
  width: 100%;
  background-color: #eaeaea;
  border: none;
  padding: 5px 50px 5px 5px;
  margin-bottom: 10px;
}
.select-quick:focus-visible {
  border: none !important;
  outline: none !important;
}
.select-quick select:focus-visible {
  border: none !important;
  outline: none !important;
}
.movie-change {
  color: #505050;
}
.book-now-btn {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-top: 10px;
}
.book-now-btn .btnc-primary {
  text-align: right;
  background-color: #c5292a;
  color: #fff;
  padding: 5px 15px;
  border: none;
  border-radius: 5px;
  font-size: 14px;
  font-weight: 600;
}
.btnc-quick {
  text-align: right;
  background-color: #c5292a;
  color: #fff;
  padding: 5px 15px;
  border: none;
  border-radius: 25px;
  font-size: 18px;
  font-weight: 600;
  transition: 0.3s background, 0.3s color;
}
.btnc-quick:hover {
  background-color: #fff;
  color: #c5292a;
}
.btnc-cross {
  background-color: #fff;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  font-size: 20px;
  color: #c5292a;
  border: none;
}
.quick-book-btn-home {
  position: fixed;
  right: 3%;
  bottom: 3%;
  z-index: 99;
}
.quick-book-container {
  position: fixed;
  right: 3%;
  bottom: 15%;
  z-index: 999;
}
.select-quick::after {
  top: 35% !important;
}
@media (max-width: 400px) {
  body {
    min-height: 950px;
  }
}

@media (max-width: 600px) {

  .silver {
   
    padding: 4px 6px 10px 25px;
}
  .point_text h1 {
  
    font-size: 22px;
}
.point_img p {
  
  font-size: 12px;
}
  .img_voucher img {
    width: 18px;
    top: -6px;
  }
  .wow_club {
    background: #242424;
  }
  .wow_points {
    border-left: 0px;
  }
  .user_points_win {
    padding: 20px;
  }
  .wow_club {
    width: 90%;
    margin: 15px auto;
  }
}

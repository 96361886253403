.login-container {
  background-color: #242424;
  width: 300px;
  padding: 20px;
}

.login-container .cross img {
  position: absolute;
  right: -60px;
  top: -20px;
}
.login-container h4 {
  color: #fff;
  font-size: 20px;
  text-transform: uppercase;
  font-family: "Oswald", sans-serif;
  letter-spacing: 1px;
  margin-bottom: 15px;
}
.login-container .input-box {
  background-color: #171614;
  padding: 10px;
  border-radius: 5px;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
}
.login-container .input-box input {
  background-color: #171614;
  border: black;
  color: #fff;
  padding-left: 10px;
  outline: none;
}
.forget {
  color: #c5292a;
  font-size: 12px;
  font-family: sans-serif;
  float: right;
  margin-bottom: 20px;
}
.login-container .input-btn {
  width: 100%;
  margin-bottom: 10px;
}
.login-container .btn {
  padding: 6px 15px;
  font-weight: 600;
  width: 100%;
  border-radius: 5px;
  height: auto;
  float: none;
}
.login-container .btn-default {
  background-color: #c5292a;
  border: 1px solid #ee682d;
  color: #fff;
  background-image: linear-gradient(to right, #ee682d, #c5342b);
}

.login-container .btn-ghost {
  background-color: transparent !important;
  border: 1px solid #c5292a !important;
  color: #c5292a;
  margin-top: 10px;
}
.login-container .btn-black {
  width: 48%;
  background-color: #181615;
  border: 1px solid #c5292a;
}
.btn-black {
  display: flex;
  align-items: center;
  justify-content: center;
}
.btn-black img {
  width: 24px;
}
.horizontal {
  width: 100%;
  text-align: center;
  margin: 15px 0;
}
.login-from {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.sign-up {
  margin: 20px 0;
  font-size: 14px;
  color: #fff;
  font-family: sans-serif;
  text-align: center;
}
.register {
  color: #c5292a;
}
.swal-button {
  background-color: #c5292a !important;
}
.otp-box input {
  width: 32px !important;
  height: 40px !important;
  margin-bottom: 20px;
}
.cross {
  position: absolute;
  right: 0px;
  cursor: pointer;
}

.footer {
  padding: 60px 100px 0 100px;
  font-family: "Montserrat", sans-serif;
  background-color: #333;
}
.footer-content-collection {
  display: flex;
  justify-content: space-between;
}
.seperator {
  margin: 15px 0;
  border-bottom: 2px solid rgb(107, 107, 107);
}
.copy-right .icons {
  text-align: right;
}
.copy-right {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-end;
}
.copyright-section {
  font-size: 12px;
  display: flex;
  justify-content: space-between;
}
.copyright-right {
  font-size: 12px;
  text-align: right;
}
.icons {
  /* width: 170px; */
}
li.icons-info {
  margin-left: 18px;
}
.icons ul {
  list-style-type: none;
  display: flex;
  justify-content: space-between;
}
.icons ul li {
  font-size: 20px;
}
.icons ul li a {
  color: #fff;
}
.icons ul li a:hover {
  color: #9b9b9b;
}
.copy-left a {
  color: #fff;
}
.copyright-right p a {
  color: #fff;
}
.wem-main {
  font-size: 12px;
  font-weight: 600;
}
.footer-preview .logo {
  width: 80px;
  margin-left: 10px;
}
.footer-preview .logo img {
  width: 100%;
}
@media (max-width: 768px) {
  .footer {
    padding: 60px;
  }
  .footer-content-collection {
    flex-wrap: wrap;
  }
  .footer-preview {
    width: 30%;
  }
}
@media (max-width: 428px) {
  .footer {
    padding: 30px;
  }
  .footer-preview {
    width: 100%;
  }
}

.upcoming-movies {
  padding: 60px 120px;
}
.upcoming-movies h3 {
  text-transform: uppercase;
  font-family: "Oswald", sans-serif;
  font-weight: 600;
  font-size: 40px;
  letter-spacing: -1.4px;
  padding-bottom: 2px;
}
.carousel-button-group {
  position: relative;
}
.left-btn-slide,
.right-btn-slide {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: #2c2c2c;
  border: none;
  color: #fff;
  position: absolute;
  bottom: 180px;
}
.left-btn-slide {
  left: -50px;
}
.right-btn-slide {
  right: -40px;
}
.upcom-nowhowing {
  padding: 0;
}
.release .img-showing {
  background-color: #000;
}
.release:hover .img-showing img {
  opacity: 0.5;
}
.release .img-showing img {
  width: 100%;
  transition: opacity 0.3s ease-in;
}
.release .up-genre-contain {
  /* display: none; */
  position: absolute;
  z-index: 99;
  left: 3%;
  bottom: 5%;
  transform: translateY(80px);
  transition: transform 0.3s ease-in;
}
.release:hover .up-genre-contain {
  display: block;
  transform: translateY(0px);
}
.up-genre-contain p {
  margin-bottom: 3px;
  font-size: 12px;
  font-weight: 500;
}
.up-genre-contain button {
  font-size: 14px;
}
.release h4 {
  padding-top: 20px;
}
.releasing {
  color: #9b9b9b;
  font-weight: 500;
}
.release-on {
  font-size: 12px;
}
.release-date {
  font-weight: 500;
  color: #767676;
}
/* new style upcoming */
.show-upcoming {
  display: flex;
  flex-wrap: wrap;
}
.release {
  width: 17%;
  margin: 1% 1.5%;
}
.upmore-info {
  font-size: 12px;
  color: #fff;
}
.upmore-info:hover {
  color: #fff;
  text-decoration: none;
}
.releasing {
  margin-bottom: 0;
}
@media (max-width: 1200px) {
  .upcoming-movies {
    padding: 30px 120px 60px 120px;
  }
}
@media (max-width: 768px) {
  .upcoming-movies {
    padding: 60px;
  }
}

@media (max-width: 428px) {
  
  .release {
    width: 48%;
    margin: 5% 1%;
  }
  .release-date {
    font-size: 14px;
  }
  .lower-upcoming {
    flex-direction: column;
    padding: 10px 45px;
  }
  .releasing {
    line-height: 80%;
  }
  .release h4 {
    padding-top: 10px;
    margin-bottom: 0;
  }
  .upcoming-movies {
    padding: 0;
  }
  .upcoming-movies h3 {
    font-size: 24px;
  }
  .left-btn-slide,
  .right-btn-slide {
    bottom: 160px;
    display: flex;
    justify-content: center;
  }
  .right-btn-slide {
    right: 0;
  }
  .left-btn-slide {
    left: 0;
  }
  .uncontrolled-tab-example-tabpane-synopsis h4 {
    font-size: 18px;
  }
  .release p {
    font-size: 14px;
  }
}

.loyalty-section {
  padding-top: 110px;
}
.loyalty-container {
  width: 100%;
  margin: auto;
  background-color: #242424;
  padding: 20px;
}
.loyalty-tabs {
  width: 100%;
  margin: auto;
  /* background-color: #242424; */
}
.voucher-info-container {
  /* padding: 20px; */
}
.loyalty-tabs .tab-content {
  padding: 0;
}

.price-ticket.price-width p {
    width: 100%;
}

.loyalty-edit-upper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.profile-edit-box {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.loyalty-edit-upper a {
  color: #fff;
  padding-left: 20px;
  text-decoration: none;
}
.wow_club .container {
  border-radius: 15px;
}
.price-ticket p {
  color: #181818;
  color: #F0F0F0;
  /* color: red; */
  width: 61%;
  padding-left: 15px;
}
.profile-edit-box .profile-circle {
  background-color: #c5292a;
  font-size: 20px;
  width: 40px;
  height: 40px;
}
.profile-edit-box .profile-name {
  display: flex;
  align-items: center;
  font-size: 24px;
}
.loyalty-edit-lower {
  margin-top: 20px;
  display: flex;
  width: 68%;
  justify-content: space-between;
}
.loyalty-tabs .nav-tabs {
  background-color: #181818;
}
.loyalty-tabs .nav-tabs .nav-link.active {
  border-color: transparent transparent #c5292aff !important;
  color: #c5292a;
}
.loyalty-tabs .nav-tabs .nav-link {
  background-color: transparent;
  color: rgb(104, 104, 104);
  font-weight: 600;
  width: auto;
}
.voucher-info-container-upper {
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;
  /* padding: 20px 20px 0 20px; */
}
.voucher-info-left {
  width: 70%;
}

.wow_club.wow-silver {
  padding-bottom: 14px;
}
.voucher-points {
  font-size: 34px;
  font-weight: 500;
  margin-bottom: 10px;
}
.prog-bar {
  width: 50%;
  height: 10px;
}

.prog-bar .progress-bar {
  background-color: #c5292a;
}
.voucher-info-right p {
  margin-bottom: 3px;
}
.voucher-info-right p:first-of-type {
  color: #c5292a;
  font-weight: 500;
  font-size: 18px;
}
.voucher-info-right p:nth-of-type(2) {
  font-weight: 500;
}
.voucher-info-right p:last-of-type {
  font-size: 14px;
  color: rgb(146, 146, 146);
}
.voucher-info-container-lower {
  background-color: #fff;
  padding: 0 10px 10px 10px;
  margin-bottom: 40px;
}
.voucher-info-container-lower .nav-tabs {
  background-color: #fff;
}
.voucher-select-container {
  display: flex;
  flex-wrap: wrap;
  padding: 20px 0;
}
.voucher-tags {
  width: 31%;
  margin: 1%;
  height: 190px;
}
.voucher-tag-img img {
  width: 100%;
  height: 190px;
  border-radius: 7px;
}
.voucher-tag-html {
  position: relative;
  height: 190px;
}
.voucher-tag-img {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 5;
  width: 100%;
}
.voucher-till {
  font-size: 12px;
  position: absolute;
  top: 9%;
  right: 5px;
  z-index: 6;
  margin-bottom: 0;
  color: #fff;
}
.voucher-valid {
  font-size: 12px;
  position: absolute;
  top: 16%;
  right: 5px;
  z-index: 6;
  margin-bottom: 0;
  color: #fff;
}

.voucher-type {
  font-size: 16px;
  top: 33.5%;
  font-weight: 600;
  right: 9%;
  margin-bottom: 0;
  z-index: 6;
  color: #fff;
  position: absolute;
}
.voucher-code {
  text-transform: uppercase;
  font-size: 10px;
  color: #c5292a;
  position: absolute;
  top: 53%;
  right: 2%;
  z-index: 6;
  font-weight: 600;
}

.voucher-off {
  position: absolute;
  top: 44%;
  right: 36%;
  z-index: 6;
  font-weight: 600;
  color: #fff;
}

.voucher-off p:first-of-type {
  font-weight: 600;
  margin-bottom: 0;
}
.voucher-off p:last-of-type {
  font-size: 10px;
  margin: -5px 0 0 0;
}
#progressbar1 {
  position: absolute;
  left: 15px;
  overflow: hidden;
  color: #2dbcb6;
  margin-bottom: 0;
}

/* #progressbar1 li:last-of-type:after{
  border-left: none;
} */

.points-ticket img {
  z-index: 9999;
}


#progressbar1 li {
  list-style-type: none;
  font-size: 8px;
  font-weight: 400;
  display: flex;
    align-items: center;
  
  counter-increment: inst;
  position: relative;
}


.points-ticket {
  
  display: flex;
  align-items: center;

  /* justify-content: space-between; */
  width: 100%;
}

.points-ticket h4 {
  margin-bottom: 0px;
  margin: 0px 30px;
  font-size: 14px;
}

.points-ticket p{
  margin-bottom: 0px;
  font-size: 12px;
  color: #9a8787;
}
.points-ticket{
  margin: 20px 0px;
  background-color: #242424;
  padding: 20px 20px;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
}
.progressbar{
  width: 100%;
}


#progressbar1 li:nth-child(3) {
  margin-bottom: 0;
}

#progressbar1 .step0:before {
  font-family: "proxima";
  content: counter(inst);
  color: #5c5d5f;
  font-size: 12px;
  font-weight: 700;
}

#progressbar1 li:before {
  width: 30px !important;
  height: 30px !important;
  line-height: 30px;
  display: block;
  font-size: 20px;
  background: #ebebeb;
  border: 2px solid #ebebeb;
  border-radius: 50%;
  margin: auto;
  position: relative;
  z-index: 5;
}
.track-margin {
  height: 66px;
}
.track-margin:last-of-type {
  height: auto;
  margin-bottom: 20px;
}
.track-margin p {
  margin-bottom: 0px;
  font-size: 14px;
  font-weight: 700;
}
#progressbar1 li:last-child:before {
  width: 40px;
  height: 40px;
}

.points-ticket:last-of-type{
  overflow:hidden
}

#progressbar1 .points-ticket li:after {
  content: "";
  width: 6px;
  height: 66px;
  
  position: absolute;
  left: 18px;
  border-left: 1px dashed #ffff;
  top: 15px;
  
}
.points-ticket li:last-of-type:after {
  border-left: none;
  content: "";
}
.d-none{
  display: none;
}


.input-box img {
  width: 16px;
  margin-right: 10px;
}
i.fa.fa-male {
  margin-right: 15px;
}

.update-paln h5 {
  text-align: center;
  font-size: 13px;
  background-color: #fff;
  display: inline-block;
  padding: 6px;
  border-radius: 3px;
  color: #242424;
  font-weight: 500;
}

.update-paln {
  display: flex;
  align-items: center;
  justify-content: center;
  /* position: absolute; */
  position: relative;
  top: -18px;
}
.profile-edit-container {
  padding-bottom: 15px;
}

.update-paln h5 span {
  color: #C5292A;
  border-bottom: 1px solid;
  cursor: pointer;

}


.point_text.amout-text p span {
  font-size: 38px;
  font-weight: bold;
  color: #878787;
}

.point_text.amout-text p {
  font-size: 22px;
  font-weight: bold;
  font-family: "Oswald", sans-serif;
}
.wow_hello.wow-silver .silver{
  border-left: transparent;
}

.wow_club.wow-silver {
  padding-top: 0px;
}
.wow_club.wow-loyalty {
  padding-bottom: 0px;
  padding-top: 0px;
}

.wow_hello.wow-silver .silver {
  display: flex;
  align-items: center;
  padding: 5px 14px 10px 17px;
}


#progressbar1 li:last-child:after {
  top: 147px;
  height: 132px;
}

/* #progressbar1 li:nth-child(3):after {
  top: 81px;
} */

#progressbar1 li:nth-child(2):after {
  top: 0px;
}

#progressbar1 li:first-child:after {
  position: absolute;
  top: 0px;
  
}





#progressbar1 li.active:after {
  background-color: #c5292a !important;
  z-index: 3;
}

#progressbar1 li.active:before {
  background-color: #c5292a !important;
  content: "\2713";
  font-weight: 700;
  color: #fff;
  border: 2px solid #c5292a;
}
.list-number {
  background-color: #c5292a;
  color: #fff;
  border-radius: 50%;
  font-size: 10px;
  width: 20px;
  height: 20px;
  position: absolute;
  top: 15.5%;
  left: 17.5%;
  z-index: 100;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;
}
.tick {
  width: 100px !important;
  height: 100px !important;
}

.prev {
  display: block !important;
  position: absolute !important;
  left: 40px !important;
  top: 20px !important;
  cursor: pointer !important;
}
.order-track-container .col-md-6 {
  position: relative;
}
.col-track {
  padding: 0 !important;
}
.card1 {
  margin-left: 60px;
  z-index: 0;
  /* border-right: 1px solid #f5f5f5; */
  /* position: relative; */
}
.prev:hover {
  color: #d50000 !important;
}
.theme-dark-info {
  color: #000;
}
.theme-info {
  color: rgb(68, 68, 68);
}
.trans-history-container {
  position: relative;
  padding: 30px 0;
}
.preferences-container {
  padding: 20px;
}
.pref-box {
  display: flex;
  align-items: flex-start;
  margin-bottom: 20px;
}
.pref-ques {
  padding: 20px;
  width: 20%;
}
.pref-categories {
  width: 80%;
  padding: 20px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.pref-cat-box {
  background-color: #fff;
  color: #000;
  padding: 5px 10px;
  margin: 0 15px 10px 0;
  border-radius: 15px;
  cursor: pointer;
}
.pref-cat-box.active {
  background-color: #c5292a;
  color: #fff;
}
.booking-history {
  padding: 20px;
}
.input-btn input {
  caret-color: transparent;
}
.booking-history-container {
  background-color: #fff;
  color: #000;
}
.loyalty-tabs .mb-3 {
  /* margin-bottom: 0px !important; */
}
.booking-history {
  display: flex;
  /* justify-content: space-between; */
}
.booking-history p {
  font-size: 16px;
  font-weight: 600;
}
.booking-history-date {
  width: 10%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.wow-gray-info {
  color: #5c5d5f;
  font-size: 14px;
}
.booking-history-date p {
  margin-bottom: 0;
  font-weight: 700;
}
.booking-history-poster {
  width: 15%;
}
.booking-history-poster img {
  width: 100%;
  height: 150px;
  object-fit: cover;
  border-radius: 15px;
}
.booking-history-info {
  width: 55%;
  padding-left: 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.booking-history-info p {
  margin-bottom: 0;
}
.booking-history-info .price-wow {
  font-size: 24px;
}
.booking-history-info h4 {
  text-transform: uppercase;
}
.booking-history-info h4 .wow-gray-info {
  text-transform: capitalize;
}
.booking-cancel {
  width: 20%;
}
.booking-cancel img {
  width: 80%;
}
.btn-food {
  background-color: #c5292a;
  color: #fff;
  padding: 5px 10px !important;
  font-size: 14px;
  margin-right: 50px;
}
.btn-food:hover {
  color: #fff;
}
.btn-again {
  background-color: #fff;
  color: #c5292a;
  border: 1px solid #c5292a;
}
.btn-again:hover {
  color: #c5292a;
}
.edit-modal .login-form {
  padding: 10px;
  background-color: #181818;
}
.edit-modal .input-box {
  border-bottom: 1px solid #5c5d5f;
}
.edit-modal .input-box input {
  width: 90%;
}
.edit-modal .input-btn {
  margin-top: 10px;
  display: flex;
  justify-content: center;
}
.sign-out {
  cursor: pointer;
}
.booking-history-info-left h4 {
  color: #000;
}
.booking-history-info .price-wow {
  font-size: 20px;
}
/* -------------new voucher-code---------------- */
.voucher-type {
  font-size: 10px;
  top: 36.5%;
  font-weight: 600;
  right: 2%;
  margin-bottom: 0;
  z-index: 6;
  color: #fff;
  position: absolute;
}
.voucher-code {
  text-transform: uppercase;
  font-size: 8px;
  color: #c5292a;
  position: absolute;
  top: 53%;
  right: 1%;
  z-index: 6;
  font-weight: 600;
}
.voucher-off {
  position: absolute;
  top: 44%;
  right: 35%;
  z-index: 6;
  font-weight: 600;
  color: #fff;
  text-align: center;
  font-size: 14px;
}
.offer-preview-display .voucher-type {
  font-size: 12px;
  top: 36.5%;
  font-weight: 600;
  right: 2%;
  margin-bottom: 0;
  z-index: 6;
  color: #fff;
  position: absolute;
}
.offer-preview-display .voucher-code {
  text-transform: uppercase;
  font-size: 8px;
  color: #c5292a;
  position: absolute;
  top: 53%;
  right: 2%;
  z-index: 6;
  font-weight: 600;
}
.login-modal .modal-content {
  background-color: #000;
}
.rewards-table td,
.rewards-table th {
  color: #000;
  border: 1px solid #000;
  padding: 10px;
}
.rewards-table {
  width: 100%;
  margin-left: 20px;

  padding-top: 30px;
}
.rewards-table h3 {
  color: #5c5d5f;
}
.rewards-table table {
  width: 50%;
  border-collapse: collapse;
  border: 1px solid #000;
}

.profile-no {
  display: flex;
  align-items: center;
  justify-content: center;

}
.profile-no p {
  margin-bottom: 0px;
}

.profile-email {
  display: flex;
  align-items: center;
  width:50%;

}
.loyalty-edit-lower {
  margin-left: 50px;
}

.profile-no img {
  margin-right: 15px;
}
.profile-email img {
  margin-right: 15px;
}

.profile-email p {
  margin-bottom: 0px;
  
}

/* -------------new voucher-code---------------- */

@media (max-width: 428px) {
  .loyalty-tabs{
margin: 15px 0px;
  }
  #progressbar1{
    left: 0px;
  }
  .loyalty-edit-lower{
    margin-left: 0px;
  }
  .loyalty-section {
    padding-top: 100px;
  }
  .profile-edit-box .profile-name {
    font-size: 12px;
  }
  .profile-edit-box .profile-circle {
    font-size: 14px;
    width: 30px;
    height: 30px;
  }
  .loyalty-edit-lower {
    width: 100%;
  }
  .loyalty-edit-lower p {
    font-size: 12px;
    overflow-wrap: break-word;
    width: 88%;
  }
  .loyalty-tabs .nav-tabs .nav-link {
    font-size: 9px;
  }
  .voucher-info-container-upper {
    flex-wrap: wrap;
    margin-bottom: 15px;
    padding: 10px 10px 0 10px;
  }

  .voucher-info-left {
    width: 100%;
    margin-bottom: 10px;
  }
  .voucher-points {
    font-size: 24px;
  }
  .prog-bar {
    width: 100%;
    height: 6px;
  }
  .voucher-info-right p:first-of-type {
    font-size: 14px;
  }
  .voucher-info-right p:nth-of-type(2) {
    font-size: 13px;
  }
  .voucher-info-right p:last-of-type {
    font-size: 12px;
  }
  .voucher-tags {
    width: 100%;
    margin: 1%;
  }
  .loyalty-tabs .nav-tabs .nav-link {
    font-size: 14px;
    padding: 4px;
  }
  .voucher-info-container-lower .nav-tabs .nav-link {
    font-size: 10px;
  }
  .track-margin p {
    font-size: 12px;
  }
  .pref-ques {
    width: 20%;
    font-size: 12px;
    padding: 0;
  }
  .pref-categories {
    width: 80%;
    padding: 0 0 0 10px;
  }
  .pref-cat-box {
    padding: 2px 10px;
    margin: 0 5px 5px 0;
    font-size: 10px;
  }
  .booking-history {
    display: flex;
    flex-wrap: wrap;
    border-bottom: 1px solid #eee;
  }
  .booking-history-date {
    width: 100%;
  }
  .booking-history-poster {
    width: 100%;
  }
  .booking-history-poster img {
    width: 100%;
    height: 170px;
    object-fit: cover;
    object-position: top;
  }
  .booking-history-info {
    width: 100%;
    margin-top: 10px;
    padding-left: 0;
  }
  .btn-food {
    margin-right: 0;
  }
  .booking-history-info {
    width: 100%;
    flex-wrap: wrap;
  }
  .booking-history-info-left {
    width: 100%;
  }
  .booking-cancel {
    width: 50%;
    margin: auto;
    margin-top: 10px;
  }
  .booking-cancel img {
    width: 100%;
  }
  .loyalty-edit-upper a {
    font-size: 12px;
  }
  .rewards-table table {
    width: 85%;
  }

  .price-ticket p {
    
    text-align: center;
    width: 100%;
}
}

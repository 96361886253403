@import url("https://fonts.googleapis.com/css2?family=Oswald:wght@700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Oswald&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat&display=swap");
@import url("https://fonts.cdnfonts.com/css/gotham");
/* @font-face {
  font-family: "Gotham-medium";
  font-family: "Gotham-book";
  src: url(./fonts/GothamMedium.ttf) format("ttf");
  src: url(./fonts/GothamBook.ttf) format("ttf");
} */
html {
  scroll-behavior: smooth;
}
body {
  font-family: "Gotham", sans-serif;
  box-sizing: border-box;
  background-image: url("./assets/bg.png");
  background-size: 100%;
  background-repeat: repeat;
  overflow-x: hidden;
  color: #fff !important;
}
h1,
h2,
h3,
h4 {
  font-family: "Oswald", sans-serif;
}
p,
a {
  font-family: "Gotham", sans-serif;
  letter-spacing: 1px;
}
/* width */
::-webkit-scrollbar {
  width: 2px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #c5292a;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #c5292a;
}

.modal-dialog {
  max-width: 300px !important;
  margin: 1.75rem auto !important;
}
.btn-primary.focus,
.btn-primary:focus {
  color: #fff;
  background-color: #0069d9;
  border-color: #0062cc;
  box-shadow: none !important;
}
.movie-preview h4 {
  font-family: "Gotham", sans-serif;
  font-weight: 600;
  font-size: 16px;
}
.hidden {
  display: none;
}
.srv-validation-message {
  font-size: 12px;
  margin-bottom: 5px;
}
.trailer-box {
  position: relative;
  padding: 0 20px;
}
.trailer-cross {
  position: absolute;
  width: 25px;
  height: 25px;
  top: 0px;
  right: 0px;
  background-color: #fff;
  font-size: 12px;
  border-radius: 50%;
  border: none;
  background-color: #ffffff;
  color: #242424;
  display: flex;
  justify-content: center;
  align-items: center;
}

.blur {
  filter: grayscale(100%);
}
.loc-modal {
  position: relative;
  z-index: 999;
}

.loc-modal .ReactModal__Content.ReactModal__Content--after-open {
  width: 70% !important;
  margin: auto !important;
  max-height: 500px !important;
  top: 50px !important;
}
.loc-modal iframe {
  width: 100% !important;
}
.loc-modal .ReactModal__Overlay.ReactModal__Overlay--after-open {
  background-color: #000000ab !important;
  inset: 0;
}
.login-modal .ReactModal__Overlay.ReactModal__Overlay--after-open {
  z-index: 999 !important;
  background-color: #000000ab !important;
}
.login-modal .ReactModal__Content.ReactModal__Content--after-open {
  width: 27% !important;
  background-color: transparent !important;
  padding: 0 !important;
  margin: 80px auto;
  position: relative !important;
  border: 0px !important;
  z-index: 999 !important;
}
.pointer-cursor {
  cursor: pointer;
}
.wow-img img {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 30%;
  padding-top: 60px;
}
.offers-wow-img {
  background-color: black;
}
.box.box-unavailable span {
  display: flex;
}
.flex-text {
  display: flex;
  justify-content: space-around;
  align-items: center;
}
@media (min-width: 1440px) {
  .container {
    max-width: 1300px !important;
  }
}
@media (min-width: 1366px) and (max-width: 1439px) {
  .container {
    max-width: 1200px !important;
  }
}
@media (min-width: 1280px) and (max-width: 1365px) {
  .container {
    max-width: 1170px !important;
  }
}
@media (min-width: 1200px) and (max-width: 1279px) {
  .container {
    max-width: 1150px !important;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .container {
    max-width: 970px !important;
  }
}
@media (max-width: 428px) {
  .loc-modal .ReactModal__Content.ReactModal__Content--after-open {
    width: 90% !important;
    top: 0px !important;
    inset: 0 !important;
  }
  .login-modal .ReactModal__Content.ReactModal__Content--after-open {
    width: 100% !important;
  }
  button.btn.btn-signup.hide-desktop {
    font-weight: 600;
    font-size: 14px;
  }
}
.react-calendar{
  background: #242424 !important;
}
.react-calendar__tile--now
{
  background: #a2a264 !important;
}
.react-calendar__tile:disabled {
  background-color: #f0f0f0;
  color: darkgray !important;
}

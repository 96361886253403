.offers {
  width: 100%;
  padding-top: 100px;
}
.offers-img {
  margin: 30px 0;
}
.offers-img img {
  width: 100%;
  display: block;
}
.offers-info-head h3 {
  font-size: 34px;
  padding: 5px 0;
}
.offers-info-head h4 {
  padding: 4px 0;
}
.offers-info-body h5 {
  font-size: 18px;
}
.offers-list li {
  font-size: 14px;
}
.main-offers-display {
  justify-content: unset !important;
}
.main-offers-tags {
  width: 391px !important;
}
.offers-pic {
  position: relative;
}
.back-button {
  position: absolute;
  left: 3%;
  top: 8%;
  cursor: pointer;
  width: 50px;
  height: 50px;
  background-color: #242424;
  font-size: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
}
.offers-pic-container {
  width: 100%;
}
.offers-pic-container img {
  width: 100%;
}
.for-mobile {
  display: none;
}
.main-offers-tags a {
  cursor: pointer;
}
@media (max-width: 428px) {
  .back-button {
    left: 3%;
    top: 10%;
    width: 30px;
    height: 30px;
    font-size: 12px;
  }
  .for-mobile {
    display: block;
  }
  .for-desktop {
    display: none;
  }
  .offers-pic {
    padding-top: 80px;
    background-color: #000;
  }
  .offers {
    padding-top: 70px;
  }
  .offers-info-head h3 {
    font-size: 24px;
  }
  .offers-info-container {
    padding: 0 10px;
  }
  .offers-info-head h4 {
    font-size: 18px;
  }
  .offers-info-body h5 {
    font-size: 16px;
  }
}

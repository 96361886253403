span.light-weight {
  font-weight: 300;
  font-size: 14px;
}
.syn-heading {
  font-size: 28px;
  font-weight: 500;
}
.upper-upcoming {
  height: 70vh;
}
.lower-upcoming {
  /* flex-direction: column; */
  height: 30vh;
  padding-top: 95px;
  padding-right: 15px;
}

.lower-upcoming h3 {
  width: 100%;
}
.lower-upcoming p {
  width: 100%;
}
@media (max-width: 428px) {
  .now-showing .dropdown {
    float: right;
  }
  .now-show-tab:last-of-type {
    width: 15%;
  }
  .now-showing-top {
    width: 87%;
    justify-content: space-between;
  }
  .upper-upcoming {
    height: auto;
  }
  .lower-upcoming {
    flex-direction: column;
    height: auto;
    padding-top: 20px;
  }
}

.comingsoon-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  position: relative;
}
.comingsoon-container h1 {
  font-size: 80px;
  border-bottom: 2px solid #c5292a;
}
.comingsoon-container img {
  display: block;
  width: 100%;
}
.error-contain {
  position: absolute;
  bottom: 20px;
  right: 30px;
  font-size: 34px;
}
@media (max-width: 428px) {
  .comingsoon-container h1 {
    font-size: 74px;
  }
}

.card-details-text {
  padding: 0.5rem 0rem;
  border-bottom: 1px solid #484848;
  text-transform: uppercase;
 
}
button, input, optgroup, select, textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  background-color: transparent;
  /*border: transparent;
  */border-bottom: 1px solid #484848;
  border-right: transparent;
  box-shadow: none;
  border-top: transparent;
 border-left: transparent;
 
 padding: 6px 0px;
    font-size: 13px;

}


input[type='checkbox'] {
  accent-color: #c5292a;
}

button.btn.btn-normal.proceed-btn.btn-apply.btn-redeem {
  
  margin-top: 0px;
  
  margin-bottom: 18px;
}
#cardNumber {
  width: 100% !important;
  text-transform: uppercase;
}
.input-date {
  display: flex;
  justify-content: space-between;
  padding: 10px 0px;
}
.border-card {
  /* border-bottom: 1px solid #484848; */
  border-right: 1px solid #c5292a !important;
  display: block;
}
.nav-pills .nav-link {
 
  border-radius: 0px;
}

/* .flex-column.payment-options.border-card.nav.nav-pills {
  border-bottom: 1px solid #484848;
} */
/* .card-details svg {
  position: relative;
  top: 24px;
  left: 92%;
  display: flex;
  align-items: center;
  justify-content: space-between;
} */

.card-details .hdfc-card {
  background-color: #48453d5c;
  /* border: 1px solid #a17979; */
  border-bottom: 1px solid #484848;
  padding: 5px 9px;
}

.card-details svg {
  position: relative;
  top: 101px;
  left: 60%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: absolute;
}


/* .flex-column.payment-options.border-card.nav.nav-pills:last-of-type {
  border-bottom: transparent;
} */
.border-card:last-of-type{
  border-bottom: transparent;
}
button.btn.btn-normal.proceed-btn {
  padding: 5px 12px !important;
  font-size: 11px;
  margin-top: 18px;
}
button.btn-pay.proceed-btn.btn {
  background-color: #c5292a;
  padding: 7px 42px !important;
  margin-bottom: 20px;
  width: 28%;
  
}
.btn-pay{
  margin-top: 11px;
}
.card-section {
  background-color: #171614;
  height: 335px;
}
.card-name input {
  width: 100%;
}

button, input {
  overflow: visible;
  outline: none;
  color: #fff;
  text-transform: uppercase;
}
.gray-box {
  background-color: #242424;
}
h3,
h4 {
  color: #fff;
  font-size: 20px;
  text-transform: uppercase;
  font-family: "Oswald", sans-serif;
  letter-spacing: 1px;
}
.payment-section {
  padding: 0;
  width: 100%;
  margin: 0 auto;
}
.gray-box {
  padding: 30px 30px 0 30px;
  margin-bottom: 30px;
}
.border-bot {
  border-bottom: solid 0.5px #484848;
}
.contact-details {
  padding: 20px 0;
}
.contact-form {
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.input-box {
  background-color: #171614;
  padding: 10px;
  border-radius: 2px;
}
.card-details {
  
  display: inline-block;
  display: flex;
  flex-direction: column;

  padding-left: 82px;
}
.input-box input {
  background-color: #171614;
  border: black;
  color: #fff;
  padding-left: 10px;
  outline: none;
}
.offer-preview-display {
  padding: 20px 0;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.card-detail {
  color: #515050;
}
.payment-details {
  padding: 5px;
  width: 100%;
  padding: 20px 0 25px 0;
}
.payment-options {
  width: 94%;
  background-color: #171614;
  margin-left: 15px;
}
.card-details .tab-content {
  width: 70%;
  box-shadow: rgb(100 100 111 / 20%) 0px 7px 29px 0px;
  margin: 20px 0px;
  padding: 30px 20px;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
}
.payment-section-container {
  width: 73%;
}
.card-info-box {
  width: 85%;
  padding: 20px;
}
.offer-img {
  width: 48%;
}
.offer-img img {
  width: 100%;
}
.card-info-section{
  padding: 50px;
}
.card {
  background-color: #171614;
  /* box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
      rgba(60, 64, 67, 0.15) 0px 2px 6px 2px; */
  box-shadow: rgb(0 0 0 / 30%) 0px 1px 5px 0px, rgb(0 0 0 / 15%) 2px 2px 6px 4px;
  padding: 10px 15px;
  margin-bottom: 15px;
}
.card-details {
  padding: 0 30px;
  background-color: #171614;
  margin: 0 20px;
  cursor: pointer;
}
.card-details .hdfc-card {
  margin-left: 50px;
}
.food-img p {
  
    bottom: 18% !important;

}

.nav-link {
  border-bottom: solid 0.5px #484848;
}
.nav-link:last-of-type {
  border-bottom: solid 0.5px transparent;
}
.nav-link:first-of-type {
  border-top: transparent;
}

.nav-pills a {
  color: #fff;
}
.nav-pills a {
  color: #fff;
}
.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  background-color: transparent;
  /* border-right: 2px solid #c5292a; */
  margin-right: -3px;
  border-radius: 0;
}
.card-heading {
  padding-top: 10px;
}
.card-info {
  margin-bottom: 10px;
}
.card-info input {
  background-color: #171614;
  border: none;
  outline: none;
}
.card-detail {
  margin-bottom: 0;
}
.expiry {
  width: 58%;
  margin-right: 10px;
}
.cvv {
  width: 38%;
}
.card-form input {
  color: #fff;
}
.btn {
  border-radius: 5px;
  color: #fff;
  font-size: 15px;
  padding: 8px 20px;
}
.btn-default {
  background-color: #c5292a;
  border: 2px solid #c5292a;
  padding: 6px 15px;
  font-weight: 600;
}
#cv-detail {
  width: 100%;
}

.payment-heading .col-sm-4 {
  padding: 0 30px 0 0;
}
.payment-options .nav-item {
  border-bottom: 1px solid #484848 !important;
}
.card-details {
  background-color: #171614;
  margin: 0;
 
}
.payment-container {
  margin-top: 20px;
}
.gray-box:last-of-type {
  padding-bottom: 20px;
}
.ticket_prints ._float_right {
  float: unset;
}
/* .ticket_prints ._info__ ul {
  display: flex;
  flex-wrap: wrap;
  margin-top: 10px;
} */
._print_tickets ._info__ ul li {
  display: flex;
  flex-wrap: wrap;
  margin-top: 10px;
  justify-content: space-between;
  font-size: 14px;
}
._print_tickets ._info__ ul li ._food_item_name {
  width: 48%;
}
._print_tickets ._info__ ul li ._food_item_name:last-of-type {
  text-align: right;
}
.payment-movie-info .booking_summary._35px {
  padding: 0;
}
.payment-movie-info .movie_name__ {
  font-weight: 500 !important;
}
.payment-movie-info .ticket_prints._35px {
  padding: 0;
}
.seats-flex {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.seats-flex li {
  margin: 1%;
}
.offer-preview-display .voucher-tags {
  width: 45%;
}
.booking-container .movie-info {
  width: 20%;
}
.booking-container .seats-flex li {
  margin: 2%;
}
.booking-container .square-seat {
  width: 35px;
  height: 35px;
}
@media (max-width: 428px) {
.card-details svg {
    left: 76%;
  }
  /* .card-details svg {
    left: 78%;
}
  button, input{
    width: 100%;
  } */
  button.btn-pay.proceed-btn.btn{
    width: 100%;
  }
  .card-info-section{
    padding: 50px 0px;
  }

  .food-img p {
    bottom: 42% !important;
}
  .input-date{
    display: inline;
  }
  #expiryDate {
    width: 100%;
}
#cvc{
  width: 100%;
}
#cvc::placeholder {
 
  content:"CVV" !important;
}
  .booking-container {
    padding: 110px 30px;
    flex-wrap: wrap;
  }
  .booking-container .movie-info {
    width: 100% !important;
    margin: auto;
  }
  .booking-container .square-seat {
    width: 37px;
    height: 37px;
  }
  .booking-success-container {
    width: 100%;
  }
  .card-details {
    margin: 20px 15px 0 15px;
    padding: 0 20px;
  }
  .card-details .tab-content {
    width: 100%;
  }
  .wow_successfully {
    width: 80%;
    margin: auto;
  }
  .successfully_text h2 {
    font-size: 24px;
  }
  .successfully_text p {
    font-size: 14px;
  }
  .wow_successfully_btn {
    display: flex;
    justify-content: space-between;
    padding: 0 10px;
  }
  button.wow_btn {
    margin-right: 0;
  }
  .btn {
    font-size: 13px;
  }
  .offer-preview-display .voucher-tags {
    width: 100%;
  }
  .voucher-off p:last-of-type {
    font-size: 8px;
  }
  .voucher-code {
    font-size: 8px;
  }
}




.apply-coupon {
  display: flex;
}

  button.btn.btn-normal.proceed-btn.btn-apply {
    margin-left: 15px;
}
.offer-info {
 
  margin-bottom: 30px;
}
.hidden {
  display: none;
}
.offer-info img {
  width: 18px;
  margin-left: 13px;
  margin-right: 13px;
}


.trailer {
  padding: 60px 0px 60px 0px;
  background-image: linear-gradient(rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.8)),
    url("../../assets/trailerbg.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.trailer h3 {
  text-transform: uppercase;
  font-family: "Oswald", sans-serif;
  font-weight: 600;
  font-size: 40px;
  letter-spacing: -1.4px;
  padding-bottom: 2px;
}
.display-trailer {
  display: flex;
}
.video-player {
  padding-top: 30px;
  width: 70%;
  height: auto;
}
.video-player iframe {
  display: block;
  width: 100%;
  height: 437px;
}
.suggestions {
  width: 30%;
  padding-top: 30px;
  padding-left: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.trai-modal .ReactModal__Content {
  width: 70%;
  margin: auto;
  height: -moz-fit-content;
  height: fit-content;
  padding: 0 !important;
  border: 1px solid #000 !important;
}
.trai-modal .video-player {
  width: 100%;
  padding: 0;
}
.trai-modal .video-player button {
  position: absolute;
  right: 0;
  top: 0;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  border: none;
  color: #fff;
  background-color: #242424;
}
.trai-modal .ReactModal__Overlay.ReactModal__Overlay--after-open {
  background-color: #6868684f !important;
  backdrop-filter: blur(5px);
}
.trai-modal .ReactModal__Overlay {
  z-index: 999;
}
@media (max-width: 768px) {
  .trailer {
    padding: 60px;
  }
  .display-trailer {
    flex-direction: column;
  }
  .video-player {
    width: 100%;
  }
  .suggestions {
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    justify-content: space-between;
  }
  .suggestion-preview {
    width: 49%;
    margin-bottom: 10px;
  }
}
@media (max-width: 428px) {
  .video-player iframe {
    height: 500px !important;
  }
  .trai-modal .ReactModal__Content {
    width: 90%;
    inset: 20px !important;
  }
  .trailer h3 {
    font-size: 24px;
  }
  .video-player iframe {
    height: auto;
  }
  .suggestions {
    padding-left: 0;
  }
  .suggestion-preview {
    width: 100%;
    margin-bottom: 10px;
  }
  .trailer {
    padding: 30px 20px;
  }
}

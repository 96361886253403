.movie-info-detail {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  font-size: 90%;
  float: left;
}
.movie-info-left {
  width: 30%;
  font-weight: 700;
  padding-bottom: 8px;
}
.movie-info-right {
  width: 70%;
  padding-bottom: 8px;
}
.movie-about {
  padding-top: 10px;
  width: 80%;
}
.showtimes .nav {
  width: 100% !important;
}

.booking-container {
  padding: 80px 0;
  display: flex;
  justify-content: space-between;
}
.booking-success-container {
  width: 100%;
  margin-top: 40px;
}
.wow_successfully {
  text-align: center;
  background-color: #242424;
  padding: 50px 0px;
  width: 100%;
}
.successfully_img img {
  width: 100px;
}
.successfully_text h2 {
  /* font-family: Gotham; */
  font-size: 34px;
  color: #fff;
  font-weight: 600;
  line-height: 1.45;
  letter-spacing: -0.16px;
  margin: 15px 0px;
}
.successfully_text p {
  /* font-family: Gotham; */
  font-size: 20px;
  color: #fff;
  line-height: 1.56;
  letter-spacing: -0.18px;
  font-weight: 600;
  margin: 10px 0px;
}
button.wow_btn {
  /* font-family: Gotham; */
  font-size: 18px;
  font-weight: 600;
  color: #fff;
  margin-right: 32px;
}
.wow_download {
  background-color: transparent;
  border: 1px solid;
  padding: 10px 22px;
}
button.wow_btn img {
  vertical-align: text-top;
  margin-right: 6px;
}
button.wow_btn.wow_share {
  padding: 11px 27px;
  background: #c5292a;
  border: 1px solid #c5292a;
}
.wow_successfully_btn {
  padding: 30px 0px 0px 0px;
}

@media (max-width: 428px) {
  button.wow_btn {
    font-size: 14px;
  }
}

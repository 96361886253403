@import url(https://fonts.googleapis.com/css2?family=Oswald:wght@700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Oswald&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Montserrat&display=swap);
@import url(https://fonts.cdnfonts.com/css/gotham);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* red color: #c5292a*/

.header {
  height: 78vh;
  background-color: #000;
}

.header .carousel .carousel-inner .carousel-item img {
  width: 100%;
  height: 600px;
  opacity: 0.7;
}
/* .header .react-multi-carousel-item{
  background-color: #fff;
} */
.carousel-caption {
  left: 10% !important;
  bottom: 50px !important;
}
.carousel-caption h3 {
  font-size: 40px;
  font-weight: 700;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.13;
  text-align: left;
  color: #ffffff;
  text-transform: uppercase;
  letter-spacing: -1.3px;
  -webkit-transform: scale(1, 1.4);
          transform: scale(1, 1.4);
  margin-bottom: 20px;
}
.carousel-caption p {
  opacity: 0.9;
  font-size: 24px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  text-align: left;
  color: #ffffff;
  margin-bottom: 20px;
}
.seperator {
  margin: 0 5px;
}
.carousel-indicators {
  bottom: -50px !important;
}
.carousel-indicators li {
  width: 10px !important;
  height: 10px !important;
  border-radius: 50% !important;
}
.carousel-indicators .active {
  background-color: #c5292a !important;
}
.btn {
  padding: 10px 20px !important;
  border-radius: 5px;
  margin-right: 15px;
  float: left;
}
.btn-normal {
  background-color: #c5292a !important;
  border: 2px solid #c5292a !important;
  transition: background-color 300ms ease-in;
}
.btn-normal:hover {
  background-color: #d42929 !important;
}
.btn-ghost {
  background-color: transparent !important;
  border: 2px solid #fff !important;
}
.btn a {
  color: #fff;
  font-weight: 600;
}
.btn a:hover {
  text-decoration: none;
  color: #fff;
}
.carousel-container-banner .w-100 {
  width: 100% !important;
  /* height: 600px; */

  height: 100vh;
  background-color: #000;
  opacity: 0.8;
}
.carousel-container-banner .react-multiple-carousel__arrow {
  z-index: 998;
}
.carousel-container-banner .react-multiple-carousel__arrow::before {
  font-size: 40px;
}
.carousel-container-banner .react-multiple-carousel__arrow {
  background: rgb(0 0 0 / 0%);
}
.carousel-container-banner .react-multiple-carousel__arrow:hover {
  background: rgb(0 0 0 / 0%);
}
.banner-custom-dot-list-style .react-multi-carousel-dot button {
  width: 10px;
  height: 10px;
  border-color: transparent;
}
.banner-custom-dot-list-style .react-multi-carousel-dot button {
  background: #c6c6c6;
}
.banner-custom-dot-list-style .react-multi-carousel-dot--active button {
  background: #c5292a;
}
.header .react-multi-carousel-list {
  padding-bottom: 30px;
}
.banner-div {
  position: relative;
  /* height: auto; */
}
.book-now-banner {
  position: absolute;
  left: 2%;
  bottom: 110px;
  z-index: 99;
}
.book-now-banner h1 {
  font-size: 38px;
  font-weight: 700;
}
.book-now-banner .btn-normal {
  padding: 5px 15px !important;
  margin-top: 10px;
  transition: background 0.3s, border 0.3s, color 0.3s;
}
.book-now-banner .btn-normal:hover {
  background-color: #fff !important;
  border: 2px solid #fff !important;
}
.book-now-banner .btn-normal:hover a {
  color: #c5292a;
}
.carousel-container-banner
  li.react-multi-carousel-item.react-multi-carousel-item--active:before {
  content: "";
  height: 250px;
  width: 100%;
  position: absolute;
  bottom: 0;
  background: linear-gradient(0deg, #000, transparent);
  z-index: 99;
}
@media (max-width: 428px) {
  .header .carousel .carousel-inner .carousel-item img {
    object-fit: cover;
  }
  .header {
    height: auto;
    background-color: transparent;
    margin-top: 75px;
  }
  .book-now-banner h1 {
    font-size: 24px;
    font-weight: 700;
  }
  .header .carousel-slider .slide img {
    height: 350px;
  }
  .carousel-container-banner .w-100 {
    width: 100% !important;
    height: 270px;
    background-color: #000;
    opacity: 0.8;
  }
  .header .react-multi-carousel-list {
    padding-bottom: 20px;
  }
  .carousel-container-banner
    li.react-multi-carousel-item.react-multi-carousel-item--active:before {
    height: 150px;
  }
  .book-now-banner {
    position: absolute;
    left: 5%;
    bottom: 15px;
    z-index: 99;
  }
}
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-text-fill-color: #fff;
  -webkit-box-shadow: 0 0 0px 1000px #171614 inset;
  -webkit-transition: background-color 5000s ease-in-out 0s;
  transition: background-color 5000s ease-in-out 0s;
}

.now-showing {
  padding: 0 0 0 0;
  margin-top: 6%;
}
.now-showing .dropdown {
  width: 13%;
}
.now-showing .dropdown img {
  width: 100%;
}
.now-showing .btn-success {
  background-color: transparent;
  border: 1px solid #be2829;
}
.now-showing .dropdown-toggle::after {
  border-top: 0.3em solid #c5292a;
  outline: none;
}
.now-showing .btn-success:not(:disabled):not(.disabled).active,
.btn-success:not(:disabled):not(.disabled):active,
.show > .btn-success.dropdown-toggle {
  background-color: transparent !important;
  outline: none;

  border-color: #be2829 !important;
}
.now-showing .btn-success.focus,
.btn-success:focus {
  border-color: #be2829 !important;
  box-shadow: none !important;
  background-color: transparent !important;
}
.now-showing .btn-success:not(:disabled):not(.disabled).active:focus,
.btn-success:not(:disabled):not(.disabled):active:focus,
.show > .btn-success.dropdown-toggle:focus {
  box-shadow: none !important;
}
.now-showing .btn-success:hover {
  background-color: transparent !important;
  border-color: #be2829 !important;
}
.now-show-tab a:hover {
  text-decoration: none;
  cursor: pointer;
  /* color: #0e0b0b !important; */
  width: auto;
  /* background-color: #fff; */
}
.now-showing-top {
  display: flex;
  justify-content: space-between;
}
.now-showing h2 {
  text-transform: uppercase;
  font-family: "Oswald", sans-serif;
  font-weight: 600;
  font-size: 40px;
  letter-spacing: -1.4px;
  padding-bottom: 2px;
  /* border-bottom: 2px solid #c5292a; */
}
.movie-preview-collection {
  display: flex;
  flex-wrap: wrap;
  /* justify-content: space-between; */
}
.view-button {
  display: flex;
  justify-content: center;
}
.view-btn {
  padding: 10px 30px !important;
  font-weight: 500 !important;
}
.icons-arr svg {
  margin-top: -3px;
}
.now-showing-top .react-tabs {
  display: flex;
  flex-direction: column;
}
.now-showing-top .react-tabs__tab-list {
  width: 100%;
}
#now-show {
  /* padding-top: 30px; */
}
.now-showing-top .react-tabs {
  width: 100%;
}
.now-showing-top .react-tabs__tab-list {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.now-showing-top .react-tabs__tab a {
  color: unset;
  text-decoration: none;
}
.now-showing-top .react-tabs__tab-list {
  border-bottom: 0px solid #aaa;
  margin: 0 0 10px;
  padding: 0;
  flex-wrap: wrap;
}
.now-show-tab .react-tabs__tab--selected {
  background: transparent;
  border-color: transparent;
  color: #fff;
  border-radius: 0;
}
.now-show-tab .react-tabs__tab--selected h2 {
  border-bottom: 2px solid #c5292a;
}
.now-genre::after {
  display: none !important;
}
.genre-drop button#dropdown-button-dark-example1 {
  background-color: #212529;
}
.genre-drop .dropdown-menu {
  min-width: 10rem;
  padding: 0.2rem 0;
  background-color: #242424;
}
.genre-drop .dropdown-item {
  background-color: #212529 !important;
  color: #fff !important;
}
.genre-drop-btn {
  min-width: 10rem;
  padding: 0.2rem 0;
}
.genre-drop-btn.btn-primary {
  min-width: 10rem;
  padding: 0.5rem 0 !important;
  background-color: #242424;
  border: 1px solid #242424;
}
.genre-drop-btn.btn-primary:not(:disabled):not(.disabled):active {
  background-color: #242424;
  border-color: #242424;
}
.genre-drop-btn.btn-primary:hover {
  background-color: #242424;
  border: 1px solid #242424;
}
.show > .genre-drop-btn.btn-primary.dropdown-toggle {
  color: #fff;
  background-color: #242424;
  border-color: #242424;
}
.genre-drop-btn.btn-primary.focus,
.genre-drop-btn.btn-primary:focus {
  background-color: #242424;
  border-color: #242424;
}

.movie-preview .img-showing img {
  width: 100%;
}
.view-button {
  padding: 20px 0;
}
.view-button a:hover button {
  color: #fff;
}
img.icon {
  width: auto !important;
}
/* without tab design */
.now-showing.container {
  position: relative;
  z-index: 3;
  padding-bottom: 30px;
}
.now-showing-top {
  display: flex;
  justify-content: flex-start;
  /* padding-top: 50px; */
}
.now-show-tab {
  margin: 0 10px 10px 0;
}
.now-show-tab a {
  color: unset;
  text-decoration: none;
  display: inline-block;
  margin: 0 5px 0 15px;
}
.now-show-tab.active a {
  border-bottom: 2px solid #c5292a;
}
.now-show-tab a:hover {
  text-decoration: none;
  color: #fff;
}
@media (max-width: 1200px) {
  .movie-preview-collection .movie-preview {
    width: 30%;
  }
  .movie-preview-collection .movie-preview:last-of-type {
    display: none;
  }
}
@media (max-width: 768px) {
  .now-showing {
    padding: 60px;
  }
}
@media (max-width: 428px) {
  .now-showing {
    padding: 30px;
    font-size: 24px;
    margin-top: 0%;
  }
  .genre-drop-btn.btn-primary {
    min-width: 7rem;
  }

  .movie-preview-collection .movie-preview {
    width: 48%;
  }
  .movie-preview-collection {
    justify-content: space-between;
  }
  .movie-preview-collection .movie-preview:last-of-type {
    display: unset;
  }
  .movie-preview .img-showing img {
    transition: opacity 0.5s, -webkit-transform 0.5s;
    transition: transform 0.5s, opacity 0.5s;
    transition: transform 0.5s, opacity 0.5s, -webkit-transform 0.5s;
    display: block;
    -webkit-transform: scale(1);
    transform: scale(1);
    width: 100%;
  }
  .movie-preview:hover .img-showing img {
    opacity: 0.5;
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  img.icon {
    width: 75% !important;
  }
  .now-showing h2 {
    font-size: 24px;
  }
  .genre {
    font-size: 16px;
  }
  .now-show-tab .react-tabs__tab {
    padding: 6px;
  }
  .genre-btn-contain {
    width: auto;
    text-align: right;
  }

  .genre {
    width: 80px !important;
    font-size: 10px;
    padding: 5px;
    height: 35px;
    margin-top: 0 !important;
    text-align: left;
  }
  .select-genre:after {
    top: 50%;
    right: 5%;
    font-size: 15px;
  }
  .now-show-tab {
    margin: 0 0px 10px 0;
  }
  .now-show-tab a {
    color: unset;
    text-decoration: none;
    display: inline-block;
    margin: 0 5px 0 5px;
  }
}

@media (min-width: 375px) and (max-width: 413px) {
  .now-showing {
    padding: 20px;
  }
}

.movie-genre {
  width: 100%;
  font-size: 12px;
  /* opacity: 0; */
  padding: 0 10px;
  position: absolute;
  left: 0;
  bottom: 28px;
  transition: opacity 0.3s, -webkit-transform 0.3s ease-in;
  transition: opacity 0.3s, transform 0.3s ease-in;
  transition: opacity 0.3s, transform 0.3s ease-in, -webkit-transform 0.3s ease-in;
  transition: opacity 0.3s, transform 0.3s ease-in,
    -webkit-transform 0.3s ease-in;
}

.navbar-nav .dropdown-menu {
 
  float: none;
  position: absolute !important;
}

.movie-genre p {
  font-size: 14px !important;
  color: #fff !important;
  margin-left: -7px;
  font-weight: 700;
}
.book {
  display: flex;
  justify-content: space-between;
}
.movie-genre img {
  margin-right: 20px;
}
.movie-genre a {
  text-decoration: none !important;
  color: #000;
  font-weight: 600;
}
.movie-genre img {
  margin-top: 3px;
}

/* .movie-preview:hover .movie-genre {
  opacity: 1;
  transform: translate(0, -15px);
} */
.trailer-btn {
  display: none;
}
.release.movie-preview .book {
  display: none;
}
.release.movie-preview .trailer-btn {
  display: unset;
  font-size: 100%;
}

.book a:first-of-type {
  display: flex;
  justify-content: center;
  align-items: center;
  transition: background 0.3s, color 0.3s;
}
.book a:hover:first-of-type {
  background-color: #fff;
  color: #c5292a !important;
}
img.icon {
  -webkit-transform: unset !important;
          transform: unset !important;
  margin-right: unset !important;
  cursor: pointer;
}
.book-now a {
  transition: color 200ms ease-in;
}
.book-now:hover a {
  color: #c5292a !important;
}
.book-now {
  background-color: #c5292a;
}
.book-now {
  color: #fff !important;
}

@media (max-width: 428px) {
  .movie-genre img {
    margin: 0 0 0 15px;
  }
  .movie-preview:hover .movie-genre {
    -webkit-transform: translateY(-15px);
            transform: translateY(-15px);
  }
  .book a:first-of-type {
    margin-top: 0;
  }

  .dropdown .btn {
    
    font-size: 14px;
    align-items: center;
    justify-content: center;
    display: flex;
    margin: 0px auto;
    
}

}

.movie-preview {
  width: 17%;
  position: relative;
  margin: 20px 1.5% 10px 1.5%;
}
.release {
  width: 90%;
  margin: auto;
}
.releasing {
  font-size: 90% !important;
  text-transform: none !important;
  font-weight: 600;
}
.release-date {
  font-weight: 300;
}
.movie-preview h4 {
  font-size: 120%;
  padding-left: 8px;
  font-weight: 600;
  margin-top: 20px;
  text-transform: uppercase;
}
.movie-preview p {
  padding-left: 8px;
  text-transform: uppercase;
  font-size: 80%;
  color: #9b9b9b;
}
.img-showing {
  overflow: hidden;
  height: 315px;
  position: relative;
}
.movie-preview .img-showing img {
  transition: opacity 0.5s, -webkit-transform 0.5s;
  transition: transform 0.5s, opacity 0.5s;
  transition: transform 0.5s, opacity 0.5s, -webkit-transform 0.5s;
  display: block;
  -webkit-transform: scale(1);
  transform: scale(1);
  height: 100%;
}
.release .img-showing img {
  height: 100%;
}
.movie-preview:hover .img-showing img {
  opacity: 0.5;
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
@media (max-width: 428px) {
  .release {
    width: 60%;
    margin: auto;
  }
  .release .img-showing img {
    height: 100%;
    width: 100%;
  }
  .movie-preview h4 {
    font-size: 80%;
  }
  .movie-preview p {
    font-size: 60%;
  }
  .movie-preview p {
    font-size: 60%;
  }
  .img-showing {
    height: 250px;
  }
  .movie-preview {
    margin: 10px 1% 0px 1%;
  }
}

.upcoming-movies {
  padding: 60px 120px;
}
.upcoming-movies h3 {
  text-transform: uppercase;
  font-family: "Oswald", sans-serif;
  font-weight: 600;
  font-size: 40px;
  letter-spacing: -1.4px;
  padding-bottom: 2px;
}
.carousel-button-group {
  position: relative;
}
.left-btn-slide,
.right-btn-slide {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: #2c2c2c;
  border: none;
  color: #fff;
  position: absolute;
  bottom: 180px;
}
.left-btn-slide {
  left: -50px;
}
.right-btn-slide {
  right: -40px;
}
.upcom-nowhowing {
  padding: 0;
}
.release .img-showing {
  background-color: #000;
}
.release:hover .img-showing img {
  opacity: 0.5;
}
.release .img-showing img {
  width: 100%;
  transition: opacity 0.3s ease-in;
}
.release .up-genre-contain {
  /* display: none; */
  position: absolute;
  z-index: 99;
  left: 3%;
  bottom: 5%;
  -webkit-transform: translateY(80px);
          transform: translateY(80px);
  transition: -webkit-transform 0.3s ease-in;
  transition: transform 0.3s ease-in;
  transition: transform 0.3s ease-in, -webkit-transform 0.3s ease-in;
}
.release:hover .up-genre-contain {
  display: block;
  -webkit-transform: translateY(0px);
          transform: translateY(0px);
}
.up-genre-contain p {
  margin-bottom: 3px;
  font-size: 12px;
  font-weight: 500;
}
.up-genre-contain button {
  font-size: 14px;
}
.release h4 {
  padding-top: 20px;
}
.releasing {
  color: #9b9b9b;
  font-weight: 500;
}
.release-on {
  font-size: 12px;
}
.release-date {
  font-weight: 500;
  color: #767676;
}
/* new style upcoming */
.show-upcoming {
  display: flex;
  flex-wrap: wrap;
}
.release {
  width: 17%;
  margin: 1% 1.5%;
}
.upmore-info {
  font-size: 12px;
  color: #fff;
}
.upmore-info:hover {
  color: #fff;
  text-decoration: none;
}
.releasing {
  margin-bottom: 0;
}
@media (max-width: 1200px) {
  .upcoming-movies {
    padding: 30px 120px 60px 120px;
  }
}
@media (max-width: 768px) {
  .upcoming-movies {
    padding: 60px;
  }
}

@media (max-width: 428px) {
  
  .release {
    width: 48%;
    margin: 5% 1%;
  }
  .release-date {
    font-size: 14px;
  }
  .lower-upcoming {
    flex-direction: column;
    padding: 10px 45px;
  }
  .releasing {
    line-height: 80%;
  }
  .release h4 {
    padding-top: 10px;
    margin-bottom: 0;
  }
  .upcoming-movies {
    padding: 0;
  }
  .upcoming-movies h3 {
    font-size: 24px;
  }
  .left-btn-slide,
  .right-btn-slide {
    bottom: 160px;
    display: flex;
    justify-content: center;
  }
  .right-btn-slide {
    right: 0;
  }
  .left-btn-slide {
    left: 0;
  }
  .uncontrolled-tab-example-tabpane-synopsis h4 {
    font-size: 18px;
  }
  .release p {
    font-size: 14px;
  }
}

.suggestion-box {
  width: 50%;
}
.suggestion-img {
  width: 140px;
  height: 90px;
  position: relative;
}
.suggestion-img svg {
  position: absolute;
  left: 45%;
  top: 45%;
}

.suggestion-img img {
  width: 100%;
  height: inherit;
  object-fit: cover;
  /* object-position: 0 -40px; */
  display: block;
  opacity: 0.8;
}

.suggestion-preview {
  display: flex;
  background-color: #333;
  padding: 6px;
  border-radius: 1px;
  cursor: pointer;
  transition: background-color 200ms ease-in;
}
.suggestion-preview:hover {
  background-color: rgb(39, 39, 39);
}

.suggestion-detail {
  width: 100%;
  padding-left: 10px;
  padding-top: 5px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}
.suggestion-detail h4 {
  font-size: 90%;
  font-weight: 700;
  font-family: "Gotham-medium", sans-serif;
}
.suggestion-detail p {
  font-size: 70%;
}
@media (max-width: 1200px) {
  .suggestion-img {
    width: 100px;
  }
  .suggestion-detail p {
    font-size: 90%;
  }
}

.trailer {
  padding: 60px 0px 60px 0px;
  background-image: linear-gradient(rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.8)),
    url(/static/media/trailerbg.78b47859.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.trailer h3 {
  text-transform: uppercase;
  font-family: "Oswald", sans-serif;
  font-weight: 600;
  font-size: 40px;
  letter-spacing: -1.4px;
  padding-bottom: 2px;
}
.display-trailer {
  display: flex;
}
.video-player {
  padding-top: 30px;
  width: 70%;
  height: auto;
}
.video-player iframe {
  display: block;
  width: 100%;
  height: 437px;
}
.suggestions {
  width: 30%;
  padding-top: 30px;
  padding-left: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.trai-modal .ReactModal__Content {
  width: 70%;
  margin: auto;
  height: -moz-fit-content;
  height: -webkit-fit-content;
  height: fit-content;
  padding: 0 !important;
  border: 1px solid #000 !important;
}
.trai-modal .video-player {
  width: 100%;
  padding: 0;
}
.trai-modal .video-player button {
  position: absolute;
  right: 0;
  top: 0;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  border: none;
  color: #fff;
  background-color: #242424;
}
.trai-modal .ReactModal__Overlay.ReactModal__Overlay--after-open {
  background-color: #6868684f !important;
  -webkit-backdrop-filter: blur(5px);
          backdrop-filter: blur(5px);
}
.trai-modal .ReactModal__Overlay {
  z-index: 999;
}
@media (max-width: 768px) {
  .trailer {
    padding: 60px;
  }
  .display-trailer {
    flex-direction: column;
  }
  .video-player {
    width: 100%;
  }
  .suggestions {
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    justify-content: space-between;
  }
  .suggestion-preview {
    width: 49%;
    margin-bottom: 10px;
  }
}
@media (max-width: 428px) {
  .video-player iframe {
    height: 500px !important;
  }
  .trai-modal .ReactModal__Content {
    width: 90%;
    inset: 20px !important;
  }
  .trailer h3 {
    font-size: 24px;
  }
  .video-player iframe {
    height: auto;
  }
  .suggestions {
    padding-left: 0;
  }
  .suggestion-preview {
    width: 100%;
    margin-bottom: 10px;
  }
  .trailer {
    padding: 30px 20px;
  }
}

.factors-preview {
  width: 33.33%;
  text-align: center;
  margin-bottom: 50px;
}
.factors-preview p {
  width: 70%;
  margin: auto;
  font-family: "Gotham", sans-serif;
  color: #c6c6c6;
  font-size: 14px;
  padding-top: 10px;
}
.factors-img {
  margin-bottom: 15px;
}
.factors-preview a {
  text-decoration: none !important;
}

.factors {
  padding: 60px 20px 0px 20px;
}
.factors h3 {
  text-transform: uppercase;
  font-family: "Oswald", sans-serif;
  font-weight: 600;
  font-size: 40px;
  letter-spacing: -1.4px;
  padding-bottom: 2px;
}
.factors-preview-collection {
  margin-top: 30px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  padding: 0 100px;
}

@media (max-width: 1200px) {
  .factors {
    padding: 60px 100px 30px 100px;
  }
  .factors-preview-collection {
    padding: 0;
  }
}
@media (max-width: 768px) {
  .factors {
    padding: 60px;
  }
}
@media (max-width: 428px) {
  .factors {
    padding: 30px;
  }
  .factors-preview {
    width: 100%;
  }
  .factors-preview h4 {
    font-size: 22px;
  }
  .factors h3 {
    font-size: 24px;
  }
}

.offers {
  width: 100%;
  padding-top: 100px;
}
.offers-img {
  margin: 30px 0;
}
.offers-img img {
  width: 100%;
  display: block;
}
.offers-info-head h3 {
  font-size: 34px;
  padding: 5px 0;
}
.offers-info-head h4 {
  padding: 4px 0;
}
.offers-info-body h5 {
  font-size: 18px;
}
.offers-list li {
  font-size: 14px;
}
.main-offers-display {
  justify-content: unset !important;
}
.main-offers-tags {
  width: 391px !important;
}
.offers-pic {
  position: relative;
}
.back-button {
  position: absolute;
  left: 3%;
  top: 8%;
  cursor: pointer;
  width: 50px;
  height: 50px;
  background-color: #242424;
  font-size: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
}
.offers-pic-container {
  width: 100%;
}
.offers-pic-container img {
  width: 100%;
}
.for-mobile {
  display: none;
}
.main-offers-tags a {
  cursor: pointer;
}
@media (max-width: 428px) {
  .back-button {
    left: 3%;
    top: 10%;
    width: 30px;
    height: 30px;
    font-size: 12px;
  }
  .for-mobile {
    display: block;
  }
  .for-desktop {
    display: none;
  }
  .offers-pic {
    padding-top: 80px;
    background-color: #000;
  }
  .offers {
    padding-top: 70px;
  }
  .offers-info-head h3 {
    font-size: 24px;
  }
  .offers-info-container {
    padding: 0 10px;
  }
  .offers-info-head h4 {
    font-size: 18px;
  }
  .offers-info-body h5 {
    font-size: 16px;
  }
}

.top {
  padding: 80px 20px 0 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
}
.top-content {
  width: 25%;
  display: flex;
  flex-direction: column;
  padding-top: 30px;
}
.top-content h3 {
  text-transform: uppercase;
  font-family: "Oswald", sans-serif;
  font-weight: 600;
  font-size: 50px;
  letter-spacing: -1.4px;
  padding-bottom: 10px;
}
.top-content h4 {
  text-transform: uppercase;
  font-family: "Oswald", sans-serif;
  font-weight: 600;
  font-size: 30px;
  letter-spacing: -1.4px;
  padding-bottom: 2px;
}
.top-content p {
  margin-top: 40px;
  margin-bottom: 30px;
}
.cinemas-btn {
  width: 70%;
}

.rank {
  display: flex;
  width: 20%;
}
.rank span {
  font-size: 25px;
  font-family: "Oswald", sans-serif;
  margin-right: 10px;
  margin-top: -5px;
}
.top .movie-preview {
  width: 100%;
  margin-top: 0;
}
@media (max-width: 1200px) {
  .top {
    padding: 60px 100px;
  }
  .top-content {
    width: 100%;
    margin-bottom: 30px;
  }
  .cinemas-btn {
    width: 20%;
  }
  .rank {
    width: 30%;
  }
}
@media (max-width: 768px) {
  .top {
    padding: 60px;
  }
  .cinemas-btn {
    width: 25%;
  }
  .rank {
    width: 30%;
    position: relative;
  }
  .rank span {
    position: absolute;
    z-index: 999;
    left: 10px;
    top: 5px;
  }
}
@media (max-width: 428px) {
  .top {
    padding: 30px;
  }
  .cinemas-btn {
    width: 50%;
  }
  .rank {
    width: 100%;
  }
  .rank span {
    position: absolute;
    z-index: 999;
    left: 20px;
    top: 15px;
  }
  .top-content h3 {
    font-size: 24px;
  }
  .top-content h4 {
    font-size: 20px;
  }
  .top .movie-preview {
    width: 60%;
    margin: auto;
  }
  .top-content p {
    margin: 0;
  }
  .top .img-showing {
    height: 270px;
  }
}

.news-preview {
  width: 30%;
  margin: 1.5%;
  background-color: #333;
}
.news-img {
  width: 100%;
}
.news-img img {
  width: 100%;
  display: block;
  height: 230px;
}
.news-content {
  padding: 20px;
}
.news-content p:first-of-type {
  margin-top: 15px;
  color: #9b9b9b;
  font-weight: 600;
}
.news-content h4 {
  margin-top: 15px;
  font-size: 120%;
  font-weight: 500;
}
.news-content p:last-of-type {
  margin-top: 15px;
  color: #c6c6c6;
  font-size: 95%;
}

.news {
  padding: 80px 0;
  background-color: #242424;
}

.news h3 {
  text-transform: uppercase;
  font-family: "Oswald", sans-serif;
  font-weight: 600;
  font-size: 40px;
  letter-spacing: -1.4px;
  padding-bottom: 18px;
}
.news-preview-collection {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.news-preview:first-of-type {
  margin: 1.5% 1.5% 1.5% 0;
}
@media (max-width: 1200px) {
  .news {
    padding: 20px 100px 60px 100px;
  }
}
@media (max-width: 768px) {
  .news {
    padding: 60px;
  }
}
@media (max-width: 428px) {
  .news {
    padding: 30px;
  }
  .news-preview {
    width: 100%;
    width: 85%;
    margin: 0 auto 20px auto;
  }
  .news h3 {
    font-size: 24px;
  }
  .news-preview:first-of-type {
    margin: 0 auto 20px auto;
  }
}

.footer-preview{
    position: relative;
}
.title{
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 1.1px;
    margin-bottom: 15px;
}
.footer-list{
    list-style-type: none;
    padding-left: 0;
}
.footer-list li a{
    text-decoration: none;
    color: #fff;
    font-size: 13px;
}
.footer-list li a:hover{
    color: rgb(224, 224, 224);
}
.footer {
  padding: 60px 100px 0 100px;
  font-family: "Montserrat", sans-serif;
  background-color: #333;
}
.footer-content-collection {
  display: flex;
  justify-content: space-between;
}
.seperator {
  margin: 15px 0;
  border-bottom: 2px solid rgb(107, 107, 107);
}
.copy-right .icons {
  text-align: right;
}
.copy-right {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-end;
}
.copyright-section {
  font-size: 12px;
  display: flex;
  justify-content: space-between;
}
.copyright-right {
  font-size: 12px;
  text-align: right;
}
.icons {
  /* width: 170px; */
}
li.icons-info {
  margin-left: 18px;
}
.icons ul {
  list-style-type: none;
  display: flex;
  justify-content: space-between;
}
.icons ul li {
  font-size: 20px;
}
.icons ul li a {
  color: #fff;
}
.icons ul li a:hover {
  color: #9b9b9b;
}
.copy-left a {
  color: #fff;
}
.copyright-right p a {
  color: #fff;
}
.wem-main {
  font-size: 12px;
  font-weight: 600;
}
.footer-preview .logo {
  width: 80px;
  margin-left: 10px;
}
.footer-preview .logo img {
  width: 100%;
}
@media (max-width: 768px) {
  .footer {
    padding: 60px;
  }
  .footer-content-collection {
    flex-wrap: wrap;
  }
  .footer-preview {
    width: 30%;
  }
}
@media (max-width: 428px) {
  .footer {
    padding: 30px;
  }
  .footer-preview {
    width: 100%;
  }
}

.wow_club {
  padding: 60px 0;
  width: 100%;
}
.wow_points {
  display: flex;
  justify-content: space-around;
}
.wow_hello {
  background: #242424;
}
/* .voucher-info-container-lower {
  display: none;
} */
.wow_text_info h1 {
  font-size: 29.5px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.45;
  letter-spacing: -0.16px;
  text-align: left;
  color: #fff;
  margin-bottom: 0px;
}
.silver {
  border-left: 1px solid #707070;
  padding-left: 25px;
}
.silver {
  display: flex;
  align-items: center;
}
.point_img.point-silver P {
  color: #878787;
}
.point_text h1 {
  color: #fff;
  font-weight: bold;
}
.gold {
  display: flex;
  align-items: center;
}

.point_img p {
  color: #c5292a;
  font-size: 13px;

}
.silver {
  display: flex;
  align-items: center;
  padding: 4px 15px 10px 25px;
}
.point_text {
  margin-right: 15px;
}
.point_img {
  margin-top: 7px;
}
.wow_text_info p {
  color: #9b9b9b;
  margin-bottom: 0px;
}
.wow_hello {
  padding: 0px 15px;
}

.wow_points {
  border-left: 1px solid #707070;
  padding: 0px 15px;
}

.user_points_win {
  background: #242424;
}

.border-line {
  border-bottom: 1px solid #707070;
}

.user_points_win {
  display: flex;
}

.point_win h2 {
  color: #9b9b9b;
  font-weight: 500;
  margin-left: 12px;
}

.points_text p {
  color: #9b9b9b;
  font-size: 12px;
  margin-top: 6px;
  margin-left: 6px;
}
.user_points_win {
  padding: 20px 120px;
}
.img_voucher img {
  width: 33px;
  -webkit-filter: sepia(40%);
  position: relative;
  top: -4px;
}
.input.active img {
  -webkit-filter: sepia(0%);
}

.flex-parent {
  display: flex;
  background: #242424;

  width: 100%;
  height: 100%;
}

.input-flex-container {
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 100%;

  max-width: 1000px;
  position: relative;
  z-index: 0;
  padding-bottom: 28px;
  padding-top: 5px;
}

.input {
  position: relative;
}
.input:hover {
  cursor: pointer;
}
.input::before,
.input::after {
  content: "";
  display: block;
  position: absolute;
  z-index: -1;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  background-color: #b9cadb;
  width: 4vw;
  height: 5px;
  max-width: 50px;
}
.input::before {
  left: calc(-4vw + 12.5px);
}
.input::after {
  right: calc(-4vw + 12.5px);
}
.input.active {
}
.input.active::before {
  background-color: #b12728;
}
.input.active::after {
  background-color: #b12728;
}
.input.active span {
  font-weight: 700;
}
.input.active span::before {
  font-size: 13px;
}
.input.active span::after {
  font-size: 15px;
}
.input.active ~ .input,
.input.active ~ .input::before,
.input.active ~ .input::after {
}
.input span {
  width: 1px;
  height: 1px;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  visibility: hidden;
}
.input span::before,
.input span::after {
  visibility: visible;
  position: absolute;
  left: 50%;
}
.input span::after {
  content: attr(data-year);
  top: 25px;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  font-size: 14px;
}
.input span::before {
  content: attr(data-info);
  top: -65px;
  width: 70px;
  -webkit-transform: translateX(-5px) rotateZ(-45deg);
          transform: translateX(-5px) rotateZ(-45deg);
  font-size: 12px;
  text-indent: -10px;
}

.description-flex-container {
  width: 80vw;
  font-weight: 400;
  font-size: 22px;
  margin-top: 100px;
  max-width: 1000px;
}
.description-flex-container p {
  margin-top: 0;
  display: none;
}
.description-flex-container p.active {
  display: block;
}

@media (min-width: 1250px) {
  .input::before {
    position: relative;
    top: 15px;
    width: 28px;
    left: -32px;
    height: 4px;
  }
  .input-first::before {
    width: 0;
  }

  .input::after {
    right: -49.5px;
    top: 15px;
    height: 4px;
  }

  .input.input_no_active::after {
    right: -49.5px;
    top: 15px;
  }
}
@media (max-width: 850px) {
  .input {
    width: 17px;
    height: 17px;
  }
  .input::before,
  .input::after {
    height: 3px;
  }
  .input::before {
    left: calc(-4vw + 8.5px);
  }
  .input::after {
    right: calc(-4vw + 8.5px);
  }
}
@media only screen and (max-width: 320px) and (min-width: 600px) {



  .flex-parent {
    justify-content: initial;
  }

  .input-flex-container {
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;
    height: auto;
    margin-top: 15vh;
  }

  .input {
    width: 60px;
    height: 60px;
    margin: 0 10px 50px;
    background-color: #aeb6bf;
  }
  .input::before,
  .input::after {
    content: none;
  }
  .input span {
    width: 100%;
    height: 100%;
    display: block;
  }
  .input span::before {
    top: calc(100% + 5px);
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
    text-indent: 0;
    text-align: center;
  }
  .input span::after {
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    color: #ecf0f1;
  }

  .description-flex-container {
    margin-top: 30px;
    text-align: center;
  }
}
.voucher-progress {
  display: flex;
  align-items: center;
  position: relative;
  height: 70px;
}
.voucher-prog-bar {
  width: 50px;
  height: 4px !important;
}
.voucher-img {
  margin-left: 5px;
}
.voucher-img img {
  width: 40px;
  background-color: #242424;
  opacity: 0.5;
}
.voucher-progress.active .voucher-img img {
  opacity: 1;
  /* position: absolute;
  left: 3%;
  z-index: 99;
  top: 10px; */
}
.voucher-progress .user_img {
  position: absolute;
  top: -30px;
  display: none;
}
/* .voucher-progress.active:last-of-type .user_img {
  display: block !important;
} */
.voucher-progress.active:nth-last-of-type(2) .user_img {
  display: block;
}
.voucher-prog-bar {
  background-color: #505050 !important;
}
.progress-bar {
  background-color: #c5292a !important;
}
.flex-parent {
  /* padding: 20px; */
  overflow-x: auto;
  width: 98%;
  margin: auto;
  padding: 40px 0 0 0;
}
.wow_club .container {
  background-color: #242424;
  padding-bottom: 10px;
}
/* width */
.flex-parent::-webkit-scrollbar {
  width: 2px;
  height: 4px;
}

/* Track */
.flex-parent::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 2px;
}

/* Handle */
.flex-parent::-webkit-scrollbar-thumb {
  background: #fff;
  border-radius: 2px;
}

/* Handle on hover */
.flex-parent::-webkit-scrollbar-thumb:hover {
  background: #fff;
}
.quick-book-container {
  background-color: #fff;
  width: 300px;
  padding: 20px 30px;
  border-radius: 15px;
}
.quick-book-head h3 {
  color: #c5292a;
  font-weight: 600;
  margin-bottom: 20px;
  font-size: 24px;
}
.select-quick {
  width: 100%;
  color: #505050;
}
.select-quick::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
  position: absolute;
  top: 35%;
  right: 5%;
}
.select-quick select {
  width: 100%;
  background-color: #eaeaea;
  border: none;
  padding: 5px 50px 5px 5px;
  margin-bottom: 10px;
}
.select-quick:focus-visible {
  border: none !important;
  outline: none !important;
}
.select-quick select:focus-visible {
  border: none !important;
  outline: none !important;
}
.movie-change {
  color: #505050;
}
.book-now-btn {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-top: 10px;
}
.book-now-btn .btnc-primary {
  text-align: right;
  background-color: #c5292a;
  color: #fff;
  padding: 5px 15px;
  border: none;
  border-radius: 5px;
  font-size: 14px;
  font-weight: 600;
}
.btnc-quick {
  text-align: right;
  background-color: #c5292a;
  color: #fff;
  padding: 5px 15px;
  border: none;
  border-radius: 25px;
  font-size: 18px;
  font-weight: 600;
  transition: 0.3s background, 0.3s color;
}
.btnc-quick:hover {
  background-color: #fff;
  color: #c5292a;
}
.btnc-cross {
  background-color: #fff;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  font-size: 20px;
  color: #c5292a;
  border: none;
}
.quick-book-btn-home {
  position: fixed;
  right: 3%;
  bottom: 3%;
  z-index: 99;
}
.quick-book-container {
  position: fixed;
  right: 3%;
  bottom: 15%;
  z-index: 999;
}
.select-quick::after {
  top: 35% !important;
}
@media (max-width: 400px) {
  body {
    min-height: 950px;
  }
}

@media (max-width: 600px) {

  .silver {
   
    padding: 4px 6px 10px 25px;
}
  .point_text h1 {
  
    font-size: 22px;
}
.point_img p {
  
  font-size: 12px;
}
  .img_voucher img {
    width: 18px;
    top: -6px;
  }
  .wow_club {
    background: #242424;
  }
  .wow_points {
    border-left: 0px;
  }
  .user_points_win {
    padding: 20px;
  }
  .wow_club {
    width: 90%;
    margin: 15px auto;
  }
}

.offershome img{
    width: 100%;
}
.booking{
    /* padding: 100px; */
}
.booking-upper{
    width: 100%;
    /* height: 70vh; */
    padding:100px 100px 0px 100px;
    float: left;
    position: relative;
    z-index: 1;
}
.booking-lower{
    /* height: 30vh; */
    width: 100%;
    float: left;
    padding:30px 100px 30px 100px;
}
.current-week {
    width: 100%;
    background-color: #242424;
    float: left;
    height: 120px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: right;
    align-content: center;
    position: absolute;
    z-index: -1;
    left: 0;
    bottom: 40px;
}
.movie-info{
    width: 23%;
    float: left;
    padding: 20px;
    background-color: #242424;
    margin-top: 40px;
}
.movie-info h4{
    text-transform: uppercase;
    font-weight: 600;
    padding-bottom: 10px;
    border-bottom: 1px solid #484848;
    font-family: 'oswald';
}
.showtimes{
    width: 77%;
    float: left;
    padding: 0 100px;
}
.nav-tabs{
    border-bottom: 0 !important;
}
.booking-time{
    background-color: transparent !important;
    color: #fff !important;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 24px;
    font-family: 'oswald';
}
.nav-tabs .nav-link:focus, .nav-tabs .nav-link:hover{
    border-color: transparent transparent !important;
}
.nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active {
    border-color: transparent transparent #c5292aff !important;
}
.display-week{
    width: 100%;
    margin-left: 30%;
}
.btn-week{
    color: rgba(194, 194, 194, 0.904) !important;
    font-weight: 600 !important;
    margin-top: 2px;
}
.btn-week:focus, .btn-week:focus-visible{
    background-color: #c5292a !important;
    color: #fff !important;
    border: none !important; 
    box-shadow: none !important;
    border: none !important;
}
.filling-data{
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    width: 25%;
    padding: 10px;
}
.filling-data p{
    width: 50%;
    font-weight: 500;
}
.filling-data p:first-of-type{
    color: #2cb10a;
}
.filling-data p:nth-of-type(2){
    color: #ff972a;
}
.filling-data p:nth-of-type(3){
    color: #ff4242;
}
.filling-data p:nth-of-type(4){
    color: rgb(150, 149, 149);
}

.movie-info-detail {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  font-size: 90%;
  float: left;
}
.movie-info-left {
  width: 30%;
  font-weight: 700;
  padding-bottom: 8px;
}
.movie-info-right {
  width: 70%;
  padding-bottom: 8px;
}
.movie-about {
  padding-top: 10px;
  width: 80%;
}
.showtimes .nav {
  width: 100% !important;
}


/* @font-face {
  font-family: "Gotham-medium";
  font-family: "Gotham-book";
  src: url(./fonts/GothamMedium.ttf) format("ttf");
  src: url(./fonts/GothamBook.ttf) format("ttf");
} */
html {
  scroll-behavior: smooth;
}
body {
  font-family: "Gotham", sans-serif;
  box-sizing: border-box;
  background-image: url(/static/media/bg.2d6e2ca6.png);
  background-size: 100%;
  background-repeat: repeat;
  overflow-x: hidden;
  color: #fff !important;
}
h1,
h2,
h3,
h4 {
  font-family: "Oswald", sans-serif;
}
p,
a {
  font-family: "Gotham", sans-serif;
  letter-spacing: 1px;
}
/* width */
::-webkit-scrollbar {
  width: 2px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #c5292a;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #c5292a;
}

.modal-dialog {
  max-width: 300px !important;
  margin: 1.75rem auto !important;
}
.btn-primary.focus,
.btn-primary:focus {
  color: #fff;
  background-color: #0069d9;
  border-color: #0062cc;
  box-shadow: none !important;
}
.movie-preview h4 {
  font-family: "Gotham", sans-serif;
  font-weight: 600;
  font-size: 16px;
}
.hidden {
  display: none;
}
.srv-validation-message {
  font-size: 12px;
  margin-bottom: 5px;
}
.trailer-box {
  position: relative;
  padding: 0 20px;
}
.trailer-cross {
  position: absolute;
  width: 25px;
  height: 25px;
  top: 0px;
  right: 0px;
  background-color: #fff;
  font-size: 12px;
  border-radius: 50%;
  border: none;
  background-color: #ffffff;
  color: #242424;
  display: flex;
  justify-content: center;
  align-items: center;
}

.blur {
  -webkit-filter: grayscale(100%);
          filter: grayscale(100%);
}
.loc-modal {
  position: relative;
  z-index: 999;
}

.loc-modal .ReactModal__Content.ReactModal__Content--after-open {
  width: 70% !important;
  margin: auto !important;
  max-height: 500px !important;
  top: 50px !important;
}
.loc-modal iframe {
  width: 100% !important;
}
.loc-modal .ReactModal__Overlay.ReactModal__Overlay--after-open {
  background-color: #000000ab !important;
  inset: 0;
}
.login-modal .ReactModal__Overlay.ReactModal__Overlay--after-open {
  z-index: 999 !important;
  background-color: #000000ab !important;
}
.login-modal .ReactModal__Content.ReactModal__Content--after-open {
  width: 27% !important;
  background-color: transparent !important;
  padding: 0 !important;
  margin: 80px auto;
  position: relative !important;
  border: 0px !important;
  z-index: 999 !important;
}
.pointer-cursor {
  cursor: pointer;
}
.wow-img img {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 30%;
  padding-top: 60px;
}
.offers-wow-img {
  background-color: black;
}
.box.box-unavailable span {
  display: flex;
}
.flex-text {
  display: flex;
  justify-content: space-around;
  align-items: center;
}
@media (min-width: 1440px) {
  .container {
    max-width: 1300px !important;
  }
}
@media (min-width: 1366px) and (max-width: 1439px) {
  .container {
    max-width: 1200px !important;
  }
}
@media (min-width: 1280px) and (max-width: 1365px) {
  .container {
    max-width: 1170px !important;
  }
}
@media (min-width: 1200px) and (max-width: 1279px) {
  .container {
    max-width: 1150px !important;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .container {
    max-width: 970px !important;
  }
}
@media (max-width: 428px) {
  .loc-modal .ReactModal__Content.ReactModal__Content--after-open {
    width: 90% !important;
    top: 0px !important;
    inset: 0 !important;
  }
  .login-modal .ReactModal__Content.ReactModal__Content--after-open {
    width: 100% !important;
  }
  button.btn.btn-signup.hide-desktop {
    font-weight: 600;
    font-size: 14px;
  }
}
.react-calendar{
  background: #242424 !important;
}
.react-calendar__tile--now
{
  background: #a2a264 !important;
}
.react-calendar__tile:disabled {
  background-color: #f0f0f0;
  color: darkgray !important;
}

/* .dropdown .btn-success:hover {
 
  border: 1px solid #be2829 !important;
} */

.btn-success {
  
  background-color: transparent;
  border: transparent;
  padding: 12px;
  margin-left: 0px !important;
}
button#dropdown-basic {
  margin-right: 0px;
}
.select-en .navbar-nav .dropdown-menu{
  position: absolute;
}
.btn-success:hover{
  background-color: transparent;
  border: transparent;
}
.select-en .dropdown-item{
  color: black !important;
}

.navbar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 40px;
  background-color: transparent;
  font-size: 15px;
  letter-spacing: 1px;
  position: fixed;
  width: 100%;
  z-index: 999;
  font-weight: 600;
}
.profile-name {
  display: flex;
  align-items: center;
}
.profile-circle {
  width: 30px;
  height: 30px;
  border: 1px solid #fff;
  border-radius: 50%;
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
}
.logo-container {
  width: 150px;
}
.logo-container img {
  width: 75%;
}
.options {
  flex-grow: 1;
  display: flex;
  justify-content: end;
  align-items: center;
}
.option {
  margin-right: 15px;
  color: #fff;
  padding-top: 10px;
  font-weight: 500;
}
.option:hover {
  text-decoration: none;
  color: #eee;
}
.location-change {
  width: 150px !important;
  border-radius: 4px;
  margin: 0 10px;
  background-color: #333;
  color: #fff;
  border: none;
  padding: 5px;
  height: 35px;
  margin-top: 5px;
  text-align: center;
}
.select-genre {
  position: relative;
}
.select-genre::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
  position: absolute;
  top: 30%;
  right: 11%;
}
/* .location-change::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
} */
.club {
  width: 100px;
  border-radius: 4px;
  border: none;
  height: 35px;
  margin-top: 5px;
  font-weight: 700;
  color: #1b1b1b;
}
.navbar-nav .club {
  width: 120px;
  color: #fff;
}
.img-search {
  width: 30px;
  height: 30px;
}
.login-button {
  padding: 0 10px;
  justify-content: end;
  display: flex;
  /* width: 200px; */
  align-items: center;
}
.login-button img {
  width: 20px;
}
.btn-signup {
  width: auto;
  background-color: #c5292a;
  color: #fff;
  font-size: 12px;
}
.hide-desktop {
  display: none;
}
.select-location {
  display: flex;
  align-items: center;
  width: 118px;
  height: 32px;
  margin: 8px 20px 0 0;
  justify-content: center;
  border: 1px solid #fff;
}
.select-location a {
  color: #fff;
}
.select-location a:hover {
  text-decoration: none;
}
.profile-picture .profile {
  background-color: transparent !important;
  border: 1px solid transparent !important;
  margin-right: 0;
  display: flex;
  align-items: center;
}

.profile-picture .dropdown-menu.show {
  margin-top: 45px !important;
  background-color: #333;
  left: 12%;
  width: 200px;
}
.profile-picture .dropdown-item {
  display: block;
  width: 100%;
  padding: 0.25rem 1rem;
  clear: both;
  font-weight: 400;
  color: #fff;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
}
.dropdown-item:first-of-type .profile-name {
  border-bottom: 1px solid #c5292a;
  padding-bottom: 10px;
}
.dropdown-item:first-of-type .profile-name .profile-circle {
  width: 22px;
  height: 22px;
  font-size: 10px;
  background-color: #c5292a;
}
.profile-picture .dropdown-item {
  font-size: 14px;
}
.dark-nav {
  background-color: #0000003d;
}
.navbar-dark .navbar-nav .nav-link {
  color: #fff !important;
}
.drop-web {
  background-color: transparent;
  border-color: transparent;
}
.drop-web::after {
  display: none;
}
.drop-web:hover {
  background-color: transparent;
  border-color: transparent;
}
.drop-web:focus {
  background-color: transparent;
  border-color: transparent;
}
.drop-web:not(:disabled):not(.disabled).active,
.drop-web:not(:disabled):not(.disabled):active,
.show > .drop-web.dropdown-toggle {
  background-color: transparent;
  border-color: transparent;
}
.web-menu-drop {
  left: -90%;
  background-color: #333;
}
.web-menu-drop a {
  color: #fff;
  font-size: 14px;
}
.web-menu-drop .dropdown-item:active,
.web-menu-drop .dropdown-item.active {
  background-color: transparent;
}
@media (max-width: 768px) {
  .navbar {
    background-color: #030303a3;
  }
  .img-search {
    display: none;
  }
  .location-change,
  .club {
    margin: 10px 0 0 0;
  }
  .options {
    align-items: flex-start;
  }
}
@media (max-width: 428px) {
  .navbar {
    background-color: #000000db;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 999;
  }
  .logo-container img {
    width: 50%;
  }
  .hide-mobile {
    display: none;
  }
  .hide-desktop {
    display: unset;
  }
  .login-button {
    padding: 0;
    justify-content: flex-start;
    margin-top: 10px;
  }
  .profile-picture .dropdown-menu.show {
    margin-top: 0 !important;
    background-color: #333;
    left: 12%;
    width: 200px;
  }
  .profile-picture .btn {
    float: none;
    padding: 10px 0 !important;
  }
  .select-genre:after {
    top: 44%;
    right: 3%;
  }
  .select-quick:after {
    top: 35%;
    right: 5%;
  }
  .genre {
    width: 90px !important;
    font-size: 10px;
    padding: 5px;
    height: 35px;
    margin-top: 0 !important;
    text-align: left;
  }
}
@media (min-width: 375px) and (max-width: 413px) {
  .genre {
    width: 80px !important;
  }
  .profile-name-info {
    display: none !important;
  }
  .profile-picture .dropdown-menu.show {
    background-color: transparent;
  }
}

.login-container {
  background-color: #242424;
  width: 300px;
  padding: 20px;
}

.login-container .cross img {
  position: absolute;
  right: -60px;
  top: -20px;
}
.login-container h4 {
  color: #fff;
  font-size: 20px;
  text-transform: uppercase;
  font-family: "Oswald", sans-serif;
  letter-spacing: 1px;
  margin-bottom: 15px;
}
.login-container .input-box {
  background-color: #171614;
  padding: 10px;
  border-radius: 5px;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
}
.login-container .input-box input {
  background-color: #171614;
  border: black;
  color: #fff;
  padding-left: 10px;
  outline: none;
}
.forget {
  color: #c5292a;
  font-size: 12px;
  font-family: sans-serif;
  float: right;
  margin-bottom: 20px;
}
.login-container .input-btn {
  width: 100%;
  margin-bottom: 10px;
}
.login-container .btn {
  padding: 6px 15px;
  font-weight: 600;
  width: 100%;
  border-radius: 5px;
  height: auto;
  float: none;
}
.login-container .btn-default {
  background-color: #c5292a;
  border: 1px solid #ee682d;
  color: #fff;
  background-image: linear-gradient(to right, #ee682d, #c5342b);
}

.login-container .btn-ghost {
  background-color: transparent !important;
  border: 1px solid #c5292a !important;
  color: #c5292a;
  margin-top: 10px;
}
.login-container .btn-black {
  width: 48%;
  background-color: #181615;
  border: 1px solid #c5292a;
}
.btn-black {
  display: flex;
  align-items: center;
  justify-content: center;
}
.btn-black img {
  width: 24px;
}
.horizontal {
  width: 100%;
  text-align: center;
  margin: 15px 0;
}
.login-from {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.sign-up {
  margin: 20px 0;
  font-size: 14px;
  color: #fff;
  font-family: sans-serif;
  text-align: center;
}
.register {
  color: #c5292a;
}
.swal-button {
  background-color: #c5292a !important;
}
.otp-box input {
  width: 32px !important;
  height: 40px !important;
  margin-bottom: 20px;
}
.cross {
  position: absolute;
  right: 0px;
  cursor: pointer;
}

.booking {
  /* padding: 100px; */
}
.booking-upper {
  width: 100%;
  /* height: 70vh; */
  padding: 100px 100px 0px 100px;
  float: left;
  position: relative;
  z-index: 1;
  background-repeat: no-repeat;
  background-size: cover;
  padding-bottom: 15px;
}
.booking-mobile{
  display: none !important;
}
.booking-lower {
  /* height: 30vh; */
  width: 100%;
  float: left;
  padding: 30px 100px 30px 100px;
  background-color: #1b1b1b;
}
.current-week {
  width: 100%;
  background-color: #242424;
  float: left;
  height: 120px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: right;
  align-content: center;
  position: absolute;
  z-index: -1;
  left: 0;
  bottom: 40px;
}
.movie-info {
  width: 23%;
  float: left;
  padding: 20px;
  background-color: #242424;
  margin-top: 10px;
}
.payment-movie-info .movie-info h4 {
  font-weight: 500 !important;
}
.movie-info h4 {
  text-transform: uppercase;
  font-weight: 600;
  padding-bottom: 10px;
  border-bottom: 0 solid #484848;
  font-family: "oswald";
}
.showtimes {
  width: 77%;
  float: left;
  padding: 0 100px;
}
._info__ ul {
  list-style-type: none;
}
.nav-tabs {
  border-bottom: 0 !important;
}
.booking-time {
  background-color: transparent !important;
  color: #fff !important;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 24px;
  font-family: "oswald";
}
.nav-tabs .nav-link:focus,
.nav-tabs .nav-link:hover {
  border-color: transparent transparent !important;
}
.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  border-color: transparent transparent #c5292aff !important;
}
.type-time-slots {
  list-style-type: none;
  display: inline;
}
.type-time-slots li {
  /* padding: 5px 15px; */
  float: left;
  /* background-color: #333; */
  /* margin-right: 15px; */
  /* border-radius: 3px; */
  /* width: 105px; */
  margin-bottom: 15px;
  text-align: left;
}
.type-time-slots .timing-box {
  padding: 5px 15px;
  float: left;
  background-color: #333;
  margin-right: 15px;
  border-radius: 3px;
  width: 105px;
  margin-bottom: 15px;
  text-align: center;
}
.type-time-slots li a {
  text-decoration: none;
  font-family: "oswald";
}
.tab-content {
  padding: 30px 0;
  padding-bottom: 0px;
}
.booking-time {
  padding: 0 !important;
  margin-right: 10px;
}
.text-default {
  color: rgb(150, 149, 149);
}
.text-default:hover {
  color: rgb(150, 149, 149);
}

.display-week {
  width: 100%;
  padding-left: 30%;
  margin-left: 0;
  display: flex;
  flex-wrap: wrap;
}
.btn-week {
  color: rgba(194, 194, 194, 0.904) !important;
  font-weight: 600 !important;
  margin-top: 2px;
}
.btn-week:focus,
.btn-week:focus-visible {
  background-color: #c5292a !important;
  color: #fff !important;
  border: none !important;
  box-shadow: none !important;
  border: none !important;
}
.btn-week.active {
  background-color: #c5292a !important;
  color: #fff !important;
  border: none !important;
  box-shadow: none !important;
  border: none !important;
}
.filling-data {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  width: 65%;
  padding: 10px;
}
.filling-data p {
  /* width: 50%; */
  font-weight: 500;
}
.filling-details {
  width: 35%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;
  padding: 10px 20px;
}
.filling-details p {
  width: 50%;
  margin-bottom: 0;
}
.filling-details p:first-of-type {
  color: #2cb10a;
}
.filling-details p:nth-of-type(2) {
  color: #ff972a;
}
.filling-details p:nth-of-type(3) {
  color: #ff4242;
}
.filling-details p:nth-of-type(4) {
  color: rgb(150, 149, 149);
}
.btn-week:active {
  background: Yellow;
}
.lang-time {
  color: #c1c1c1;
  font-weight: 600;
}
.cin-type {
  font-weight: 600;
}
.seats-bar {
  height: 3px;
}
.seats-bar .progress-bar {
  background-color: #28a745 !important;
}
.cinema-title .title {
  font-size: 20px;
}
@media (max-width: 428px) {
  .booking-mobile{
    display: inline-block !important;
  }
  .movie-info h4 {
    font-weight: 500;
  }
  .genre {
    margin-top: 0 !important;
    text-align: left;
    padding-left: 5px;
  }
  .booking-upper {
    width: 100%;
    padding: 100px 30px 0 30px;
    background-image: none !important;
    display: flex;
    background-size: cover;
    flex-direction: column;
  }
  .movie-details {
    width: 100%;
    display: flex;
    flex-direction: column;
  }
  .booking-poster {
    width: 70%;
    margin: auto;
  }
  .booking-movie-details {
    padding: 30px 0 0;
    width: 100%;
  }
  .booking-movie-details h3 {
    font-size: 150%;
  }
  .booking-movie-details p {
    font-weight: 700;
    font-size: 80%;
  }
  .booking-movie-details .btn-normal {
    padding: 7px 10px !important;
    font-size: 14px;
  }
  .book-now {
    background-color: #c5292a;
    color: #fff;
  }
  .book-now a {
    transition: color 200ms ease-in;
  }
  .book-now:hover a {
    color: #c5292a !important;
  }
  .share {
    font-size: 20px !important;
  }
  .filling-data {
    width: 100%;
  }
  .display-week {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    margin: 10px 0;
  }
  .filling-details {
    width: 100%;
    padding: 0 10px;
  }

  .current-week {
    width: 100%;
    height: auto;
    margin-top: 10px;
  }
  .btn-week.active,
  .btn-week:focus,
  .btn-week:focus-visible {
    font-size: 14px !important;
    margin-right: 0.5% !important;
  }
  .booking-lower {
    padding: 20px 30px;
  }
  .movie-info {
    width: 100%;
    margin-top: 0;
  }
  .movie-info h4 {
    font-size: 120%;
  }
  .showtimes {
    width: 100%;
    padding: 10px 0;
  }
  .showtimes .nav {
    width: 100% !important;
  }
  .booking-time {
    font-size: 22px !important;
  }
  .showtimes .tab-content {
    padding: 20px 0;
  }
  .btn-week {
    margin-top: 2px;
    margin-right: 2px;
    padding: 5px 10px !important;
    font-size: 12px;
  }
  .current-week {
    position: unset;
  }
  #uncontrolled-tab-example-tabpane-synopsis h4 {
    font-size: 16px;
  }
  .filling-details {
    font-size: 12px;
  }
  .showtimes .nav-tabs {
    border-bottom: 0 !important;
    width: 100% !important;
  }
  .food-type .tab-content {
    width: 100%;
  }
  .type-time-slots li {
    margin-right: 10px;
  }
  .display-week {
    padding-left: 0%;
  }
}
.cinema-holder
{
  display: block; 
  float:left;
  clear:both;
}

a#uncontrolled-tab-example-tab-Movie-info {
  display: none;
}

.movie-details {
  width: 100%;
  /* display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content:space-between;
    align-items: center; */
}
.booking-poster {
  width: 23%;
  height: 400px;
  float: left;
}
.booking-movie-details {
  /* padding: 60px 80px 0 80px; */
  padding: 30px 80px 0 80px;
  width: 77%;
  float: left;
}
.booking-poster img {
  width: 100%;
  height: inherit;
  display: block;
}
.booking-movie-details h3 {
  font-size: 240%;
  text-transform: uppercase;
  font-weight: 700;
  font-family: "oswald";
}
.booking-movie-details p {
  font-weight: 700;
}
.booking-movie-details svg {
  line-height: 120%;
  margin-top: -3px;
  font-size: 20px;
  margin-right: 5px;
}
.share {
  font-size: 40px !important;
  line-height: 120% !important;
  margin-top: 5px !important;
}
.booking-mobile{
  display: none;
}

@media (max-width: 428px) {
  .movie-details.container.mobile-container {
    padding: 0px;
}

  .hidden-mobile{
    display: none;
  }

.bookin-lower-contain.container.booking-mobile {
  padding: 0px;
}
.booking-mobile{
  display: inline-block;
}

  .booking-upper {
    width: 100%;
    padding: 100px 30px 0 30px;
    background-image: none !important;
    display: flex;
    background-size: cover;
    flex-direction: column;
    padding-bottom: 15px;
  }
  .movie-details {
    width: 100%;
    display: flex;
    flex-direction: column;
    background-image: none !important;
  }
  .booking-poster {
    width: 100%;
    margin: auto;
  }
  .booking-movie-details {
    padding: 30px 0 0;
    width: 100%;
  }
  .booking-movie-details h3 {
    font-size: 150%;
  }
  .booking-movie-details p {
    font-weight: 700;
    font-size: 80%;
  }
  .booking-movie-details .btn-normal {
    padding: 7px 10px !important;
    font-size: 14px;
    margin-right: 0px;
    margin-bottom: 0px;
  }
  .share {
    font-size: 20px !important;
  }
  .filling-data {
    width: 100%;
  }
  .display-week {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    margin: 10px 0;
  }
  .filling-details {
    width: 100%;
    padding: 0 10px;
  }
  .current-week {
    width: 100%;
    height: auto;
    margin-top: 10px;
  }
  .btn-week.active,
  .btn-week:focus,
  .btn-week:focus-visible {
    font-size: 14px !important;
    margin-right: 0.5% !important;
  }
  .booking-lower {
    padding: 20px 30px;
  }
  .movie-info {
    width: 100%;
    margin-top: 0;
  }
  .movie-info h4 {
    font-size: 120%;
  }
  .showtimes {
    width: 100%;
    padding: 10px 0;
  }
  .booking-time {
    font-size: 18px !important;
  }
  .showtimes .tab-content {
    padding: 20px 0;
  }
  .btn-week {
    margin-top: 2px;
    margin-right: 2px;
    padding: 5px 10px !important;
  }
  .current-week {
    position: unset;
  }
  #uncontrolled-tab-example-tabpane-synopsis h4 {
    font-size: 16px;
  }
  a#uncontrolled-tab-example-tab-Movie-info {
    display: inline-block;
  }
}

@media (max-width: 395px){

  .booking-time {
    font-size: 14px !important;
}
}

* {
  padding: 0;
  margin: 0;
}
:root {
  --light_gray: #edf0f4;
}
.scree_way {
  width: 100%;
}
.scree_way img {
  width: 100%;
  display: block;
}
.scree_way p {
  text-align: center;
  text-transform: uppercase;
  margin-top: -40px;
}
._class_lable {
  padding: 5px 0;
}
.seats__area {
  font-size: 12px;
  color: #fff;
  line-height: 15px;
  font-weight: 600;
}
.cinemas_seats {
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  margin-bottom: 0;
  padding-bottom: 0;
  -webkit-transform-origin: top;
          transform-origin: top;
  overflow: auto;
  overflow: -moz-scrollbars-none;
  overflow-x: auto;
}
/* seat styles */
.seats-row {
  display: flex;
  justify-content: center;
  margin: 5px 0;
}
.seats-col {
  display: flex;
  align-items: center;
  justify-content: center;
}
.seat-row-no {
  font-size: 13px;
  color: #fff;
  margin-right: 10px;
  font-weight: 600;
}

.seat {
  width: 20px !important;
  height: 20px !important;
  /* border-radius: 6px; */
  display: block;
  /* border: 1px solid #2A81EA; */
  background: transparent;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAABHNCSVQICAgIfAhkiAAAAMpJREFUOE9jZICC8woKAl85OesZGRgCGRgY5WHi+On/D/8zMKzn/v690fDBgw8gtUD9DAzHNTQc/jEy7SfOEOyqmP7/c7S8ceMAI8hl3zi53jP8//+R8R9DgtWt6xtIMfiohkYC0F0TGBgZ+bm+fxNkBApMZGBkymP8+z+QVMNgFoMNZWSaz/D/3yTGo5qaD0BhZn39Gtj75AC4Lxn+PwQaqAUMVwYGSgwE6YeZM2ogOVEC0TMahqPpkIzUQ/tkQ4ajsGqBFw7UMhAAEmOKKqRMHCgAAAAASUVORK5CYII=);
  background-size: 100%;
  background-position: center;
  margin: 0 4px 4px 4px;
  cursor: pointer;
  position: relative;
  transition: all 0.2s ease-in-out;
  text-align: center;
}
.seat._hide {
  background: transparent;
  pointer-events: none;
  visibility: hidden !important;
}
.seat:hover {
  -webkit-transform: translateY(-5px);
          transform: translateY(-5px);
  transition: all 0.2s ease-in-out;
}
.seat:hover:before {
  content: "";
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  background: transparent;
  border-radius: 0;
  left: 0;
}
.seat.selected:before,
.seat:focus:before {
  content: "";
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAABHNCSVQICAgIfAhkiAAAAK9JREFUOE9jZICC8woKAl85OesZGRgCGRgY5WHi+On/D/8zMKzn/v690fDBgw8gtUD9DAzHNTQc/jEy7SfOEOyqmP7/c7S8ceMAI8hl3zi53v////8nIwMjO8QKUsB/hv//GX4yMjKyc33/Jsh4VENjIgMjUx4D0O2kGwazGKr5/79JjEc1NR8QH2bYXc4IdOJ/RpDX/j8EGqgFMp5qYNRAyoNyNAxHw5CMEBiByQYAftlOKttbi/8AAAAASUVORK5CYII=);
  background-size: 100%;
  background-position: center;
  border-radius: 3px;
  left: 0;
}

.seat.disable {
  cursor: not-allowed;
  pointer-events: none;
}
.seat.disable:before {
  content: "";
  position: absolute;
  top: 0px;
  width: 100%;
  height: 100%;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAABHNCSVQICAgIfAhkiAAAAIhJREFUOE/tksENwzAIRflZoBklG6TdAryMk2UitqizQUbxBCVYSm+VWiW+1VyQEDzBE6AjmPkOoghg8FL/rn/J2cw2I5pVNZVeZxAF5omA+CPkc5vZvKhOKJt1wPMS7Bh+mT0gIsnXHGsA/fQVwpzd260G0BkZQcTB9aIBr7tsDpvDEwb+8G121fs8yF/nAbkAAAAASUVORK5CYII=);
  background-size: 100%;
  background-position: center;
  left: 0;
}
.covid-seat {
  /* width: 20px !important;
  height: 20px !important; */
  /* border-radius: 6px; */
  /* display: block; */
  /* border: 1px solid #2A81EA; */
  background: transparent;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAABHNCSVQICAgIfAhkiAAAAIhJREFUOE/tksENwzAIRflZoBklG6TdAryMk2UitqizQUbxBCVYSm+VWiW+1VyQEDzBE6AjmPkOoghg8FL/rn/J2cw2I5pVNZVeZxAF5omA+CPkc5vZvKhOKJt1wPMS7Bh+mT0gIsnXHGsA/fQVwpzd260G0BkZQcTB9aIBr7tsDpvDEwb+8G121fs8yF/nAbkAAAAASUVORK5CYII=);
  background-size: 100%;
  background-position: center;
  margin: 0 4px 4px 4px;
  cursor: pointer;
  position: relative;
  transition: all 0.2s ease-in-out;
  text-align: center;
}
.text-center {
  text-align: center;
}
.text-right {
  text-align: right;
}
.text-left {
  text-align: left;
}
._box_title {
  display: inline-block;
  width: calc(100% / 2 - 4px);
}

/* Avalable | Not Available | Selected */
.cinema-labels {
  width: 100%;
  overflow-x: auto;
  overflow-y: hidden;
  white-space: nowrap;
  justify-content: center;
  margin-top: 10px;
  padding: 5px 0 20px;
}
.cinema-labels .box {
  padding: 7.5px 14px 0 0;
  display: inline-block;
  color: #fff;
  font-size: 12px;

  text-transform: uppercase;
}
/* .cinema-labels .box-available:before {
  background: transparent;
  border: 1px solid #2A81EA;
  color: #534D4D;
}

.cinema-labels .box-selected:before {
  background: #2A81EA;
  color: #534D4D;
}
.cinema-labels .box-unavailable:before {
  background: #C6C9D0;
  color: #534D4D;
} */
.cinema-labels .box:before {
  content: " ";
  padding: 5px 11.5px;
  max-height: 20px;
  border-radius: 6px;
  /* background: red; */
  /* margin-right: 25px; */
}
._bdr_top {
  border-top: 1px solid #c6c9d0;
}
/* right movies summary */
.sticky-right {
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease-in-out;
  background-color: #242424;
  border-radius: 3px;
  position: relative;
  height: auto;
  max-width: 320px;
}

/* .booking_summary:after, .booking_summary:before{
  position: absolute;
  content: '';
  width: 38px;
  height: 38px;
  background-color: #FAFBFF;
  border-radius: 20px;
} */
._border_top {
  border-top: 1px solid #c6c9d0;
}
._border__ {
  border-top: 1px solid #484848;
  border-bottom: 1px solid #484848;
}

._item_list:first-child {
  margin-top: 20px;
}
._item_list {
  margin-bottom: 20px;
}
._name__ {
  margin: 0;
  color: #9f9797;
  font-size: 11px;
  font-weight: normal;
  text-transform: uppercase;
}
.movie_name__ {
  color: #fff;
  font-size: 14px;
  line-height: 24px;
  font-weight: normal;
  font-weight: 500;
}
._35px {
  padding: 0 35px;
}
._summary_txt {
  padding: 35px 0 0;
  font-family: "oswald";
}
._summary_txt,
._seat_title {
  text-transform: uppercase;
  color: #fff;
  font-size: 22px;
  line-height: 30px;
  font-weight: 500;
}
._float_right {
  float: right;
}
.ticket_prints {
  margin-top: 20px;
}
.ticket_pricing {
  font-size: 15px;
  color: #3f495f;
  line-height: 25px;
}
.ticket_Total_amount,
.ticket_Total_amount i,
.ticket_Total_amount .fa {
  font-size: 42px;
  font-weight: 600;
  color: #00184d !important;
}
.ticket_Total_amount i,
.ticket_Total_amount .fa {
  font-size: 30px !important;
}
.prices_tags {
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease-in-out;
  /* position: absolute; */
  background-color: #fff;
  right: 0;
  bottom: 0;
  padding: 15px 20px;
  width: 100%;
  text-align: right;
  border-radius: 0 0 10px 10px;
  -moz-border-radius: 0 0 10px 10px;
  -webkit-border-radius: 0 0 10px 10px;
  -ms-border-radius: 0 0 10px 10px;
}
/* form styles */
._10px {
  margin-top: 10px;
}
._select__ select {
  height: 38px !important;
  padding: 10px 40px !important;
}
._select__ ._set_abs {
  top: 10px !important;
}
._custome_form input,
.select-cutom select {
  border: 1px solid #bdbdbd;
  height: 40px;
  padding: 0px 30px;
  border: 1px solid #c6c9d0;
}
._custome_form input::-webkit-input-placeholder {
  font-size: 16px;
}
._custome_form input::placeholder,
.select-cutom select {
  font-size: 16px;
}
.select-cutom select {
  background: transparent;
}
._icon_set {
  position: relative;
}
._set_abs {
  position: absolute;
  top: 12px;
  left: 12px;
}
.booking-lower {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  background-color: #171614;
}
.summary-display {
  width: 25%;
  padding-right: 25px;
}
.seats-display {
  width: 75%;
}

select {
  border-radius: 0;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  -o-border-radius: 0;
  -ms-border-radius: 0;
  -webkit-appearance: none;
  background-color: transparent;
  color: #7b7b7b;
}
.select-cutom:after {
  position: absolute;
  content: "";
  border: solid #7b7b7b;
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 3px;
  right: 20px;
  top: 40%;
  transform: translateY(-40%);
  -webkit-transform: translateY(-40%);
  -moz-transform: translateY(-40%);
  z-index: -1;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
}
.format_movies {
  display: inline-block;
}
._bg_gray {
  background-color: #edf0f4;
  background-color: var(--light_gray);
}
.format_movies .select-cutom:after {
  z-index: 1;
}
.format_movies select {
  border: none;
  box-shadow: none;
  font-size: 13px;
  font-weight: 600;
}
.format_movies .select-cutom {
  border-radius: 5px;
}
.format_movies .select-cutom:first-child {
  margin-left: 10px;
}
._seat_title {
  margin: 0;
}
.seat__title {
  padding: 0 0 5px;
}
.seats_section {
  padding: 0px 0 100px;
}
/* checkout ticket */
._print_tickets {
  min-height: 100px;
}
.seats_checkout_ticket {
  display: flex;
  list-style: none;
  padding-left: 0;
  margin: 0.6em 0;
}
.seats_checkout_ticket ul {
  list-style-type: none;
}
.seats_checkout_ticket li {
  background: #2a81ea;
  color: #ffffff;
  text-transform: uppercase;
  font-size: 20px;
  font-weight: 500;
  line-height: 35px;
  border-radius: 6px;
  min-width: 35px;
  min-height: 35px;
  text-align: center;
  margin-right: 0.3em;
}
/* bottom back and next button */
.bottom_sticky {
  width: 69% !important;
  padding: 15px 0;
  position: fixed;
  bottom: 0;
  right: auto;
  left: 5.55555%;
}
/* button */
.btn_blue {
  font-size: 13px;
  color: #fff;
}
.btn_text {
  font-size: 15px;
}
.btn_blue:hover,
.btn_blue:focus,
.btn_outline {
  color: #2a81ea;
  border: 2px solid #2a81ea;
  background: transparent;
}
.btn_outline:hover {
  background-color: #2a81ea;
  color: #fff;
}
.btn_blue {
  background-color: #2a81ea;
}
.btn_blue,
.btn_outline {
  padding: 10px 25px;
  border-radius: 50px;
  font-weight: 500;
  border: 2px solid #2a81ea;
  text-transform: uppercase;
}

.btn_transparent {
  background: transparent;
  font-size: 15px;
  text-transform: uppercase;
  font-weight: 600;
  color: #9f9797;
  position: relative;
  top: 6px;
}
.total-amount {
  display: flex;
  justify-content: space-between;
  padding-top: 15px;
  padding-bottom: 15px;
  line-height: 120%;
}

.booking-button {
  display: flex;
  justify-content: space-between;
  padding-bottom: 20px;
}

.btn-back,
.btn-next {
  margin-right: 0;
  padding: 5px 15px !important;
  color: #fff !important;
  width: 100%;
}
._btn {
  border-radius: 5px !important;
  width: 47%;
  border-color: transparent;
}
.btn-next {
  background-color: #c5292a;
  transition: background-color 0.3s;
}
.btn-next:hover {
  background-color: #db2626;
}
.btn-back {
  background-color: #242424;
  border: 1px solid white;
}
._btn a {
  color: white;
}
._btn a:hover {
  text-decoration: none;
}
.square-seat {
  background-color: #c5292a;
  display: inline-block;
  width: 29px;
  display: flex;
  height: 29px;
  margin: 2%;
  font-size: 12px;
  font-weight: 700;
  border-radius: 3px;
  align-items: center;
  justify-content: center;
}
.food-section .square-seat {
  width: 35px;
  height: 35px;
}
._summary_details {
  /* height: 350px !important; */
  height: auto;
}
.seats-display {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
}
.box img {
  margin-right: 10px;
  line-height: 120%;
  vertical-align: middle;
  margin-top: -2px;
}
.cl-left {
  width: 40%;
  color: #fff;
  margin-left: -10px;
}
.cl-right {
  width: 60%;
  color: #fff;
  padding-left: 4%;
}
.seats-info {
  width: 75%;
}
.seat-selected {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}
.scree_watch p {
  text-align: center;
  margin-top: -50px;
  text-transform: capitalize;
}
.bookin-lower-contain.container {
  display: flex;
  flex-wrap: wrap;
}

@media (max-width: 767px) {
  .bottom_sticky {
    width: 90%;
  }
  .summary-sticky {
    position: relative !important;
    right: 15px;
  }
  .seats_section {
    padding: 0px 0 50px;
  }
  .seat:hover {
    -webkit-transform: translateY(0px);
            transform: translateY(0px);
  }
}
@media (max-width: 428px) {
  .booking_summary._35px,
  .ticket_prints._35px {
    padding: 0;
  }
  .summary-sticky .booking_summary._35px {
    padding: 0 35px;
  }
  .summary-display {
    width: 100%;
    padding-right: 0;
  }
  ._name__ {
    font-size: 14px;
  }
  .summary-sticky .movie_name__ {
    font-weight: 500;
  }
  .summary-sticky {
    position: relative !important;
    right: 0;
    max-width: 100%;
  }
  .seats-display {
    width: 100%;
  }
  .cl-left {
    width: 100%;
    margin-left: 0;
  }
  .cl-right {
    width: 100%;
    padding-left: 0;
  }
  .cinema-labels .box:before {
    margin-right: 0;
  }
  .seats-info {
    width: 100%;
  }
  .scree_way p {
    margin-top: -15px;
  }

  .seat {
    width: 12px !important;
    height: 12px !important;
    margin: 0 2px 1px;
  }
  .covid-seat{
    background-image: none;
  }

  .seats-col.covid-seat img {
    width: 12px;
height:12px;
margin-top: 3px;
}
  .cinema-labels .box:before {
    padding: 0;
    margin-right: 0;
  }
  .seats-display {
    padding-left: 20px;
  }
  .summary-sticky {
    width: 100%;
    margin: 15px auto;
  }
  .booking-lower {
    flex-direction: column-reverse;
  }
  .scree_watch.y {
    width: 100%;
  }
  .scree_watch.y img {
    width: 100%;
  }
  .scree_watch.y p {
    margin-top: -20px;
  }
  /* ------------mobile-seats--------------- */
  .seats-info {
    width: 100%;
  }
  .seats-scroll {
    width: 100%;
    overflow-x: auto;
  }
  .seats-scroll::-webkit-scrollbar {
    width: 2px;
    height: 4px;
  }

  .seats-scroll::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey;
    border-radius: 10px;
  }

  .seats-scroll::-webkit-scrollbar-thumb {
    background: #c5292a;
    border-radius: 10px;
  }

  .seats-scroll::-webkit-scrollbar-thumb:hover {
    background: #c5292a;
  }

  .seats-preview {
    width: 170%;
  }
  .square-seat {
    width: 35px;
    height: 35px;
  }
  .food-section .square-seat {
    width: 37px;
    height: 37px;
  }
  /* ------------------------------------------- */
}
/* end button  */

.booking-container {
  padding: 80px 0;
  display: flex;
  justify-content: space-between;
}
.booking-success-container {
  width: 100%;
  margin-top: 40px;
}
.wow_successfully {
  text-align: center;
  background-color: #242424;
  padding: 50px 0px;
  width: 100%;
}
.successfully_img img {
  width: 100px;
}
.successfully_text h2 {
  /* font-family: Gotham; */
  font-size: 34px;
  color: #fff;
  font-weight: 600;
  line-height: 1.45;
  letter-spacing: -0.16px;
  margin: 15px 0px;
}
.successfully_text p {
  /* font-family: Gotham; */
  font-size: 20px;
  color: #fff;
  line-height: 1.56;
  letter-spacing: -0.18px;
  font-weight: 600;
  margin: 10px 0px;
}
button.wow_btn {
  /* font-family: Gotham; */
  font-size: 18px;
  font-weight: 600;
  color: #fff;
  margin-right: 32px;
}
.wow_download {
  background-color: transparent;
  border: 1px solid;
  padding: 10px 22px;
}
button.wow_btn img {
  vertical-align: text-top;
  margin-right: 6px;
}
button.wow_btn.wow_share {
  padding: 11px 27px;
  background: #c5292a;
  border: 1px solid #c5292a;
}
.wow_successfully_btn {
  padding: 30px 0px 0px 0px;
}

@media (max-width: 428px) {
  button.wow_btn {
    font-size: 14px;
  }
}

.loyalty-section {
  padding-top: 110px;
}
.loyalty-container {
  width: 100%;
  margin: auto;
  background-color: #242424;
  padding: 20px;
}
.loyalty-tabs {
  width: 100%;
  margin: auto;
  /* background-color: #242424; */
}
.voucher-info-container {
  /* padding: 20px; */
}
.loyalty-tabs .tab-content {
  padding: 0;
}

.price-ticket.price-width p {
    width: 100%;
}

.loyalty-edit-upper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.profile-edit-box {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.loyalty-edit-upper a {
  color: #fff;
  padding-left: 20px;
  text-decoration: none;
}
.wow_club .container {
  border-radius: 15px;
}
.price-ticket p {
  color: #181818;
  color: #F0F0F0;
  /* color: red; */
  width: 61%;
  padding-left: 15px;
}
.profile-edit-box .profile-circle {
  background-color: #c5292a;
  font-size: 20px;
  width: 40px;
  height: 40px;
}
.profile-edit-box .profile-name {
  display: flex;
  align-items: center;
  font-size: 24px;
}
.loyalty-edit-lower {
  margin-top: 20px;
  display: flex;
  width: 68%;
  justify-content: space-between;
}
.loyalty-tabs .nav-tabs {
  background-color: #181818;
}
.loyalty-tabs .nav-tabs .nav-link.active {
  border-color: transparent transparent #c5292aff !important;
  color: #c5292a;
}
.loyalty-tabs .nav-tabs .nav-link {
  background-color: transparent;
  color: rgb(104, 104, 104);
  font-weight: 600;
  width: auto;
}
.voucher-info-container-upper {
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;
  /* padding: 20px 20px 0 20px; */
}
.voucher-info-left {
  width: 70%;
}

.wow_club.wow-silver {
  padding-bottom: 14px;
}
.voucher-points {
  font-size: 34px;
  font-weight: 500;
  margin-bottom: 10px;
}
.prog-bar {
  width: 50%;
  height: 10px;
}

.prog-bar .progress-bar {
  background-color: #c5292a;
}
.voucher-info-right p {
  margin-bottom: 3px;
}
.voucher-info-right p:first-of-type {
  color: #c5292a;
  font-weight: 500;
  font-size: 18px;
}
.voucher-info-right p:nth-of-type(2) {
  font-weight: 500;
}
.voucher-info-right p:last-of-type {
  font-size: 14px;
  color: rgb(146, 146, 146);
}
.voucher-info-container-lower {
  background-color: #fff;
  padding: 0 10px 10px 10px;
  margin-bottom: 40px;
}
.voucher-info-container-lower .nav-tabs {
  background-color: #fff;
}
.voucher-select-container {
  display: flex;
  flex-wrap: wrap;
  padding: 20px 0;
}
.voucher-tags {
  width: 31%;
  margin: 1%;
  height: 190px;
}
.voucher-tag-img img {
  width: 100%;
  height: 190px;
  border-radius: 7px;
}
.voucher-tag-html {
  position: relative;
  height: 190px;
}
.voucher-tag-img {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 5;
  width: 100%;
}
.voucher-till {
  font-size: 12px;
  position: absolute;
  top: 9%;
  right: 5px;
  z-index: 6;
  margin-bottom: 0;
  color: #fff;
}
.voucher-valid {
  font-size: 12px;
  position: absolute;
  top: 16%;
  right: 5px;
  z-index: 6;
  margin-bottom: 0;
  color: #fff;
}

.voucher-type {
  font-size: 16px;
  top: 33.5%;
  font-weight: 600;
  right: 9%;
  margin-bottom: 0;
  z-index: 6;
  color: #fff;
  position: absolute;
}
.voucher-code {
  text-transform: uppercase;
  font-size: 10px;
  color: #c5292a;
  position: absolute;
  top: 53%;
  right: 2%;
  z-index: 6;
  font-weight: 600;
}

.voucher-off {
  position: absolute;
  top: 44%;
  right: 36%;
  z-index: 6;
  font-weight: 600;
  color: #fff;
}

.voucher-off p:first-of-type {
  font-weight: 600;
  margin-bottom: 0;
}
.voucher-off p:last-of-type {
  font-size: 10px;
  margin: -5px 0 0 0;
}
#progressbar1 {
  position: absolute;
  left: 15px;
  overflow: hidden;
  color: #2dbcb6;
  margin-bottom: 0;
}

/* #progressbar1 li:last-of-type:after{
  border-left: none;
} */

.points-ticket img {
  z-index: 9999;
}


#progressbar1 li {
  list-style-type: none;
  font-size: 8px;
  font-weight: 400;
  display: flex;
    align-items: center;
  
  counter-increment: inst;
  position: relative;
}


.points-ticket {
  
  display: flex;
  align-items: center;

  /* justify-content: space-between; */
  width: 100%;
}

.points-ticket h4 {
  margin-bottom: 0px;
  margin: 0px 30px;
  font-size: 14px;
}

.points-ticket p{
  margin-bottom: 0px;
  font-size: 12px;
  color: #9a8787;
}
.points-ticket{
  margin: 20px 0px;
  background-color: #242424;
  padding: 20px 20px;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
}
.progressbar{
  width: 100%;
}


#progressbar1 li:nth-child(3) {
  margin-bottom: 0;
}

#progressbar1 .step0:before {
  font-family: "proxima";
  content: counter(inst);
  color: #5c5d5f;
  font-size: 12px;
  font-weight: 700;
}

#progressbar1 li:before {
  width: 30px !important;
  height: 30px !important;
  line-height: 30px;
  display: block;
  font-size: 20px;
  background: #ebebeb;
  border: 2px solid #ebebeb;
  border-radius: 50%;
  margin: auto;
  position: relative;
  z-index: 5;
}
.track-margin {
  height: 66px;
}
.track-margin:last-of-type {
  height: auto;
  margin-bottom: 20px;
}
.track-margin p {
  margin-bottom: 0px;
  font-size: 14px;
  font-weight: 700;
}
#progressbar1 li:last-child:before {
  width: 40px;
  height: 40px;
}

.points-ticket:last-of-type{
  overflow:hidden
}

#progressbar1 .points-ticket li:after {
  content: "";
  width: 6px;
  height: 66px;
  
  position: absolute;
  left: 18px;
  border-left: 1px dashed #ffff;
  top: 15px;
  
}
.points-ticket li:last-of-type:after {
  border-left: none;
  content: "";
}
.d-none{
  display: none;
}


.input-box img {
  width: 16px;
  margin-right: 10px;
}
i.fa.fa-male {
  margin-right: 15px;
}

.update-paln h5 {
  text-align: center;
  font-size: 13px;
  background-color: #fff;
  display: inline-block;
  padding: 6px;
  border-radius: 3px;
  color: #242424;
  font-weight: 500;
}

.update-paln {
  display: flex;
  align-items: center;
  justify-content: center;
  /* position: absolute; */
  position: relative;
  top: -18px;
}
.profile-edit-container {
  padding-bottom: 15px;
}

.update-paln h5 span {
  color: #C5292A;
  border-bottom: 1px solid;
  cursor: pointer;

}


.point_text.amout-text p span {
  font-size: 38px;
  font-weight: bold;
  color: #878787;
}

.point_text.amout-text p {
  font-size: 22px;
  font-weight: bold;
  font-family: "Oswald", sans-serif;
}
.wow_hello.wow-silver .silver{
  border-left: transparent;
}

.wow_club.wow-silver {
  padding-top: 0px;
}
.wow_club.wow-loyalty {
  padding-bottom: 0px;
  padding-top: 0px;
}

.wow_hello.wow-silver .silver {
  display: flex;
  align-items: center;
  padding: 5px 14px 10px 17px;
}


#progressbar1 li:last-child:after {
  top: 147px;
  height: 132px;
}

/* #progressbar1 li:nth-child(3):after {
  top: 81px;
} */

#progressbar1 li:nth-child(2):after {
  top: 0px;
}

#progressbar1 li:first-child:after {
  position: absolute;
  top: 0px;
  
}





#progressbar1 li.active:after {
  background-color: #c5292a !important;
  z-index: 3;
}

#progressbar1 li.active:before {
  background-color: #c5292a !important;
  content: "\2713";
  font-weight: 700;
  color: #fff;
  border: 2px solid #c5292a;
}
.list-number {
  background-color: #c5292a;
  color: #fff;
  border-radius: 50%;
  font-size: 10px;
  width: 20px;
  height: 20px;
  position: absolute;
  top: 15.5%;
  left: 17.5%;
  z-index: 100;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;
}
.tick {
  width: 100px !important;
  height: 100px !important;
}

.prev {
  display: block !important;
  position: absolute !important;
  left: 40px !important;
  top: 20px !important;
  cursor: pointer !important;
}
.order-track-container .col-md-6 {
  position: relative;
}
.col-track {
  padding: 0 !important;
}
.card1 {
  margin-left: 60px;
  z-index: 0;
  /* border-right: 1px solid #f5f5f5; */
  /* position: relative; */
}
.prev:hover {
  color: #d50000 !important;
}
.theme-dark-info {
  color: #000;
}
.theme-info {
  color: rgb(68, 68, 68);
}
.trans-history-container {
  position: relative;
  padding: 30px 0;
}
.preferences-container {
  padding: 20px;
}
.pref-box {
  display: flex;
  align-items: flex-start;
  margin-bottom: 20px;
}
.pref-ques {
  padding: 20px;
  width: 20%;
}
.pref-categories {
  width: 80%;
  padding: 20px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.pref-cat-box {
  background-color: #fff;
  color: #000;
  padding: 5px 10px;
  margin: 0 15px 10px 0;
  border-radius: 15px;
  cursor: pointer;
}
.pref-cat-box.active {
  background-color: #c5292a;
  color: #fff;
}
.booking-history {
  padding: 20px;
}
.input-btn input {
  caret-color: transparent;
}
.booking-history-container {
  background-color: #fff;
  color: #000;
}
.loyalty-tabs .mb-3 {
  /* margin-bottom: 0px !important; */
}
.booking-history {
  display: flex;
  /* justify-content: space-between; */
}
.booking-history p {
  font-size: 16px;
  font-weight: 600;
}
.booking-history-date {
  width: 10%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.wow-gray-info {
  color: #5c5d5f;
  font-size: 14px;
}
.booking-history-date p {
  margin-bottom: 0;
  font-weight: 700;
}
.booking-history-poster {
  width: 15%;
}
.booking-history-poster img {
  width: 100%;
  height: 150px;
  object-fit: cover;
  border-radius: 15px;
}
.booking-history-info {
  width: 55%;
  padding-left: 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.booking-history-info p {
  margin-bottom: 0;
}
.booking-history-info .price-wow {
  font-size: 24px;
}
.booking-history-info h4 {
  text-transform: uppercase;
}
.booking-history-info h4 .wow-gray-info {
  text-transform: capitalize;
}
.booking-cancel {
  width: 20%;
}
.booking-cancel img {
  width: 80%;
}
.btn-food {
  background-color: #c5292a;
  color: #fff;
  padding: 5px 10px !important;
  font-size: 14px;
  margin-right: 50px;
}
.btn-food:hover {
  color: #fff;
}
.btn-again {
  background-color: #fff;
  color: #c5292a;
  border: 1px solid #c5292a;
}
.btn-again:hover {
  color: #c5292a;
}
.edit-modal .login-form {
  padding: 10px;
  background-color: #181818;
}
.edit-modal .input-box {
  border-bottom: 1px solid #5c5d5f;
}
.edit-modal .input-box input {
  width: 90%;
}
.edit-modal .input-btn {
  margin-top: 10px;
  display: flex;
  justify-content: center;
}
.sign-out {
  cursor: pointer;
}
.booking-history-info-left h4 {
  color: #000;
}
.booking-history-info .price-wow {
  font-size: 20px;
}
/* -------------new voucher-code---------------- */
.voucher-type {
  font-size: 10px;
  top: 36.5%;
  font-weight: 600;
  right: 2%;
  margin-bottom: 0;
  z-index: 6;
  color: #fff;
  position: absolute;
}
.voucher-code {
  text-transform: uppercase;
  font-size: 8px;
  color: #c5292a;
  position: absolute;
  top: 53%;
  right: 1%;
  z-index: 6;
  font-weight: 600;
}
.voucher-off {
  position: absolute;
  top: 44%;
  right: 35%;
  z-index: 6;
  font-weight: 600;
  color: #fff;
  text-align: center;
  font-size: 14px;
}
.offer-preview-display .voucher-type {
  font-size: 12px;
  top: 36.5%;
  font-weight: 600;
  right: 2%;
  margin-bottom: 0;
  z-index: 6;
  color: #fff;
  position: absolute;
}
.offer-preview-display .voucher-code {
  text-transform: uppercase;
  font-size: 8px;
  color: #c5292a;
  position: absolute;
  top: 53%;
  right: 2%;
  z-index: 6;
  font-weight: 600;
}
.login-modal .modal-content {
  background-color: #000;
}
.rewards-table td,
.rewards-table th {
  color: #000;
  border: 1px solid #000;
  padding: 10px;
}
.rewards-table {
  width: 100%;
  margin-left: 20px;

  padding-top: 30px;
}
.rewards-table h3 {
  color: #5c5d5f;
}
.rewards-table table {
  width: 50%;
  border-collapse: collapse;
  border: 1px solid #000;
}

.profile-no {
  display: flex;
  align-items: center;
  justify-content: center;

}
.profile-no p {
  margin-bottom: 0px;
}

.profile-email {
  display: flex;
  align-items: center;
  width:50%;

}
.loyalty-edit-lower {
  margin-left: 50px;
}

.profile-no img {
  margin-right: 15px;
}
.profile-email img {
  margin-right: 15px;
}

.profile-email p {
  margin-bottom: 0px;
  
}

/* -------------new voucher-code---------------- */

@media (max-width: 428px) {
  .loyalty-tabs{
margin: 15px 0px;
  }
  #progressbar1{
    left: 0px;
  }
  .loyalty-edit-lower{
    margin-left: 0px;
  }
  .loyalty-section {
    padding-top: 100px;
  }
  .profile-edit-box .profile-name {
    font-size: 12px;
  }
  .profile-edit-box .profile-circle {
    font-size: 14px;
    width: 30px;
    height: 30px;
  }
  .loyalty-edit-lower {
    width: 100%;
  }
  .loyalty-edit-lower p {
    font-size: 12px;
    overflow-wrap: break-word;
    width: 88%;
  }
  .loyalty-tabs .nav-tabs .nav-link {
    font-size: 9px;
  }
  .voucher-info-container-upper {
    flex-wrap: wrap;
    margin-bottom: 15px;
    padding: 10px 10px 0 10px;
  }

  .voucher-info-left {
    width: 100%;
    margin-bottom: 10px;
  }
  .voucher-points {
    font-size: 24px;
  }
  .prog-bar {
    width: 100%;
    height: 6px;
  }
  .voucher-info-right p:first-of-type {
    font-size: 14px;
  }
  .voucher-info-right p:nth-of-type(2) {
    font-size: 13px;
  }
  .voucher-info-right p:last-of-type {
    font-size: 12px;
  }
  .voucher-tags {
    width: 100%;
    margin: 1%;
  }
  .loyalty-tabs .nav-tabs .nav-link {
    font-size: 14px;
    padding: 4px;
  }
  .voucher-info-container-lower .nav-tabs .nav-link {
    font-size: 10px;
  }
  .track-margin p {
    font-size: 12px;
  }
  .pref-ques {
    width: 20%;
    font-size: 12px;
    padding: 0;
  }
  .pref-categories {
    width: 80%;
    padding: 0 0 0 10px;
  }
  .pref-cat-box {
    padding: 2px 10px;
    margin: 0 5px 5px 0;
    font-size: 10px;
  }
  .booking-history {
    display: flex;
    flex-wrap: wrap;
    border-bottom: 1px solid #eee;
  }
  .booking-history-date {
    width: 100%;
  }
  .booking-history-poster {
    width: 100%;
  }
  .booking-history-poster img {
    width: 100%;
    height: 170px;
    object-fit: cover;
    object-position: top;
  }
  .booking-history-info {
    width: 100%;
    margin-top: 10px;
    padding-left: 0;
  }
  .btn-food {
    margin-right: 0;
  }
  .booking-history-info {
    width: 100%;
    flex-wrap: wrap;
  }
  .booking-history-info-left {
    width: 100%;
  }
  .booking-cancel {
    width: 50%;
    margin: auto;
    margin-top: 10px;
  }
  .booking-cancel img {
    width: 100%;
  }
  .loyalty-edit-upper a {
    font-size: 12px;
  }
  .rewards-table table {
    width: 85%;
  }

  .price-ticket p {
    
    text-align: center;
    width: 100%;
}
}

.know_more img {
  width: 100%;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
}

.about_wow {
  padding: 85px 80px;
  display: flex;
}
.about_img img {
  width: 401px;
  height: 500px;
  padding: 0px 15px;
}
.about_text {
  width: 100%;
  padding: 0px 15px;
}
.know_more {
  width: 100%;
  padding: 0px 15px;
}
.about_header_img {
  width: 100%;
}
.about_header_img img {
  height: 550px;
  width: 100%;
}
.carousel_img img {
  width: 100%;
}

.movie_rating .accordion__button {
  background: #6c6d6e3b;
  color: #fff;
}
.movie_rating .accordion__button:hover {
  background: #6c6d6e3b;
}
.movie_info .accordion__panel {
  background: #6c6d6e3b;
  background-color: #6c6d6e3b;
}
.carousel-container-about {
  height: 85vh;
}
.carousel-container-about img.d-block.w-100 {
  height: 100%;
}
@media only screen and (max-width: 768px) {
  .about_wow {
    flex-wrap: wrap;
    padding: 15px;
  }
  .about_header_img img {
    height: auto;
    max-width: 100%;
  }
  .about_img img {
    max-width: 100%;
    height: auto;
  }
}
li::before {
  color:white !important;
}

.foodcontainer {
  background-color: #242424;
}
.food-section .movie-info {
  width: 20%;
  border-radius: 5px;
  position: relative;
}
.food-section .showtimes {
  padding: 0 20px !important;
  width: 75%;
  border-radius: 5px;
}
.food-section .row {
  justify-content: space-between;
}
.foodcontainer ._summary_txt,
._seat_title {
  text-transform: uppercase;
  color: #fff;
  font-size: 22px;
  line-height: 30px;
  font-weight: 500;
  margin-bottom: 10px;
}
.food-section ._summary_txt {
  padding: 33px 0 0;
  font-family: "oswald";
}
.food-heading {
  padding: 0px 10px 30px 10px;
}
.heading-content {
  display: flex;
  justify-content: space-between;
  padding-bottom: 10px;
  border-bottom: solid 0.5px #484848;
  width: 100%;
  align-items: center;
}
.heading-content .btn {
  padding: 5px 15px !important;
}
.food-section ._summary_details {
  height: auto !important;
}
.food-section ._35px {
  padding: 0 10px;
}
.food-section ul {
  display: flex;
  flex-wrap: wrap;
}
.food-heading h3 {
  color: #fff;
  font-size: 20px;
  text-transform: uppercase;
  font-family: "Oswald", sans-serif;
  letter-spacing: 1px;
  margin-bottom: 0px;
}
.food-type {
  padding: 0 10px 30px 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}
.food-section {
  padding: 110px 0px 30px 0px;
}
.food-section .movie-info {
  margin-top: 0;
  padding: 0;
}

.food-section .movie-info h4 {
  text-transform: uppercase;
  font-weight: 600;
  padding-bottom: 10px;
  border-bottom: none;
  font-family: unset;
}
ul.react-tabs__tab-list {
  float: left;
  display: flex;
  list-style-type: none;
}
.btn-preview-collection {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}
.food-btn-group {
  width: unset;
}
.food-btn-group .btn {
  margin-right: 5px;
  padding: 5px 15px !important;
  font-size: 14px;
  text-transform: capitalize;
  font-weight: 600;
}
.food-section ._item_list {
  margin-bottom: 0;
}
.food-section ._print_tickets {
  min-height: auto;
}
.food-section .food_list_selected_ li {
  display: flex;
  font-size: 12px;
  justify-content: space-between;
  margin-bottom: 10px;
  width: 100%;
  color: #9f9797;
}
.food-section .food_list_selected_ {
  padding: 0 10px;
  border-bottom: 1px solid #484848;
}
.food-section .total-amount {
  padding: 0 10px;
}
.food-section .total-amount ._name__ {
  font-size: 12px;
}
.food-section .amount-value {
  font-size: 1rem;
  font-weight: 600;
}
.food-section .booking-button {
  padding: 0 10px;
  margin: 40px 0 20px 0;
  width: 100%;
}
.food-section .food_list_selected_ {
  padding: 0 10px;
  border-bottom: 1px solid #484848;
  height: 115px;
  overflow-y: scroll;
}
.btn {
  border-radius: 5px;
  color: #fff;
  font-size: 15px;
  padding: 8px 20px;
}
.btn-ghost {
  background-color: transparent;
  border: 2px solid #fff;
}
.foodcontainer .btn-ghost:hover {
  color: #fff;
}
.btn-gray {
  background-color: #5a5959;
  border: 2px solid #5a5959;
  padding: 8px 15px;
  font-weight: 600;
}
.btn-default {
  background-color: #c5292a;
  border: 2px solid #c5292a;
  padding: 8px 15px;
  font-weight: 600;
}

.switch {
  position: relative;
  display: inline-block;
  width: 40px;
  height: 22px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0px;
  background-color: #5a5959;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 18px;
  width: 18px;
  left: 3px;
  bottom: 2px;
  background-color: white;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #c5292a;
}

input:focus + .slider {
  box-shadow: 0 0 1px #c5292a;
}

input:checked + .slider:before {
  -webkit-transform: translateX(16px);
  transform: translateX(16px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}
.food-switch {
  color: #fff;
  font-family: sans-serif;
}
.food-preview-collection {
  width: 100%;
  color: #fff;
  display: flex;
  height: 410px;
  flex-wrap: wrap;
  overflow-y: auto;
}
.food-preview {
  width: 31%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-right: 15px;
}
.food-img {
  width: 100%;
  position: relative;
  background-color: black;
}
.food-img img {
  width: 100%;
  opacity: 0.6;
  display: block;
}
.food-img p {
  position: absolute;
  bottom: 5%;
  left: 5%;
  font-weight: 600;
  font-family: sans-serif;
  letter-spacing: 1px;
  height: 30px;
}
p.order-info {
  margin-top: 30px;
  font-family: sans-serif;
  font-size: 14px;
}
.food-preview .row {
  margin-top: 30px;
  display: flex;
  justify-content: space-between;
  padding: 0 20px;
  margin-bottom: 20px;
}
.food-counter-btn {
  display: flex;
  align-items: baseline;
}
.food-counter-btn img {
  line-height: 120%;
}
.food-count {
  margin: 0 13px;
}
.food-section .movie_name__ {
  color: #fff;
  font-size: 13px;
  line-height: 24px;
  font-weight: normal;
  font-weight: 700;
  text-transform: capitalize;
}
/* width */
::-webkit-scrollbar {
  width: 2px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: red;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #b30000;
}
.food-counter-btn .fa {
  cursor: pointer;
}
._food_item_name:first-of-type {
  width: 65%;
}

@media (max-width: 768px) {
  .food-preview {
    height: 320px;
    margin-bottom: 30px;
  }
}
@media (max-width: 428px) {
  .foodcontainer {
    padding: 0;
  }
  .food-section {
    padding: 80px 20px 30px 20px;
  }
  .btn {
    margin-bottom: 10px;
    font-size: 14px;
  }
  .switch {
    margin-top: 10px;
  }
  .food-section .movie-info {
    width: 80%;
    margin: auto;
    margin-top: 20px;
  }

  .food-section .foodcontainer {
    width: 80%;
    margin: 20px auto;
  }
  .food-preview {
    height: 180px;
    margin-bottom: 30px;
    width: 100%;
  }
  .heading-content {
    align-items: center;
  }
  .food-section .row {
    justify-content: space-between;
    flex-direction: column-reverse;
  }
  .food-preview {
    height: 200px;
    margin-bottom: 30px;
  }
  .food-preview {
    height: 200px;
  }
  .food-section .row {
    justify-content: space-between;
    flex-direction: column-reverse;
  }
}

.foodcontainer {
  background-color: #242424;
}
.wow-location .flim_info {
  margin: 15px 0px;
}

.wow-location .wow_info {
  display: flex;
  justify-content: space-between;
  flex-wrap:wrap;
}
.wow-location .about_wow.wow-location.container {
  display: grid;
}
.about_wow.wow-location.container{
  display: grid;
}
.wow-location .wow_location {
  width: 50%;
}
.wow-location .club {
 height: 29px;
 margin-bottom: 0px;
}

.wow-location .cinescap_location {
  width: 50%;
}

.house_text {
  background-color: #15151596;
  width: 85%;
  padding: 15px;
  border-radius: 6px;
}

/* .house_text h3 {
  padding-left: 15px;
  margin: 10px 0px;
} */

.text-map {
  text-align: center;
}




.food-section .movie-info {
  width: 20%;
  border-radius: 5px;
  position: relative;
}
.food-section .showtimes {
  padding: 0 20px !important;
  width: 75%;
  border-radius: 5px;
}
.food-section .row {
  justify-content: space-between;
}
.foodcontainer ._summary_txt,
._seat_title {
  text-transform: uppercase;
  color: #fff;
  font-size: 22px;
  line-height: 30px;
  font-weight: 500;
  margin-bottom: 10px;
}
.food-section ._summary_txt {
  padding: 33px 0 0;
  font-family: "oswald";
}
.food-heading {
  padding: 0px 10px 30px 10px;
}
.heading-content {
  display: flex;
  justify-content: space-between;
  padding-bottom: 10px;
  border-bottom: solid 0.5px #484848;
  width: 100%;
  align-items: center;
}
.heading-content .btn {
  padding: 5px 15px !important;
}
.food-section ._summary_details {
  height: auto !important;
}
.food-section ._35px {
  padding: 0 10px;
}
.food-section ul {
  display: flex;
  flex-wrap: wrap;
}
.food-heading h3 {
  color: #fff;
  font-size: 20px;
  text-transform: uppercase;
  font-family: "Oswald", sans-serif;
  letter-spacing: 1px;
  margin-bottom: 0px;
}
.food-type {
  padding: 0 10px 30px 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}
.food-section {
  padding: 110px 0px 30px 0px;
}
.food-section .movie-info {
  margin-top: 0;
  padding: 0;
}

.food-section .movie-info h4 {
  text-transform: uppercase;
  font-weight: 600;
  padding-bottom: 10px;
  border-bottom: none;
  font-family: unset;
}
ul.react-tabs__tab-list {
  float: left;
  display: flex;
  list-style-type: none;
}
.btn-preview-collection {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}
.food-btn-group {
  width: unset;
}
.food-btn-group .btn {
  margin-right: 5px;
  padding: 5px 15px !important;
  font-size: 14px;
  text-transform: capitalize;
  font-weight: 600;
}
.food-section ._item_list {
  margin-bottom: 0;
}
.food-section ._print_tickets {
  min-height: auto;
}
.food-section .food_list_selected_ li {
  display: flex;
  font-size: 12px;
  justify-content: space-between;
  margin-bottom: 10px;
  width: 100%;
  color: #9f9797;
}
.food-section .food_list_selected_ {
  padding: 0 10px;
  border-bottom: 1px solid #484848;
}
.food-section .total-amount {
  padding: 0 10px;
}
.food-section .total-amount ._name__ {
  font-size: 12px;
}
.food-section .amount-value {
  font-size: 1rem;
  font-weight: 600;
}
.food-section .booking-button {
  padding: 0 10px;
  margin: 40px 0 20px 0;
  width: 100%;
}
.food-section .food_list_selected_ {
  padding: 0 10px;
  border-bottom: 1px solid #484848;
  height: 115px;
  overflow-y: scroll;
}
.btn {
  border-radius: 5px;
  color: #fff;
  font-size: 15px;
  padding: 8px 20px;
}
.btn-ghost {
  background-color: transparent;
  border: 2px solid #fff;
}
.foodcontainer .btn-ghost:hover {
  color: #fff;
}
.btn-gray {
  background-color: #5a5959;
  border: 2px solid #5a5959;
  padding: 8px 15px;
  font-weight: 600;
}
.btn-default {
  background-color: #c5292a;
  border: 2px solid #c5292a;
  padding: 8px 15px;
  font-weight: 600;
}

.switch {
  position: relative;
  display: inline-block;
  width: 40px;
  height: 22px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0px;
  background-color: #5a5959;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 18px;
  width: 18px;
  left: 3px;
  bottom: 2px;
  background-color: white;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #c5292a;
}

input:focus + .slider {
  box-shadow: 0 0 1px #c5292a;
}

input:checked + .slider:before {
  -webkit-transform: translateX(16px);
  transform: translateX(16px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}
.food-switch {
  color: #fff;
  font-family: sans-serif;
}
.food-preview-collection {
  width: 100%;
  color: #fff;
  display: flex;
  height: 471px;
  flex-wrap: wrap;
  overflow-y: auto;
}
button.btn.btn-ghost.btn-skip {
  margin: 40px 0 20px 0;
}
.food-preview {
  width: 31%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-right: 15px;
}
.food-img {
  width: 100%;
  position: relative;
  background-color: transparent;
}
.food-img img {
  width: 100%;
  opacity: 0.6;
  display: block;
}
.food-img p {
  position: initial !important;
  bottom: 5%;
  left: 5%;
  font-weight: 600;
  font-family: sans-serif;
  letter-spacing: 1px;
  height: 30px;
}
p.order-info {
  margin-top: 30px;
  font-family: sans-serif;
  font-size: 14px;
}
.food-preview .row {
  margin-top: 30px;
  display: flex;
  justify-content: space-between;
  padding: 0 20px;
  margin-bottom: 20px;
}
.food-counter-btn {
  display: flex;
  align-items: baseline;
}
.food-counter-btn img {
  line-height: 120%;
}
.food-count {
  margin: 0 13px;
}
.food-section .movie_name__ {
  color: #fff;
  font-size: 13px;
  line-height: 24px;
  font-weight: normal;
  font-weight: 700;
  text-transform: capitalize;
}
/* width */
::-webkit-scrollbar {
  width: 2px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: red;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #b30000;
}
.food-counter-btn .fa {
  cursor: pointer;
}
._food_item_name:first-of-type {
  width: 65%;
}

@media (max-width: 768px) {
  .food-preview {
    height: 320px;
    margin-bottom: 30px;
  }
}
@media (max-width: 428px) {
  
  .wow-location .house_text{
    width: 100%;
  }
  .wow-location .wow_location {
    width: 100% ;
}

.wow-location .location_info{
  width: 100%;
}
 

  .foodcontainer {
    padding: 0;
  }
  .food-section {
    padding: 80px 20px 30px 20px;
  }
  .btn {
    margin-bottom: 10px;
    font-size: 14px;
  }
  .switch {
    margin-top: 10px;
  }
  .food-section .movie-info {
    width: 80%;
    margin: auto;
    margin-top: 20px;
  }

  .food-section .foodcontainer {
    width: 80%;
    margin: 20px auto;
  }
  .food-preview {
    height: 180px;
    margin-bottom: 30px;
    width: 100%;
  }
  .heading-content {
    align-items: center;
  }
  .food-section .row {
    justify-content: space-between;
    flex-direction: column-reverse;
  }
  .food-preview {
    height: 200px;
    margin-bottom: 30px;
  }
  .food-preview {
    height: 230px;
  }
  .food-preview .row{
    padding: 20px 20px;
  }
  .food-section .row {
    justify-content: space-between;
    /* padding: 12px 20px; */
    padding-top: 0px;
    margin-top: 0px;
    flex-direction: column-reverse;
  }
  .food-img p{
    position: initial;
    height: auto;
  }
  .food-preview{
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    margin-bottom: 0px;
  }
}

.card-details-text {
  padding: 0.5rem 0rem;
  border-bottom: 1px solid #484848;
  text-transform: uppercase;
 
}
button, input, optgroup, select, textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  background-color: transparent;
  /*border: transparent;
  */border-bottom: 1px solid #484848;
  border-right: transparent;
  box-shadow: none;
  border-top: transparent;
 border-left: transparent;
 
 padding: 6px 0px;
    font-size: 13px;

}


input[type='checkbox'] {
  accent-color: #c5292a;
}

button.btn.btn-normal.proceed-btn.btn-apply.btn-redeem {
  
  margin-top: 0px;
  
  margin-bottom: 18px;
}
#cardNumber {
  width: 100% !important;
  text-transform: uppercase;
}
.input-date {
  display: flex;
  justify-content: space-between;
  padding: 10px 0px;
}
.border-card {
  /* border-bottom: 1px solid #484848; */
  border-right: 1px solid #c5292a !important;
  display: block;
}
.nav-pills .nav-link {
 
  border-radius: 0px;
}

/* .flex-column.payment-options.border-card.nav.nav-pills {
  border-bottom: 1px solid #484848;
} */
/* .card-details svg {
  position: relative;
  top: 24px;
  left: 92%;
  display: flex;
  align-items: center;
  justify-content: space-between;
} */

.card-details .hdfc-card {
  background-color: #48453d5c;
  /* border: 1px solid #a17979; */
  border-bottom: 1px solid #484848;
  padding: 5px 9px;
}

.card-details svg {
  position: relative;
  top: 101px;
  left: 60%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: absolute;
}


/* .flex-column.payment-options.border-card.nav.nav-pills:last-of-type {
  border-bottom: transparent;
} */
.border-card:last-of-type{
  border-bottom: transparent;
}
button.btn.btn-normal.proceed-btn {
  padding: 5px 12px !important;
  font-size: 11px;
  margin-top: 18px;
}
button.btn-pay.proceed-btn.btn {
  background-color: #c5292a;
  padding: 7px 42px !important;
  margin-bottom: 20px;
  width: 28%;
  
}
.btn-pay{
  margin-top: 11px;
}
.card-section {
  background-color: #171614;
  height: 335px;
}
.card-name input {
  width: 100%;
}

button, input {
  overflow: visible;
  outline: none;
  color: #fff;
  text-transform: uppercase;
}
.gray-box {
  background-color: #242424;
}
h3,
h4 {
  color: #fff;
  font-size: 20px;
  text-transform: uppercase;
  font-family: "Oswald", sans-serif;
  letter-spacing: 1px;
}
.payment-section {
  padding: 0;
  width: 100%;
  margin: 0 auto;
}
.gray-box {
  padding: 30px 30px 0 30px;
  margin-bottom: 30px;
}
.border-bot {
  border-bottom: solid 0.5px #484848;
}
.contact-details {
  padding: 20px 0;
}
.contact-form {
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.input-box {
  background-color: #171614;
  padding: 10px;
  border-radius: 2px;
}
.card-details {
  
  display: inline-block;
  display: flex;
  flex-direction: column;

  padding-left: 82px;
}
.input-box input {
  background-color: #171614;
  border: black;
  color: #fff;
  padding-left: 10px;
  outline: none;
}
.offer-preview-display {
  padding: 20px 0;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.card-detail {
  color: #515050;
}
.payment-details {
  padding: 5px;
  width: 100%;
  padding: 20px 0 25px 0;
}
.payment-options {
  width: 94%;
  background-color: #171614;
  margin-left: 15px;
}
.card-details .tab-content {
  width: 70%;
  box-shadow: rgb(100 100 111 / 20%) 0px 7px 29px 0px;
  margin: 20px 0px;
  padding: 30px 20px;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
}
.payment-section-container {
  width: 73%;
}
.card-info-box {
  width: 85%;
  padding: 20px;
}
.offer-img {
  width: 48%;
}
.offer-img img {
  width: 100%;
}
.card-info-section{
  padding: 50px;
}
.card {
  background-color: #171614;
  /* box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
      rgba(60, 64, 67, 0.15) 0px 2px 6px 2px; */
  box-shadow: rgb(0 0 0 / 30%) 0px 1px 5px 0px, rgb(0 0 0 / 15%) 2px 2px 6px 4px;
  padding: 10px 15px;
  margin-bottom: 15px;
}
.card-details {
  padding: 0 30px;
  background-color: #171614;
  margin: 0 20px;
  cursor: pointer;
}
.card-details .hdfc-card {
  margin-left: 50px;
}
.food-img p {
  
    bottom: 18% !important;

}

.nav-link {
  border-bottom: solid 0.5px #484848;
}
.nav-link:last-of-type {
  border-bottom: solid 0.5px transparent;
}
.nav-link:first-of-type {
  border-top: transparent;
}

.nav-pills a {
  color: #fff;
}
.nav-pills a {
  color: #fff;
}
.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  background-color: transparent;
  /* border-right: 2px solid #c5292a; */
  margin-right: -3px;
  border-radius: 0;
}
.card-heading {
  padding-top: 10px;
}
.card-info {
  margin-bottom: 10px;
}
.card-info input {
  background-color: #171614;
  border: none;
  outline: none;
}
.card-detail {
  margin-bottom: 0;
}
.expiry {
  width: 58%;
  margin-right: 10px;
}
.cvv {
  width: 38%;
}
.card-form input {
  color: #fff;
}
.btn {
  border-radius: 5px;
  color: #fff;
  font-size: 15px;
  padding: 8px 20px;
}
.btn-default {
  background-color: #c5292a;
  border: 2px solid #c5292a;
  padding: 6px 15px;
  font-weight: 600;
}
#cv-detail {
  width: 100%;
}

.payment-heading .col-sm-4 {
  padding: 0 30px 0 0;
}
.payment-options .nav-item {
  border-bottom: 1px solid #484848 !important;
}
.card-details {
  background-color: #171614;
  margin: 0;
 
}
.payment-container {
  margin-top: 20px;
}
.gray-box:last-of-type {
  padding-bottom: 20px;
}
.ticket_prints ._float_right {
  float: unset;
}
/* .ticket_prints ._info__ ul {
  display: flex;
  flex-wrap: wrap;
  margin-top: 10px;
} */
._print_tickets ._info__ ul li {
  display: flex;
  flex-wrap: wrap;
  margin-top: 10px;
  justify-content: space-between;
  font-size: 14px;
}
._print_tickets ._info__ ul li ._food_item_name {
  width: 48%;
}
._print_tickets ._info__ ul li ._food_item_name:last-of-type {
  text-align: right;
}
.payment-movie-info .booking_summary._35px {
  padding: 0;
}
.payment-movie-info .movie_name__ {
  font-weight: 500 !important;
}
.payment-movie-info .ticket_prints._35px {
  padding: 0;
}
.seats-flex {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.seats-flex li {
  margin: 1%;
}
.offer-preview-display .voucher-tags {
  width: 45%;
}
.booking-container .movie-info {
  width: 20%;
}
.booking-container .seats-flex li {
  margin: 2%;
}
.booking-container .square-seat {
  width: 35px;
  height: 35px;
}
@media (max-width: 428px) {
.card-details svg {
    left: 76%;
  }
  /* .card-details svg {
    left: 78%;
}
  button, input{
    width: 100%;
  } */
  button.btn-pay.proceed-btn.btn{
    width: 100%;
  }
  .card-info-section{
    padding: 50px 0px;
  }

  .food-img p {
    bottom: 42% !important;
}
  .input-date{
    display: inline;
  }
  #expiryDate {
    width: 100%;
}
#cvc{
  width: 100%;
}
#cvc::-webkit-input-placeholder {
 
  content:"CVV" !important;
}
#cvc::placeholder {
 
  content:"CVV" !important;
}
  .booking-container {
    padding: 110px 30px;
    flex-wrap: wrap;
  }
  .booking-container .movie-info {
    width: 100% !important;
    margin: auto;
  }
  .booking-container .square-seat {
    width: 37px;
    height: 37px;
  }
  .booking-success-container {
    width: 100%;
  }
  .card-details {
    margin: 20px 15px 0 15px;
    padding: 0 20px;
  }
  .card-details .tab-content {
    width: 100%;
  }
  .wow_successfully {
    width: 80%;
    margin: auto;
  }
  .successfully_text h2 {
    font-size: 24px;
  }
  .successfully_text p {
    font-size: 14px;
  }
  .wow_successfully_btn {
    display: flex;
    justify-content: space-between;
    padding: 0 10px;
  }
  button.wow_btn {
    margin-right: 0;
  }
  .btn {
    font-size: 13px;
  }
  .offer-preview-display .voucher-tags {
    width: 100%;
  }
  .voucher-off p:last-of-type {
    font-size: 8px;
  }
  .voucher-code {
    font-size: 8px;
  }
}




.apply-coupon {
  display: flex;
}

  button.btn.btn-normal.proceed-btn.btn-apply {
    margin-left: 15px;
}
.offer-info {
 
  margin-bottom: 30px;
}
.hidden {
  display: none;
}
.offer-info img {
  width: 18px;
  margin-left: 13px;
  margin-right: 13px;
}


#contact-form {
  width: 100%;
  padding: 0 80px !important;
}
.contact-form h2 {
  padding: 40px 0;
}
.contact-us-container {
  padding: 40px 0;
}
.contact-box {
  border: 1px solid #fff;
  border-radius: 5px;
  padding: 20px;
  width: 88%;
  margin: auto;
}
.contact-inline {
  display: flex;
  align-items: center;
}
.contact-left {
  width: 15%;
}
.contact-left .fas {
  margin-right: 10px;
}
.contact-right {
  width: 85%;
  padding-left: 10px;
}
@media (max-width: 428px) {
  .contact-left {
    width: 45%;
    font-size: 12px;
  }
  #contact-form {
    width: 100%;
    padding: 0 25px !important;
  }
  .contact-right {
    width: auto;
    font-size: 12px;
    padding-left: 0;
  }
}

.comingsoon-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  position: relative;
}
.comingsoon-container h1 {
  font-size: 80px;
  border-bottom: 2px solid #c5292a;
}
.comingsoon-container img {
  display: block;
  width: 100%;
}
.error-contain {
  position: absolute;
  bottom: 20px;
  right: 30px;
  font-size: 34px;
}
@media (max-width: 428px) {
  .comingsoon-container h1 {
    font-size: 74px;
  }
}

span.light-weight {
  font-weight: 300;
  font-size: 14px;
}
.syn-heading {
  font-size: 28px;
  font-weight: 500;
}
.upper-upcoming {
  height: 70vh;
}
.lower-upcoming {
  /* flex-direction: column; */
  height: 30vh;
  padding-top: 95px;
  padding-right: 15px;
}

.lower-upcoming h3 {
  width: 100%;
}
.lower-upcoming p {
  width: 100%;
}
@media (max-width: 428px) {
  .now-showing .dropdown {
    float: right;
  }
  .now-show-tab:last-of-type {
    width: 15%;
  }
  .now-showing-top {
    width: 87%;
    justify-content: space-between;
  }
  .upper-upcoming {
    height: auto;
  }
  .lower-upcoming {
    flex-direction: column;
    height: auto;
    padding-top: 20px;
  }
}

.margin-top {
  margin-top: 80px;
}


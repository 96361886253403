.movie-preview {
  width: 17%;
  position: relative;
  margin: 20px 1.5% 10px 1.5%;
}
.release {
  width: 90%;
  margin: auto;
}
.releasing {
  font-size: 90% !important;
  text-transform: none !important;
  font-weight: 600;
}
.release-date {
  font-weight: 300;
}
.movie-preview h4 {
  font-size: 120%;
  padding-left: 8px;
  font-weight: 600;
  margin-top: 20px;
  text-transform: uppercase;
}
.movie-preview p {
  padding-left: 8px;
  text-transform: uppercase;
  font-size: 80%;
  color: #9b9b9b;
}
.img-showing {
  overflow: hidden;
  height: 315px;
  position: relative;
}
.movie-preview .img-showing img {
  transition: transform 0.5s, opacity 0.5s;
  display: block;
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
  height: 100%;
}
.release .img-showing img {
  height: 100%;
}
.movie-preview:hover .img-showing img {
  opacity: 0.5;
  -webkit-transform: scale(1.1);
  -ms-transform: scale(1.1);
  transform: scale(1.1);
}
@media (max-width: 428px) {
  .release {
    width: 60%;
    margin: auto;
  }
  .release .img-showing img {
    height: 100%;
    width: 100%;
  }
  .movie-preview h4 {
    font-size: 80%;
  }
  .movie-preview p {
    font-size: 60%;
  }
  .movie-preview p {
    font-size: 60%;
  }
  .img-showing {
    height: 250px;
  }
  .movie-preview {
    margin: 10px 1% 0px 1%;
  }
}

.footer-preview{
    position: relative;
}
.title{
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 1.1px;
    margin-bottom: 15px;
}
.footer-list{
    list-style-type: none;
    padding-left: 0;
}
.footer-list li a{
    text-decoration: none;
    color: #fff;
    font-size: 13px;
}
.footer-list li a:hover{
    color: rgb(224, 224, 224);
}
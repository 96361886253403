.now-showing {
  padding: 0 0 0 0;
  margin-top: 6%;
}
.now-showing .dropdown {
  width: 13%;
}
.now-showing .dropdown img {
  width: 100%;
}
.now-showing .btn-success {
  background-color: transparent;
  border: 1px solid #be2829;
}
.now-showing .dropdown-toggle::after {
  border-top: 0.3em solid #c5292a;
  outline: none;
}
.now-showing .btn-success:not(:disabled):not(.disabled).active,
.btn-success:not(:disabled):not(.disabled):active,
.show > .btn-success.dropdown-toggle {
  background-color: transparent !important;
  outline: none;

  border-color: #be2829 !important;
}
.now-showing .btn-success.focus,
.btn-success:focus {
  border-color: #be2829 !important;
  box-shadow: none !important;
  background-color: transparent !important;
}
.now-showing .btn-success:not(:disabled):not(.disabled).active:focus,
.btn-success:not(:disabled):not(.disabled):active:focus,
.show > .btn-success.dropdown-toggle:focus {
  box-shadow: none !important;
}
.now-showing .btn-success:hover {
  background-color: transparent !important;
  border-color: #be2829 !important;
}
.now-show-tab a:hover {
  text-decoration: none;
  cursor: pointer;
  /* color: #0e0b0b !important; */
  width: auto;
  /* background-color: #fff; */
}
.now-showing-top {
  display: flex;
  justify-content: space-between;
}
.now-showing h2 {
  text-transform: uppercase;
  font-family: "Oswald", sans-serif;
  font-weight: 600;
  font-size: 40px;
  letter-spacing: -1.4px;
  padding-bottom: 2px;
  /* border-bottom: 2px solid #c5292a; */
}
.movie-preview-collection {
  display: flex;
  flex-wrap: wrap;
  /* justify-content: space-between; */
}
.view-button {
  display: flex;
  justify-content: center;
}
.view-btn {
  padding: 10px 30px !important;
  font-weight: 500 !important;
}
.icons-arr svg {
  margin-top: -3px;
}
.now-showing-top .react-tabs {
  display: flex;
  flex-direction: column;
}
.now-showing-top .react-tabs__tab-list {
  width: 100%;
}
#now-show {
  /* padding-top: 30px; */
}
.now-showing-top .react-tabs {
  width: 100%;
}
.now-showing-top .react-tabs__tab-list {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.now-showing-top .react-tabs__tab a {
  color: unset;
  text-decoration: none;
}
.now-showing-top .react-tabs__tab-list {
  border-bottom: 0px solid #aaa;
  margin: 0 0 10px;
  padding: 0;
  flex-wrap: wrap;
}
.now-show-tab .react-tabs__tab--selected {
  background: transparent;
  border-color: transparent;
  color: #fff;
  border-radius: 0;
}
.now-show-tab .react-tabs__tab--selected h2 {
  border-bottom: 2px solid #c5292a;
}
.now-genre::after {
  display: none !important;
}
.genre-drop button#dropdown-button-dark-example1 {
  background-color: #212529;
}
.genre-drop .dropdown-menu {
  min-width: 10rem;
  padding: 0.2rem 0;
  background-color: #242424;
}
.genre-drop .dropdown-item {
  background-color: #212529 !important;
  color: #fff !important;
}
.genre-drop-btn {
  min-width: 10rem;
  padding: 0.2rem 0;
}
.genre-drop-btn.btn-primary {
  min-width: 10rem;
  padding: 0.5rem 0 !important;
  background-color: #242424;
  border: 1px solid #242424;
}
.genre-drop-btn.btn-primary:not(:disabled):not(.disabled):active {
  background-color: #242424;
  border-color: #242424;
}
.genre-drop-btn.btn-primary:hover {
  background-color: #242424;
  border: 1px solid #242424;
}
.show > .genre-drop-btn.btn-primary.dropdown-toggle {
  color: #fff;
  background-color: #242424;
  border-color: #242424;
}
.genre-drop-btn.btn-primary.focus,
.genre-drop-btn.btn-primary:focus {
  background-color: #242424;
  border-color: #242424;
}

.movie-preview .img-showing img {
  width: 100%;
}
.view-button {
  padding: 20px 0;
}
.view-button a:hover button {
  color: #fff;
}
img.icon {
  width: auto !important;
}
/* without tab design */
.now-showing.container {
  position: relative;
  z-index: 3;
  padding-bottom: 30px;
}
.now-showing-top {
  display: flex;
  justify-content: flex-start;
  /* padding-top: 50px; */
}
.now-show-tab {
  margin: 0 10px 10px 0;
}
.now-show-tab a {
  color: unset;
  text-decoration: none;
  display: inline-block;
  margin: 0 5px 0 15px;
}
.now-show-tab.active a {
  border-bottom: 2px solid #c5292a;
}
.now-show-tab a:hover {
  text-decoration: none;
  color: #fff;
}
@media (max-width: 1200px) {
  .movie-preview-collection .movie-preview {
    width: 30%;
  }
  .movie-preview-collection .movie-preview:last-of-type {
    display: none;
  }
}
@media (max-width: 768px) {
  .now-showing {
    padding: 60px;
  }
}
@media (max-width: 428px) {
  .now-showing {
    padding: 30px;
    font-size: 24px;
    margin-top: 0%;
  }
  .genre-drop-btn.btn-primary {
    min-width: 7rem;
  }

  .movie-preview-collection .movie-preview {
    width: 48%;
  }
  .movie-preview-collection {
    justify-content: space-between;
  }
  .movie-preview-collection .movie-preview:last-of-type {
    display: unset;
  }
  .movie-preview .img-showing img {
    transition: transform 0.5s, opacity 0.5s;
    display: block;
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
    width: 100%;
  }
  .movie-preview:hover .img-showing img {
    opacity: 0.5;
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
  }
  img.icon {
    width: 75% !important;
  }
  .now-showing h2 {
    font-size: 24px;
  }
  .genre {
    font-size: 16px;
  }
  .now-show-tab .react-tabs__tab {
    padding: 6px;
  }
  .genre-btn-contain {
    width: auto;
    text-align: right;
  }

  .genre {
    width: 80px !important;
    font-size: 10px;
    padding: 5px;
    height: 35px;
    margin-top: 0 !important;
    text-align: left;
  }
  .select-genre:after {
    top: 50%;
    right: 5%;
    font-size: 15px;
  }
  .now-show-tab {
    margin: 0 0px 10px 0;
  }
  .now-show-tab a {
    color: unset;
    text-decoration: none;
    display: inline-block;
    margin: 0 5px 0 5px;
  }
}

@media (min-width: 375px) and (max-width: 413px) {
  .now-showing {
    padding: 20px;
  }
}

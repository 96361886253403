#contact-form {
  width: 100%;
  padding: 0 80px !important;
}
.contact-form h2 {
  padding: 40px 0;
}
.contact-us-container {
  padding: 40px 0;
}
.contact-box {
  border: 1px solid #fff;
  border-radius: 5px;
  padding: 20px;
  width: 88%;
  margin: auto;
}
.contact-inline {
  display: flex;
  align-items: center;
}
.contact-left {
  width: 15%;
}
.contact-left .fas {
  margin-right: 10px;
}
.contact-right {
  width: 85%;
  padding-left: 10px;
}
@media (max-width: 428px) {
  .contact-left {
    width: 45%;
    font-size: 12px;
  }
  #contact-form {
    width: 100%;
    padding: 0 25px !important;
  }
  .contact-right {
    width: auto;
    font-size: 12px;
    padding-left: 0;
  }
}

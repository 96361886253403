/* red color: #c5292a*/

.header {
  height: 78vh;
  background-color: #000;
}

.header .carousel .carousel-inner .carousel-item img {
  width: 100%;
  height: 600px;
  opacity: 0.7;
}
/* .header .react-multi-carousel-item{
  background-color: #fff;
} */
.carousel-caption {
  left: 10% !important;
  bottom: 50px !important;
}
.carousel-caption h3 {
  font-size: 40px;
  font-weight: 700;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.13;
  text-align: left;
  color: #ffffff;
  text-transform: uppercase;
  letter-spacing: -1.3px;
  transform: scale(1, 1.4);
  margin-bottom: 20px;
}
.carousel-caption p {
  opacity: 0.9;
  font-size: 24px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  text-align: left;
  color: #ffffff;
  margin-bottom: 20px;
}
.seperator {
  margin: 0 5px;
}
.carousel-indicators {
  bottom: -50px !important;
}
.carousel-indicators li {
  width: 10px !important;
  height: 10px !important;
  border-radius: 50% !important;
}
.carousel-indicators .active {
  background-color: #c5292a !important;
}
.btn {
  padding: 10px 20px !important;
  border-radius: 5px;
  margin-right: 15px;
  float: left;
}
.btn-normal {
  background-color: #c5292a !important;
  border: 2px solid #c5292a !important;
  transition: background-color 300ms ease-in;
}
.btn-normal:hover {
  background-color: #d42929 !important;
}
.btn-ghost {
  background-color: transparent !important;
  border: 2px solid #fff !important;
}
.btn a {
  color: #fff;
  font-weight: 600;
}
.btn a:hover {
  text-decoration: none;
  color: #fff;
}
.carousel-container-banner .w-100 {
  width: 100% !important;
  /* height: 600px; */

  height: 100vh;
  background-color: #000;
  opacity: 0.8;
}
.carousel-container-banner .react-multiple-carousel__arrow {
  z-index: 998;
}
.carousel-container-banner .react-multiple-carousel__arrow::before {
  font-size: 40px;
}
.carousel-container-banner .react-multiple-carousel__arrow {
  background: rgb(0 0 0 / 0%);
}
.carousel-container-banner .react-multiple-carousel__arrow:hover {
  background: rgb(0 0 0 / 0%);
}
.banner-custom-dot-list-style .react-multi-carousel-dot button {
  width: 10px;
  height: 10px;
  border-color: transparent;
}
.banner-custom-dot-list-style .react-multi-carousel-dot button {
  background: #c6c6c6;
}
.banner-custom-dot-list-style .react-multi-carousel-dot--active button {
  background: #c5292a;
}
.header .react-multi-carousel-list {
  padding-bottom: 30px;
}
.banner-div {
  position: relative;
  /* height: auto; */
}
.book-now-banner {
  position: absolute;
  left: 2%;
  bottom: 110px;
  z-index: 99;
}
.book-now-banner h1 {
  font-size: 38px;
  font-weight: 700;
}
.book-now-banner .btn-normal {
  padding: 5px 15px !important;
  margin-top: 10px;
  transition: background 0.3s, border 0.3s, color 0.3s;
}
.book-now-banner .btn-normal:hover {
  background-color: #fff !important;
  border: 2px solid #fff !important;
}
.book-now-banner .btn-normal:hover a {
  color: #c5292a;
}
.carousel-container-banner
  li.react-multi-carousel-item.react-multi-carousel-item--active:before {
  content: "";
  height: 250px;
  width: 100%;
  position: absolute;
  bottom: 0;
  background: linear-gradient(0deg, #000, transparent);
  z-index: 99;
}
@media (max-width: 428px) {
  .header .carousel .carousel-inner .carousel-item img {
    object-fit: cover;
  }
  .header {
    height: auto;
    background-color: transparent;
    margin-top: 75px;
  }
  .book-now-banner h1 {
    font-size: 24px;
    font-weight: 700;
  }
  .header .carousel-slider .slide img {
    height: 350px;
  }
  .carousel-container-banner .w-100 {
    width: 100% !important;
    height: 270px;
    background-color: #000;
    opacity: 0.8;
  }
  .header .react-multi-carousel-list {
    padding-bottom: 20px;
  }
  .carousel-container-banner
    li.react-multi-carousel-item.react-multi-carousel-item--active:before {
    height: 150px;
  }
  .book-now-banner {
    position: absolute;
    left: 5%;
    bottom: 15px;
    z-index: 99;
  }
}
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-text-fill-color: #fff;
  -webkit-box-shadow: 0 0 0px 1000px #171614 inset;
  transition: background-color 5000s ease-in-out 0s;
}

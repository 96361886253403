/* .dropdown .btn-success:hover {
 
  border: 1px solid #be2829 !important;
} */

.btn-success {
  
  background-color: transparent;
  border: transparent;
  padding: 12px;
  margin-left: 0px !important;
}
button#dropdown-basic {
  margin-right: 0px;
}
.select-en .navbar-nav .dropdown-menu{
  position: absolute;
}
.btn-success:hover{
  background-color: transparent;
  border: transparent;
}
.select-en .dropdown-item{
  color: black !important;
}

.navbar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 40px;
  background-color: transparent;
  font-size: 15px;
  letter-spacing: 1px;
  position: fixed;
  width: 100%;
  z-index: 999;
  font-weight: 600;
}
.profile-name {
  display: flex;
  align-items: center;
}
.profile-circle {
  width: 30px;
  height: 30px;
  border: 1px solid #fff;
  border-radius: 50%;
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
}
.logo-container {
  width: 150px;
}
.logo-container img {
  width: 75%;
}
.options {
  flex-grow: 1;
  display: flex;
  justify-content: end;
  align-items: center;
}
.option {
  margin-right: 15px;
  color: #fff;
  padding-top: 10px;
  font-weight: 500;
}
.option:hover {
  text-decoration: none;
  color: #eee;
}
.location-change {
  width: 150px !important;
  border-radius: 4px;
  margin: 0 10px;
  background-color: #333;
  color: #fff;
  border: none;
  padding: 5px;
  height: 35px;
  margin-top: 5px;
  text-align: center;
}
.select-genre {
  position: relative;
}
.select-genre::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
  position: absolute;
  top: 30%;
  right: 11%;
}
/* .location-change::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
} */
.club {
  width: 100px;
  border-radius: 4px;
  border: none;
  height: 35px;
  margin-top: 5px;
  font-weight: 700;
  color: #1b1b1b;
}
.navbar-nav .club {
  width: 120px;
  color: #fff;
}
.img-search {
  width: 30px;
  height: 30px;
}
.login-button {
  padding: 0 10px;
  justify-content: end;
  display: flex;
  /* width: 200px; */
  align-items: center;
}
.login-button img {
  width: 20px;
}
.btn-signup {
  width: auto;
  background-color: #c5292a;
  color: #fff;
  font-size: 12px;
}
.hide-desktop {
  display: none;
}
.select-location {
  display: flex;
  align-items: center;
  width: 118px;
  height: 32px;
  margin: 8px 20px 0 0;
  justify-content: center;
  border: 1px solid #fff;
}
.select-location a {
  color: #fff;
}
.select-location a:hover {
  text-decoration: none;
}
.profile-picture .profile {
  background-color: transparent !important;
  border: 1px solid transparent !important;
  margin-right: 0;
  display: flex;
  align-items: center;
}

.profile-picture .dropdown-menu.show {
  margin-top: 45px !important;
  background-color: #333;
  left: 12%;
  width: 200px;
}
.profile-picture .dropdown-item {
  display: block;
  width: 100%;
  padding: 0.25rem 1rem;
  clear: both;
  font-weight: 400;
  color: #fff;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
}
.dropdown-item:first-of-type .profile-name {
  border-bottom: 1px solid #c5292a;
  padding-bottom: 10px;
}
.dropdown-item:first-of-type .profile-name .profile-circle {
  width: 22px;
  height: 22px;
  font-size: 10px;
  background-color: #c5292a;
}
.profile-picture .dropdown-item {
  font-size: 14px;
}
.dark-nav {
  background-color: #0000003d;
}
.navbar-dark .navbar-nav .nav-link {
  color: #fff !important;
}
.drop-web {
  background-color: transparent;
  border-color: transparent;
}
.drop-web::after {
  display: none;
}
.drop-web:hover {
  background-color: transparent;
  border-color: transparent;
}
.drop-web:focus {
  background-color: transparent;
  border-color: transparent;
}
.drop-web:not(:disabled):not(.disabled).active,
.drop-web:not(:disabled):not(.disabled):active,
.show > .drop-web.dropdown-toggle {
  background-color: transparent;
  border-color: transparent;
}
.web-menu-drop {
  left: -90%;
  background-color: #333;
}
.web-menu-drop a {
  color: #fff;
  font-size: 14px;
}
.web-menu-drop .dropdown-item:active,
.web-menu-drop .dropdown-item.active {
  background-color: transparent;
}
@media (max-width: 768px) {
  .navbar {
    background-color: #030303a3;
  }
  .img-search {
    display: none;
  }
  .location-change,
  .club {
    margin: 10px 0 0 0;
  }
  .options {
    align-items: flex-start;
  }
}
@media (max-width: 428px) {
  .navbar {
    background-color: #000000db;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 999;
  }
  .logo-container img {
    width: 50%;
  }
  .hide-mobile {
    display: none;
  }
  .hide-desktop {
    display: unset;
  }
  .login-button {
    padding: 0;
    justify-content: flex-start;
    margin-top: 10px;
  }
  .profile-picture .dropdown-menu.show {
    margin-top: 0 !important;
    background-color: #333;
    left: 12%;
    width: 200px;
  }
  .profile-picture .btn {
    float: none;
    padding: 10px 0 !important;
  }
  .select-genre:after {
    top: 44%;
    right: 3%;
  }
  .select-quick:after {
    top: 35%;
    right: 5%;
  }
  .genre {
    width: 90px !important;
    font-size: 10px;
    padding: 5px;
    height: 35px;
    margin-top: 0 !important;
    text-align: left;
  }
}
@media (min-width: 375px) and (max-width: 413px) {
  .genre {
    width: 80px !important;
  }
  .profile-name-info {
    display: none !important;
  }
  .profile-picture .dropdown-menu.show {
    background-color: transparent;
  }
}
